<template>
  <!--**********************************
			Main wrapper start
		***********************************-->
  <div id="main-wrapper">

    <!--**********************************
	  Content body start
  ***********************************-->

    <div class="row justify-content-center align-items-center">
      <div class="col-md-4 mt-4">
        <div class="authincation-content mt-5">
          <div class="row no-gutters mt-3">
            <div class="col-xl-12">
              <div class="auth-form">
                <h4 class="text-center mb-4">Welcome to the Konferensbokarna</h4>
                <div class="form-group">
                  <label class="mb-3"><strong>New Password</strong></label>
                  <div class="pass-code">
                    <input type="password" id="password-field" class="form-control" v-model="newPassword">
                  </div>
                </div>
                <div class="form-group">
                  <label class="mb-3"><strong>Confirm Password</strong></label>
                  <div class="pass-code">
                    <input type="password" id="password-field-new" class="form-control" v-model="confirmPassword">
                  </div>

                </div>
                <div class="text-center">
                  <button type="submit" @click="submit" class="btn btn-primary btn-block mb-4">
                    <div class="lds-ring" v-show="loading">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <span v-show="loadingS">Submit</span>
                  </button>
                  <router-link to="/login" class="text-center">Login</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--**********************************
	  Content body end
  ***********************************-->
  </div>
</template>



<script>
import axios from "axios";
export default {
  name: "Set Password",
  data() {
    return {
      newPassword: "",
      confirmPassword: "",
      loading: false,
      loadingS: true,
    };
  },

  methods: {
    submit() {
      this.loading = true;
      this.loadingS = false;
      var formData = new FormData();
      formData.append("password", this.newPassword);
      formData.append("password_confirmation", this.confirmPassword);

      axios
        .post(axios.defaults.baseURL + "set-password/" + this.$route.params.id, formData, {
          headers: {
            'Content-Type': 'application/json',
          }
        })
        .then((res) => {
          console.log("res", res);
          this.loading = false;
          this.loadingS = true;
          //   this.$router.push({ path: "" });
          this.$store.dispatch("checkErrorAndSendToast", [res, 'Success']);
          this.$router.push({ path: "/login" });
        })
        .catch((err) => {
          console.log("err", err);
          this.loading = false;
          this.loadingS = true;
          this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
    },


    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },

  },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pass-code {
  position: relative;
}

.pass-code span {
  position: absolute;
  right: 10px;
  top: 18px;
}
</style>