<template>
  <div>
    <!--**********************************
  Main wrapper start
  ***********************************-->
    <div id="main-wrapper">
      <!--**********************************
      Content body start
  ***********************************-->
      <div class="content-body pt-0 mb-5">
        <div class="container-fluid">
          <div
            class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
          >
            <h2 class="font-w600 title mb-2 mr-auto">
              {{ lan.admin[3].Opportunity[0].Opportunities }}
            </h2>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-header pb-2 d-block flex-wrap border-0">
                  <div class="d-flex mt-3">
                    <button
                      class="filter-btn up-btn ml-0"
                      data-toggle="modal"
                      data-target="#exampleModal"
                    >
                      {{ lan.admin[4].Opportunity_page[0].Add_new_note }}
                    </button>
                    <button
                      class="filter-btn up-btn"
                      data-toggle="modal"
                      data-target="#exampleModal1"
                    >
                      {{ lan.admin[4].Opportunity_page[0].Add_new_task }}
                    </button>
                  </div>

                  <div class="row noteTaskRow" v-if="note_detail && note_detail.length">
                    <div class="col-lg-6">
                      <h5 class="mb-0 py-2 text-center bgLinear">{{ lan.admin[4].Opportunity_page[0].Notes }}</h5>
                      <table
                        class="pointer"
                      >
                        <tr
                          v-for="(data, index) in note_detail"
                          :key="index"
                          @click="editNote(data.note_id)"
                        >
                          <td>
                            <strong>{{ index + 1 }}</strong>
                          </td>
                          <td>
                            <p class="mb-0">{{ data.subject }}</p>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-6" v-if="task_detail && task_detail.length">
                      <h5 class="mb-0 py-2 text-center bgLinear">{{ lan.admin[4].Opportunity_page[0].Tasks }}</h5>
                      <table
                        class="pointer"
                      >
                        <tr
                          v-for="(data, index) in task_detail"
                          :key="index"
                          @click="editTask(data.task_id)"
                        >
                          <td>
                            <strong>{{ index + 1 }}</strong>
                          </td>
                          <td>
                            <p class="mb-0">{{ data.subject }}</p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>

                  <div class="row px-3">
                  <table class="col-md-6 bg-info-hover tr-rounded order-tbl">
                    <tbody>
                      <tr>
                        <td>
                          {{
                            lan.admin[4].Opportunity_page[0].Opportunity_owner
                          }}
                        </td>
                        <td>
                          <input
                            type="text"
                            name="other date"
                            class="init"
                            :placeholder="user.name"
                            disabled
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                 </div>

                  <div class="mb-3 mt-4">
                    <h4 class="fs-20 text-black">
                      {{ lan.admin[4].Opportunity_page[0].Opportunity }}
                    </h4>
                  </div>

                  <div class="card-body tab-content p-0">
                    <div class="tab-pane active show fade" id="monthly">
                      <!-- <div class="d-flex mb-4">
                        <select
                          name=""
                          class="select-01"
                          style="width: 30%; height: 43px"
                          id=""
                        >
                          <option value="">Contact</option>
                          <option value="">Contact 1</option>
                          <option value="">Contact 2</option>
                        </select>

                        <a href="contact-form.html"
                          ><button
                            class="filter-btn mb-0"
                            style="margin-left: 100px"
                          >
                            Create new contact
                          </button></a
                        >
                      </div> -->

                      <div class="table-responsive">
                        <table class="bg-info-hover tr-rounded order-tbl">
                          <tbody>
                            <tr>
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0].Our_customer
                                }}
                              </td>
                              <td class="">
                                <div class="row align-items-center">
                                  <div
                                    class="col-xl-5 col-6 px-xl-1 pl-xl-3 mb-xl-0 mb-2"
                                  >
                                  <model-list-select :list="customerAccountDetails"
                                    @change="checkContact()"
                                    style="min-height: 43px; border: 1px solid #ccc; border-radius: 2px;"
                                    v-model="account_id"
                                    option-value="account_id"
                                    :custom-text="getName"
                                    :placeholder="lan.admin[4].Opportunity_page[0].Select_Account"
                                    >
                                  </model-list-select>
                                  </div>
                                  <div
                                    class="col-xl-5 col-6 px-xl-1 mb-xl-0 mb-2"
                                  >
                                    <select
                                      class="select"
                                      style="height: 43px"
                                      v-model="contact_id"
                                    >
                                      <option value="" disabled selected>
                                        {{lan.admin[4].Opportunity_page[0].Select_Contact}}
                                      </option>
                                      <option
                                        :value="data.id"
                                        v-for="(
                                          data, index
                                        ) in contact_details_by_account_id"
                                        :key="index.id"
                                      >
                                        {{ data.first_name }}
                                        {{ data.last_name }}
                                      </option>
                                    </select>
                                  </div>

                                  <div class="col-2 px-xl-1">
                                    <div class="dropdown mb-0">
                                      <div class="dropdown-toggle select-Btn" type="button" data-toggle="dropdown" aria-expanded="false">
                                        {{ lan.admin[4].Opportunity_page[0].New }}
                                      </div>
  
                                      <input type="checkbox" class="dd-input d-none" id="test">
  
                                      <ul class="dropdown-menu py-0 mt-1">
                                        <button
                                        class="search-btn-new fs-12 ml-0 px-0"
                                        style="width: 100%; height: 42px"
                                        data-toggle="modal"
                                        data-target="#AddAccountModel"
                                      >
                                       {{ lan.admin[4].Opportunity_page[0].New_Account }}
                                      </button>
                                      <li class="divider"></li>
                                      <button
                                        class="search-btn-new fs-12 ml-0 px-0"
                                        style="width: 100%; height: 42px"
                                        data-toggle="modal"
                                        data-target="#exampleModal22"
                                      >
                                       {{ lan.admin[4].Opportunity_page[0].New_Contact }}
                                      </button>
  
                                        <!-- <li>
                                          <a href="http://rane.io">Link to Rane.io</a>
                                        </li> -->
                                      </ul>
                                    </div>
                                  </div>
                                



                                  <!-- <div class="col-xl-3 col-6 px-xl-1">
                                    <button
                                      class="search-btn-new ml-0 px-2"
                                      style="width: 100%; height: 42px"
                                      data-toggle="modal"
                                      data-target="#AddAccountModel"
                                    >
                                     {{ lan.admin[4].Opportunity_page[0].Create_Account }}
                                    </button>
                                  </div>
                                  <div class="col-xl-3 col-6 px-xl-1 pr-xl-3">
                                    <button
                                      class="search-btn-new ml-0 px-2"
                                      style="width: 100%; height: 42px"
                                      data-toggle="modal"
                                      data-target="#exampleModal22"
                                    >
                                     {{ lan.admin[4].Opportunity_page[0].Create_Contact }}
                                    </button>
                                  </div> -->
                                </div>
                              </td>
                            </tr>

                            <!-- <tr>
                              <td>Email</td>
                              <td>
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  v-model="email"
                                  placeholder="Enter email ID to invite cutomer"
                                  id=""
                                />
                              </td>
                            </tr> -->
                            <tr>
                              <td>
                                {{ lan.admin[4].Opportunity_page[0].Date }}
                              </td>
                              <td>
                                <input
                                  class="init mr-3"
                                  style="width: 25%;"
                                  type="date"
                                  name="other date"
                                  v-model="date_of_conference_start"
                                  placeholder="Date"
                                  @change="incrementDate"
                                />
                                <span>To</span>
                                <input
                                  class="init ml-3"
                                  style="width: 25%;"
                                  type="date"
                                  name="other date"
                                  v-model="date_of_conference_end"
                                  placeholder="Date"
                                  id=""
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Number_of_participants
                                }}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  name="other date"
                                  class="init"
                                  style="width: 20%"
                                  v-model="number_of_participants"
                                  min="0"
                                  id=""
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>{{lan.admin[4].Opportunity_page[0].Type_Of_Conference}}</td>
                              <td>
                                <select
                                  class="init"
                                  style="width: 40%; height: 43px"
                                  v-model="type_of_conference"
                                >
                                  <!-- <option value="" selected>
                                    {{lan.admin[4].Opportunity_page[0].Select_Type_Of_Conference}}
                                  </option> -->
                                  <option
                                    value="Conference with overnight stay"
                                  >
                                  {{lan.admin[4].Opportunity_page[0].Conference_with_overnight_stay}}
                                  </option>
                                  <option value="Day conference">
                                    {{lan.admin[4].Opportunity_page[0].Day_conference}}
                                  </option>
                                </select>
                              </td>
                            </tr>

                            <tr
                              v-if="
                                type_of_conference ==
                                'Conference with overnight stay'
                              "
                            >
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0].Accomodations
                                }}
                              </td>
                              <td>
                                <select
                                  class="init"
                                  style="width: 40%; height: 43px"
                                  v-model="accomodations"
                                >
                                  <!-- <option value="" disabled selected>
                                    Select Accomodation
                                  </option> -->
                                  <option
                                    :value="data.product_name"
                                    v-for="(
                                      data, index
                                    ) in productOnlyAcomdation"
                                    :key="index"
                                  >
                                    {{ data.product_name }}
                                  </option>
                                </select>
                                <!-- <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  v-model="
                                    number_of_participants_in_single_room
                                  "
                                  placeholder="Accomodations"
                                  id=""
                                /> -->
                              </td>
                            </tr>
                            <tr v-if="check_acc_Single_double == true">
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Number_of_participants_in_single_room
                                }}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  class="init"
                                  style="width: 20%"
                                  v-model="
                                    number_of_participants_in_single_room
                                  "
                                  min="0"
                                  id=""
                                />
                              </td>
                            </tr>
                            <tr v-if="check_acc_Single_double == true">
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Number_of_participants_in_double_room
                                }}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  class="init"
                                  style="width: 20%"
                                  v-model="no_of_people_in_shared_double_rooms"
                                  min="0"
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0].Arrival_time
                                }}
                              </td>
                              <td>
                                <input
                                  type="time"
                                  name="other date"
                                  class="init"
                                  style="width: 20%"
                                  v-model="arrival_time"
                                  id=""
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Departure_time
                                }}
                              </td>
                              <td>
                                <input
                                  type="time"
                                  name="other date"
                                  class="init"
                                  style="width: 20%"
                                  v-model="departure_time"
                                  id=""
                                />
                              </td>
                            </tr>

                            <tr>
                              <td>
                                {{
                                  lan.admin[4].Opportunity_page[0]
                                    .Requested_size_of_the_conference_room
                                }}
                              </td>

                              <td>
                                <input
                                  type="number"
                                  name="other date"
                                  class="init"
                                  style="width: 20%"
                                  min="0"
                                  v-model="no_of_participants_in_conf"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                        <form action="">
                          <div class="col-md-6 px-0 mt-4">
                            <label
                              >{{
                                lan.admin[4].Opportunity_page[0]
                                  .Prices_should_be
                              }}<span>*</span></label
                            ><br />
                            <input
                              type="radio"
                              id="html"
                              name="fav_language"
                              value="excl vat"
                              v-model="vat"
                            />
                            &nbsp;
                            <label for="html">{{
                              lan.admin[4].Opportunity_page[0].excl_VAT
                            }}</label>
                            &nbsp;
                            <input
                              type="radio"
                              id="css"
                              name="fav_language"
                              value="incl vat"
                              v-model="vat"
                            />
                            &nbsp;
                            <label for="css">{{
                              lan.admin[4].Opportunity_page[0].inc_VAT
                            }}</label>
                          </div>
                          <textarea
                            v-model="free_text_message"
                            cols="20"
                            rows="5"
                            style="width: 50%"
                            class="mt-4"
                            placeholder="Comments"
                          ></textarea>

                          <div class="mb-3 mt-4">
                            <h4 class="fs-20 text-black">
                              {{
                                lan.admin[4].Opportunity_page[0].Needs_analysis
                              }}
                            </h4>
                          </div>

                          <textarea
                            name=""
                            id=""
                            cols="20"
                            rows="10"
                            class="mt-4"
                            v-model="opportunity_comments"
                          >
    Comments</textarea
                          >
                        </form>

                        <div class="mb-3 mt-4">
                          <h4 class="fs-20 text-black">
                            {{
                              lan.admin[4].Opportunity_page[0]
                                .Common_questions_to_ask_customer
                            }}
                          </h4>
                        </div>

                        <!--for hide and show accrodian start-->
                        <div
                          @click="hideOpenAccordian()"
                          class="d-flex justify-content-between px-2 py-3 mb-4 mainAccordian"
                        >
                          <div>
                            <h5 class="mb-0">
                              {{
                                lan.admin[4].Opportunity_page[0]
                                  .Open_All_Questions
                              }}
                            </h5>
                          </div>
                          <div>
                            <i class="fa-solid fa-caret-down topCaret"></i>
                          </div>
                        </div>
                        <!--for hide and show accrodian end-->

                        <div v-show="hideAccordian" id="accordion">
                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingOne"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link"
                                  data-toggle="collapse"
                                  data-target="#collapseOne"
                                  aria-expanded="false"
                                  aria-controls="collapseOne"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Are_the_dates_set_or_can_you_consider_other_alternative_dates
                                  }}

                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>

                            <div
                              id="collapseOne"
                              class="collapse"
                              aria-labelledby="headingOne"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Alternative_dates
                                  "
                                  id=""
                                  v-model="addCommonQuestion[0].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingTwo"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseTwo"
                                  aria-expanded="false"
                                  aria-controls="collapseTwo"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .How_many_participants_are_you
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseTwo"
                              class="collapse"
                              aria-labelledby="headingTwo"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[1].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingThree"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseThree"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Can_you_give_me_an_estimated_plan_for_your_conference
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseThree"
                              class="collapse"
                              aria-labelledby="headingThree"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Alternative_dates
                                  "
                                  id=""
                                  v-model="addCommonQuestion[2].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingFour"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseFour"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Day_conference_or_overnight_stay
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseFour"
                              class="collapse"
                              aria-labelledby="headingFive"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Alternative_dates
                                  "
                                  id=""
                                  v-model="addCommonQuestion[3].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingFive"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseFive"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0].full_days
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseFive"
                              class="collapse"
                              aria-labelledby="headingThree"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[4].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingFive"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseFive"
                                  aria-expanded="false"
                                  aria-controls="collapseThree"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .In_which_region_are_you_planning_to_conduct_your_conference
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseFive"
                              class="collapse"
                              aria-labelledby="headingThree"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[5].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingSix"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseSix"
                                  aria-expanded="false"
                                  aria-controls="collapseSix"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Where_are_the_participants_coming_from
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseSix"
                              class="collapse"
                              aria-labelledby="headingSix"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[6].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingSeven"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseSeven"
                                  aria-expanded="false"
                                  aria-controls="collapseSeven"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Are_any_participants_arriving_by_airplane_Is_it_important_to_be_close_to_an_airport
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseSeven"
                              class="collapse"
                              aria-labelledby="headingSeven"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[7].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingEight"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseEight"
                                  aria-expanded="false"
                                  aria-controls="collapseSeven"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Where_is_your_office_located
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseEight"
                              class="collapse"
                              aria-labelledby="headingEight"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html"
                                  >{{
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  }}r</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[8].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingNine"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseNine"
                                  aria-expanded="false"
                                  aria-controls="collapseSeven"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Where_is_you_bossgroup_leader_located
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseNine"
                              class="collapse"
                              aria-labelledby="headingNine"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <label for="html">{{
                                  lan.admin[4].Opportunity_page[0]
                                    .Entering_answer
                                }}</label
                                ><br />
                                <input
                                  type="text"
                                  name="other date"
                                  class="init"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                  id=""
                                  v-model="addCommonQuestion[9].answer"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingTen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseTen"
                                  aria-expanded="false"
                                  aria-controls="collapseTen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .What_type_of_conference_venue_do_you_have_in_mind
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseTen"
                              class="collapse"
                              aria-labelledby="headingTen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init mt-2"
                                  v-model="addCommonQuestion[10].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingEleven"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseEleven"
                                  aria-expanded="false"
                                  aria-controls="collapseEleven"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Is_this_a_recurring_eventconference_Where_have_you_been_before
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseEleven"
                              class="collapse"
                              aria-labelledby="headingEleven"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[11].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingTwelve"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseTwelve"
                                  aria-expanded="false"
                                  aria-controls="collapseTwelve"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Whats_the_purpose_of_this_conference_What_is_important_for_you
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseTwelve"
                              class="collapse"
                              aria-labelledby="headingTwelve"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[12].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingThirteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseThirteen"
                                  aria-expanded="false"
                                  aria-controls="collapseThirteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .If_the_venue_cant_offer_you_single_room_could_you_consider_sharing_double_rooms
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseThirteen"
                              class="collapse"
                              aria-labelledby="headingThirteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[13].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingForteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseForteen"
                                  aria-expanded="false"
                                  aria-controls="collapseForteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Do_you_need_rooms_for_break_out_sessions
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseForteen"
                              class="collapse"
                              aria-labelledby="headingForteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[14].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingFifteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseFifteen"
                                  aria-expanded="false"
                                  aria-controls="collapseFifteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Are_you_looking_for_some_sort_of_team_building_activity
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseFifteen"
                              class="collapse"
                              aria-labelledby="headingFifteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[15].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingSixteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseSixteen"
                                  aria-expanded="false"
                                  aria-controls="collapseSixteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .If_yes_what_kind_Physical_demanding_or_mentally_demanding
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseSixteen"
                              class="collapse"
                              aria-labelledby="headingSixteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[16].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingSeveteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseSeveteen"
                                  aria-expanded="false"
                                  aria-controls="collapseSeveteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Whats_the_setup_of_the_group_Age_gender
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseSeveteen"
                              class="collapse"
                              aria-labelledby="headingSeveteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[17].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingEightteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseEightteen"
                                  aria-expanded="false"
                                  aria-controls="collapseEightteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Do_you_have_a_budget_for_the_event
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseEightteen"
                              class="collapse"
                              aria-labelledby="headingEightteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[18].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>

                          <div class="card card-new mb-0">
                            <div
                              class="card-header card-header-new"
                              id="headingEightteen"
                            >
                              <h5 class="mb-0">
                                <button
                                  class="btn btn-link collapsed"
                                  data-toggle="collapse"
                                  data-target="#collapseNineteen"
                                  aria-expanded="false"
                                  aria-controls="collapseNineteen"
                                >
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .By_when_do_you_need_the_quote
                                  }}
                                  <i class="fa-solid fa-caret-down"></i>
                                </button>
                              </h5>
                            </div>
                            <div
                              id="collapseNineteen"
                              class="collapse"
                              aria-labelledby="headingNineteen"
                              data-parent="#accordion"
                            >
                              <div class="card-body">
                                <input
                                  type="text"
                                  name="text"
                                  class="init"
                                  v-model="addCommonQuestion[19].answer"
                                  :placeholder="
                                    lan.admin[4].Opportunity_page[0]
                                      .Entering_answer
                                  "
                                />
                              </div>
                            </div>
                          </div>
                        </div>


                        <div v-if="type_of_conference == 'Conference with overnight stay'" >
                        <div
                          class="mb-3 mt-4"
                        >
                          <h4 class="fs-20 text-black">
                            {{
                              lan.admin[4].Opportunity_page[0]
                                .Add_accommodation
                            }}
                          </h4>
                        </div>
 
                        <div
                          class="table-responsive"
                        >
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th class="b-left">
                                  {{ lan.admin[4].Opportunity_page[0].Product }}
                                </th>
                                <th style="width: 10%;">
                                  {{ lan.admin[4].Opportunity_page[0].Date }}
                                </th>
                                <th style="width: 10%;">
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Number_of_participants
                                  }}
                                </th>
                                <th class="border-0"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in addAcomdation"
                                :key="index"
                              >
                                <td
                                  draggable="true"
                                  @dragstart="dragStart(index)"
                                  @dragover="dragOver"
                                  @drop="drop(index, 'accomodation')"
                                >
                                  <img
                                    src="../../../../public/images/drag.png"
                                    alt=""
                                    style="height: 25px; width: 25px"
                                  />
                                </td>
                                <td class="b-left b-buttom">
                                  <select
                                    name=""
                                    id=""
                                    class="select"
                                    v-model="data.product_id"
                                    @change="updateProduct(data, 'Accomodation')"
                                  >
                                  <option value="" disabled>Select product from the list</option>
                                    <option
                                      v-for="(
                                        data, index
                                      ) in productOnlyAcomdation"
                                      :key="index"
                                      :value="data.product_id"
                                    >
                                      {{ data.product_name }}
                                    </option>
                                  </select>
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="date"
                                    name="other date"
                                    class="init"
                                    v-model="data.date"
                                    id=""
                                  />
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="text"
                                    name="other date"
                                    class="init"
                                    v-model="data.room"
                                    placeholder="NUMBER FROM LEAD"
                                    id=""
                                  />
                                </td>
                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteRow(index, 'Accomodation')"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                          <div class="d-flex justify-content-end mt-3 mr-md-2">
                            <button
                              class="filter-btn up-btn mr-md-5"
                              @click="addRow('Accomodation')"
                            >
                              {{ lan.admin[4].Opportunity_page[0].Add_new_row }}
                            </button>
                          </div>
                        </div>

                        <div class="mb-3 mt-4">
                          <h4 class="fs-20 text-black">{{ lan.admin[4].Opportunity_page[0].Add_meals }}</h4>
                        </div>

                        <div class="table-responsive">
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th class="b-left">
                                  {{ lan.admin[4].Opportunity_page[0].Product }}
                                </th>
                                <th style="width: 10%;">
                                  {{ lan.admin[4].Opportunity_page[0].Date }}
                                </th>
                                <th style="width: 10%;">
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Number_of_participants
                                  }}
                                </th>
                                <th class="border-0"></th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in addMeals"
                                :key="index"
                              >
                                <td
                                  draggable="true"
                                  @dragstart="dragStart(index)"
                                  @dragover="dragOver"
                                  @drop="drop(index, 'meals')"
                                >
                                  <img
                                    src="../../../../public/images/drag.png"
                                    alt=""
                                    style="height: 25px; width: 25px"
                                  />
                                </td>
                                <td class="b-left b-buttom">
                                  <select
                                    name=""
                                    id=""
                                    class="select"
                                    v-model="data.product_id"
                                    @change="updateProduct(data, 'Meals')"
                                  >
                                  <option value="" disabled>Select product from the list</option>
                                    <option
                                      v-for="(data, index) in productOnlyMeals"
                                      :key="index"
                                      :value="data.product_id"
                                    >
                                      {{ data.product_name }}
                                    </option>
                                  </select>
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="date"
                                    name="other date"
                                    class="init"
                                    v-model="data.date"
                                    placeholder="Our customer"
                                    id=""
                                  />
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="text"
                                    name="other date"
                                    class="init"
                                    v-model="data.room"
                                    placeholder="NUMBER FROM LEAD"
                                    id=""
                                  />
                                </td>
                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteRow(index, 'Meals')"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                          <div class="d-flex justify-content-end mt-3 mr-md-2">
                            <button
                              class="filter-btn up-btn mr-md-5"
                              @click="addRow('Meals')"
                            >
                              {{ lan.admin[4].Opportunity_page[0].Add_new_row }}
                            </button>
                          </div>


                          <div class="mb-3 mt-4">
                            <h4 class="fs-20 text-black">
                              {{
                                lan.admin[4].Opportunity_page[0]
                                  .Add_other_products
                              }}
                            </h4>
                          </div>

                          <div class="table-responsive">
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th class="b-left">
                                  {{ lan.admin[4].Opportunity_page[0].Product }}
                                </th>
                                <!-- <th class="comment-01">
                                  {{ lan.admin[4].Opportunity_page[0].Comment }}
                                </th> -->
                                <th>
                                  {{ lan.admin[4].Opportunity_page[0].Date }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Number_of_participants
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in addOtherProduct"
                                :key="index"
                              >
                                <td
                                  draggable="true"
                                  @dragstart="dragStart(index)"
                                  @dragover="dragOver"
                                  @drop="drop(index, 'other')"
                                >
                                  <img
                                    src="../../../../public/images/drag.png"
                                    alt=""
                                    style="height: 25px; width: 25px"
                                  />
                                </td>
                                <td class="b-left b-buttom">
                                  <select class="select" v-model="data.product_id" @change="updateProduct(data, 'OtherProduct')">
                                    <option value="" disabled>Select product from the list</option>
                                    <option
                                      v-for="(data, index) in productOnlyOthers"
                                      :key="index"
                                      :value="data.product_id"
                                    >
                                      {{ data.product_name }}
                                    </option>
                                  </select>
                                </td>
                                <!-- <td class="b-buttom">
                                  <input
                                    type="text"
                                    class="init"
                                    v-model="data.comment"
                                  />
                                </td> -->
                                <td class="b-buttom">
                                  <input
                                    type="date"
                                    name="other date"
                                    class="init"
                                    placeholder="Our customer"
                                    v-model="data.date"
                                  />
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="text"
                                    name="other date"
                                    class="init"
                                    v-model="data.room"
                                    placeholder="NUMBER FROM LEAD"
                                    id=""
                                  />
                                </td>

                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteRow(index, 'OtherProduct')"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                          <div class="d-flex justify-content-end mt-3 mr-md-2">
                            <button
                              class="filter-btn up-btn mr-md-5"
                              @click="addRow('OtherProduct')"
                            >
                              {{ lan.admin[4].Opportunity_page[0].Add_new_row }}
                            </button>
                          </div>
                          

                        <div class="mb-3 mt-4">
                          <h4 class="fs-20 text-black">
                            {{
                              lan.admin[4].Opportunity_page[0]
                                .Add_Conference_room
                            }}
                          </h4>
                        </div>

                        <div class="table-responsive">
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th></th>
                                <th class="b-left">
                                  {{ lan.admin[4].Opportunity_page[0].Product }}
                                </th>
                                <th class="comment">
                                  {{ lan.admin[4].Opportunity_page[0].Comment }}
                                </th>
                                <th>
                                  {{ lan.admin[4].Opportunity_page[0].Date }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Requested_size
                                  }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Seating_type
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in addConferenceRoom"
                                :key="index"
                              >
                                <td
                                  draggable="true"
                                  @dragstart="dragStart(index)"
                                  @dragover="dragOver"
                                  @drop="drop(index, 'conference')"
                                >
                                  <img
                                    src="../../../../public/images/drag.png"
                                    alt=""
                                    style="height: 25px; width: 25px"
                                  />
                                </td>
                                <td class="b-left b-buttom">
                                  <select
                                    name=""
                                    id=""
                                    class="select"
                                    v-model="data.product_id"
                                    @change="updateProduct(data, 'Conference')"
                                  >
                                  <option value="" disabled>Select product from the list</option>
                                    <option
                                      v-for="(
                                        data, index
                                      ) in productOnlyConferenceRoom"
                                      :key="index"
                                      :value="data.product_id"
                                    >
                                      {{ data.product_name }}
                                    </option>
                                  </select>
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="text"
                                    id="html"
                                    class="init"
                                    name="fav_language"
                                    v-model="data.comment"
                                  />
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="date"
                                    name="other date"
                                    class="init"
                                    id=""
                                    v-model="data.date"
                                  />
                                </td>
                                <td class="b-buttom">
                                  <input
                                    type="text"
                                    id="html"
                                    class="init"
                                    name="fav_language"
                                    placeholder="Number form lead"
                                    v-model="data.request_size"
                                  />
                                </td>
                                <td class="b-buttom">
                                  <select class="select" v-model="data.seating">
                                    <option value="Cinema Seating">
                                      {{lan.admin[4].Opportunity_page[0].Cinema_Seating}}
                                    </option>
                                    <option value="U-shaped table">
                                      {{lan.admin[4].Opportunity_page[0].Ushaped_table}}
                                    </option>
                                    <option value="Boardroom">{{lan.admin[4].Opportunity_page[0].Boardroom}}</option>
                                    <option value="School Seating">
                                      {{lan.admin[4].Opportunity_page[0].School_Seating}}
                                    </option>
                                    <option value="Islands">{{lan.admin[4].Opportunity_page[0].Islands}}</option>
                                    <option value="Don't know at the moment">
                                      {{lan.admin[4].Opportunity_page[0].Dont_know_at_the_moment}}
                                    </option>
                                  </select>
                                </td>
                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteRow(index, 'Product')"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                          <div class="d-flex justify-content-end mt-3 mr-md-2">
                            <button
                              class="filter-btn up-btn mr-md-5"
                              @click="addRow('con Product')"
                            >
                              {{ lan.admin[4].Opportunity_page[0].Add_new_row }}
                            </button>
                          </div>


                          <div class="mb-3 mt-4">
                            <h4 class="fs-20 text-black">
                              {{
                                lan.admin[4].Opportunity_page[0]
                                  .Add_your_question_to_partners
                              }}
                            </h4>
                          </div>

                          <div class="table-responsive">
                            <table>
                              <tr>
                                <td>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Write_your_question
                                  }}
                                </td>
                                <td>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Select_question_type
                                  }}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    id="html"
                                    class="init"
                                    name="fav_language"
                                    v-model="add_question.question"
                                  />
                                </td>
                                <td>
                                  <select
                                    class="select-01 init-01"
                                    v-model="add_question.type"
                                  >
                                    <option value="dropdown">Dropdown</option>
                                    <option value="text">Text</option>
                                    <option value="radio">Radio</option>
                                  </select>
                                  <button @click="addQuestion()" class="ml-3 add-btn" :disabled="!add_question.question" >
                                    {{ lan.admin[4].Opportunity_page[0].Add }}
                                  </button>
                                </td>
                              </tr>
                            </table>
                          </div>

                          <div
                            class="mb-3 mt-4"
                            v-if="
                              additional_questions &&
                              additional_questions.length
                            "
                          >
                            <h4 class="fs-20 text-black">
                              {{
                                lan.admin[4].Opportunity_page[0]
                                  .Questions_to_partners
                              }}
                            </h4>
                          </div>

                          <div class="table-responsive">
                            <table
                              style="white-space: normal"
                              class="border-0"
                              v-for="(data, index) in additional_questions"
                              :key="index"
                            >
                            <div class="d-flex question_to_partner">
                              <div class="drag">
                                <td
                                  style="border-right: none;"
                                  draggable="true"
                                  @dragstart="dragStart(index)"
                                  @dragover="dragOver"
                                  @drop="drop(index, 'question')"
                                >
                                  <img
                                    src="../../../../public/images/drag.png"
                                    alt=""
                                    style="height: 25px; width: 25px"
                                  />
                                </td>
                              </div>

                              <div class="texts" style="width: 100%;">
                              <tr>
                                <td class="b-left">{{ data.question }}</td>
                              </tr>

                              <tr v-if="data.inputType == 'text'">
                                <td class="b-left b-buttom" style="width: 100%">
                                  <input
                                    type="text"
                                    name="other date"
                                    class="init"
                                    id=""
                                    v-model="data.answer"
                                  />
                                </td>

                                <td class="border-0"></td>

                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteQuestion(index)"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>

                              <tr v-else-if="data.inputType == 'dropdown'">
                                <td class="b-left b-buttom" style="width: 100%;">
                                  <select class="select" v-model="data.answer" @change="selectDropdown(index)">
                                    <option v-if="data.dropdown.length == 0" disabled>
                                      Empty
                                    </option>
                                    <option
                                      :value="indexx"
                                      v-for="(dataa, indexx) in data.dropdown"
                                      :key="indexx"
                                    >
                                      {{ dataa.name }}
                                    </option>
                                  </select>
                                  <div v-if="data.dropdown.length" class="tag-container">
                                    <h5>{{ lan.admin[4].Opportunity_page[0].Options }}:- </h5>
                                    <span v-for="item in data.dropdown" :key="item" class="tag">
                                      {{ item.name }}
                                      <i class="fa fa-times" @click="optionsDropdownDelete(item,index)"></i>
                                    </span>
                                  </div>
                                  <div class="mt-3 row align-items-end">
                                    <div class="col-6">
                                      <div v-if="data.answertags.length" class="tag-container">
                                        <h5 class="mr-2">{{ lan.admin[4].Opportunity_page[0].Selected }}:- </h5>
                                        <span v-for="item in data.answertags" :key="item" class="tag">
                                          {{ item.name }}
                                          <i class="fa fa-times" @click="selectDropdownDelete(item,index)"></i>
                                        </span>
                                      </div>
                                    <input
                                      class="init"
                                      style="width: 60%;"
                                      type="text"
                                      v-model="dropdown_option"
                                    />
                                    <button
                                      class="ml-3"
                                      @click="addDropdown(index)"
                                    >
                                     {{ lan.admin[4].Opportunity_page[0].Add }}
                                    </button>
                                  </div>
                                  <div class="col-6">
                                      <label class="d-block">{{ lan.admin[4].Opportunity_page[0].Set_Maximum_select }}</label>
                                      <input
                                      class="init init-01"
                                      type="number"
                                      v-model="data.maxSelect"
                                      :placeholder="lan.admin[4].Opportunity_page[0].Set_Maximum_select"
                                      />
                                    </div>
                                  </div>
                                </td>

                                <td class="border-0"></td>

                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteQuestion(index)"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>

                              <tr v-else-if="data.inputType == 'radio'">
                                <td
                                  class="b-left b-buttom"
                                  style="
                                    width: 100%;
                                    text-align: end;
                                    padding-right: 20px;
                                  "
                                >
                                  &nbsp;
                                  <input
                                    type="radio"
                                    id="html"
                                    name="fav_language"
                                    value="Yes"
                                    v-model="data.answer"
                                  />
                                  &nbsp;
                                  <label for="Yes">Yes</label>
                                  &nbsp;
                                  <input
                                    type="radio"
                                    id="css"
                                    name="fav_language"
                                    value="No"
                                    v-model="data.answer"
                                  />
                                  &nbsp;
                                  <label for="No">No</label>
                                </td>

                                <td class="border-0"></td>

                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteQuestion(index)"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </div>
                          </div>
                            </table>
                          </div>

                          <!-- <div class="input d-flex mb-4">
                            <div class="devide-01">
                              <label>New note<span>*</span></label
                              ><br />
                              <div class="container22">
                                <table>
                                  <tr
                                    v-for="(data, index) in allNoteData"
                                    :key="index"
                                  >
                                    <td>
                                      <input
                                        type="checkbox"
                                        v-model="noteCheckbox"
                                        :value="data.note_id"
                                      />
                                    </td>
                                    <td>{{ data.subject }}</td>
                                    
                                  </tr>
                                </table>
                              </div>
                            </div>

                            <div class="devide-01 ml-3">
                              <label>New task<span>*</span></label
                              ><br />
                              <div class="container22">
                                <table>
                                  <tr
                                    v-for="(data, index) in allTaskData"
                                    :key="index"
                                  >
                                    <td>
                                      <input
                                        type="checkbox"
                                        v-model="taskCheckbox"
                                        :value="data.task_id"
                                      />
                                    </td>
                                    <td>{{ data.subject }}</td>
                                    
                                  </tr>
                                </table>
                              </div>
                            </div>
                          </div> -->

                          <div class="mb-3 mt-4">
                            <h4 class="fs-20 text-black">
                              {{ lan.admin[4].Opportunity_page[0].Partner }}
                            </h4>
                          </div>

                          <div class="table-responsive">
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Partner_venue_name
                                  }}
                                </th>
                                <th>
                                  {{ lan.admin[4].Opportunity_page[0].Status }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Send_invitation
                                  }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Resend_invitation
                                  }}
                                </th>
                                <th>
                                  {{ lan.admin[4].Opportunity_page[0].Quote }}
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Present_to_customer
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in addBidders"
                                :key="index"
                              >

                              <!-- <td class="b-left">
          <div class="dropdown-container">
            <input
              type="text"
              class="dropdown-input"
              :placeholder="data.selectedVenue || 'Select an option'"
              v-model="data.searchQuery"
              @click="openDropdown(index)"
              @change="filterDropdown()"
              />
            <div class="dropdown-list" v-if="data.dropdownOpen">
              <div
                class="dropdown-item"
                v-for="(data1, idx) in filter_bidder"
                :key="idx"
                @click="selectOption(data, data1.account_id)"
                :class="{ 'disabled-option': data1.disabled }"
              >
                {{ data1.venue_name }}
              </div>
            </div>
          </div>
        </td> -->


                                <td class="b-left">
                                  <select
                                    class="select-01"
                                    v-model="data.account_id"
                                  >
                                    <option
                                      v-for="(data1, index) in filter_bidder"
                                      :key="index"
                                      :value="data1.account_id"
                                      :disabled="data.status == 'Invitation not sent' && !data1.disabled ? false : data.status != 'Invitation not sent' ? true : data1.disabled"
                                      :class="data1.disabled == false ?'dropdown-color-green':'dropdown-color-red'"
                                    >
                                    {{ data1.is_outside_partner != 1 ? data1.venue_name ? data1.venue_name : data1.name : data1.venue_name ? data1.venue_name : data1.name ? data1.name : data1.email}} 
                                    {{ data1.is_outside_partner == 1 || data1.is_outside_partner == '1' ? '&#127358' : ''}}
                                    </option>
                                  </select>
                                </td>
                                <td>
                                  {{
                                    data.status
                                  }}
                                </td>
                                <td>
                                  <span
                                    class="badge badge-success pointer"
                                    v-show="loader_Loading_T[index].send"
                                    data-target="#exampleModal-676"
                                    data-toggle="modal"
                                    @click="invetation_Set_RawData(data, index, 'Send')"
                                    >{{
                                      data.send_invitation == 1 ? lan.admin[4].Opportunity_page[0].Send : ""
                                    }}</span
                                  >
                                  <div
                                    class="lds-ellipsis"
                                    v-show="loader_Loading_C[index].send"
                                  >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </td>
                                <td>
                                  <span
                                    class="badge badge-danger pointer"
                                    v-show="loader_Loading_T[index].resend"
                                    @click="
                                      invetation_Set_RawData(data, index, 'Resend')
                                    "
                                    >{{
                                      data.resend_invitation == 1
                                        ? lan.admin[4].Opportunity_page[0].Resend
                                        : ""
                                    }}</span
                                  >
                                  <div
                                    class="lds-ellipsis"
                                    v-show="loader_Loading_C[index].resend"
                                  >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </td>

                                <td>
                                  <button
                                    v-if="data.status == 'Quote Submitted'"
                                    @click="edit(data.quote_id)"
                                  >
                                   {{ lan.admin[4].Opportunity_page[0].Open_quote }}
                                  </button>
                                </td>

                                <td>
                                  <input
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                    v-model="data.present_to_customer"
                                    :disabled="
                                      data.status == 'Quote Submitted'
                                        ? false
                                        : true
                                    "
                                  />
                                </td>
                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteRow(index, 'Bidder')"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                          <!-- <div class="table-responsive">
                          <table class="text-center bg-info-hover tr-rounded order-tbl mt-5">
                            <thead>
                              <tr>
                                <th>
                                  Partner Email id
                                </th>
                                <th>
                                  Partner Name
                                </th>
                                <th>
                                  Status
                                </th>
                                <th>
                                  Invitation
                                </th>
                                <th>
                                  {{
                                    lan.admin[4].Opportunity_page[0]
                                      .Present_to_customer
                                  }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(data, index) in addBidders_Outside" :key="index" >
                                <td>
                                  <input class="select-01" type="text" v-model="data.email" placeholder="Enter Email id">
                                </td>
                                <td>
                                  <input class="select-01" type="text" v-model="data.name" placeholder="Enter Name">
                                </td>
                                <td>
                                  {{ data.status }}
                                </td>
                                <td>
                                  <span
                                    class="badge badge-success pointer"
                                    v-show="loader_Loading_T_2[index].send"
                                    @click="invetationSend_outside_partner(data, index, 'Send')"
                                    >{{
                                      data.send_invitation == 1 ? "Send" : "Resend"
                                    }}</span
                                  >
                                  <div
                                    class="lds-ellipsis"
                                    v-show="loader_Loading_C_2[index].send"
                                  >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="checkbox"
                                    id="vehicle1"
                                    name="vehicle1"
                                    value="Bike"
                                    v-model="data.present_to_customer"
                                    :disabled="
                                      data.status == 'Quote Submitted'
                                        ? false
                                        : true
                                    "
                                  />
                                </td>
                                <td class="border-0">
                                  <button
                                    class="btn btn-danger shadow btn-xs sharp"
                                    @click="deleteRow(index, 'Bidder_Outside')"
                                  >
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div> -->
                          <div class="d-flex justify-content-end mt-3">
                            <button
                              class="filter-btn c-btn"
                              @click="addRow('AddBidder')"
                            >
                              {{ lan.admin[4].Opportunity_page[0].add_partner }}
                            </button>
                            <button
                              class="filter-btn c-btn"
                              data-toggle="modal"
                              data-target="#AddAccount_Outside_Model"
                            >
                              {{ lan.admin[4].Opportunity_page[0].new_partner }}
                            </button>
                            <!-- <button
                              class="filter-btn"
                              @click="addRow('AddBidderOutside')"
                            >
                            Add outside partner
                            </button> -->
                          </div>


                        <div class="d-flex justify-content-end mt-5 lower-btns-div">
                          <button class="filter-btn up-btn mb-3" @click="clearRawData()">
                            {{ lan.admin[4].Opportunity_page[0].Reset_Data }}
                          </button>
                          <button class="filter-btn up-btn mb-3" @click="sendPreview()">
                            {{ lan.admin[4].Opportunity_page[0].Preview }}
                          </button>
                          <!-- <button
                            class="filter-btn"
                            @click="inviteToCustomer()"
                          >
                            <div
                              class="lds-ring"
                              v-show="loadingSendCustomerLoader"
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-show="loadingSendCustomerText">
                              Invite customer to quote</span
                            >
                          </button> -->
                          <button class="search-btn up-btn ml-3" @click="save()" style="min-width: 210px;">
                            <div class="lds-ring" v-show="loadingSaveOppLoader">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-show="loadingSaveOppText"
                              >{{
                                lan.admin[4].Opportunity_page[0]
                                  .Save_Opportunity
                              }}
                            </span>
                          </button>
                          <router-link to="/opportunity"
                            ><button class="filter-btn up-btn">
                              {{ lan.admin[4].Opportunity_page[0].Close }}
                            </button></router-link
                          >
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

            <!-- Modal Edit email or send invitation -->
            <div
            class="modal fade"
            id="exampleModal-676"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered" style="max-width: 400px">
              <div class="modal-content">
                <div class="modal-header py-3">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Choose
                  </h5>
                  <button
                  id="exampleModal-676_closeX"
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body p-md-4 p-3">
                   <div class="d-flex justify-content-between">
                      <button type="button" data-target="#exampleModal-989" data-toggle="modal" class="btn btn-info px-5 mb-0">
                        Edit Email
                      </button> 
                      <button type="button" class="btn btn-success px-5 mb-0 ml-0" @click="invetationSend()">
                        <div v-if="loader_send_invitation" class="lds-ring">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-if="!loader_send_invitation">Send</span>
                      </button> 
                   </div>
                 
                </div>
                <div class="modal-footer py-2">
                  <button
                    type="button"
                    class="btn btn-danger px-4 mb-0"
                    data-dismiss="modal"
                  >
                    {{ lan.admin[22].Email[0].Close }}
                  </button>
                  <!-- <button type="button" class="filter-btn up-btn mb-0">
                      {{ lan.admin[22].Email[0].Save_changes }}
                  </button> -->
                </div>
              </div>
            </div>
          </div>

      <!-- Edit Email Or Send Invitation -->
      <EditTemplateEmail v-if="invitation_send_rawdata.data != ''"  :emailData="invitation_send_rawdata" @email-updated="invetationSend" />

      <!-- Modal Note -->
      <AddNote
        :accountDetails="accountDetails"
        :opportunitiesDetails="opportunitiesDetails"
        :contactsDetails="contactsDetails"
        @data-updated-note="saveNewNoteHandle"
      />
      <!-- Modal Task -->
      <AddTask
        :accountDetails="accountDetails"
        :opportunitiesDetails="opportunitiesDetails"
        :contactsDetails="contactsDetails"
        :accountOwners="accountOwners"
        @data-updated-task="saveNewTaskHandle"
      />

      <AddConatact
        @event="checkContact"
        @getContact="getContact"
        :accountDetails="accountDetails"
        setRole="Customer"
        check_from="SelfOppo"
      />
      <AddAccount
        @getAccount="getAccount"
        :accountOwners="accountOwners"
        :contactsDetails="contactsDetails"
        account_type="Customer"
        check_from="SelfOppo"
      />
      <AddAccountOutside
        @getBidders="getBidders"
        :accountOwners="accountOwners"
        :contactsDetails="contactsDetails"
        account_type="Partner"
        check_from="SelfOppo"
      />

      <!--**********************************
      Content body end
  ***********************************-->
    </div>
    <!--**********************************
  Main wrapper end
  ***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import AddConatact from "../../../components/addContact";
import AddNote from "../../../components/addNote.vue";
import AddTask from "../../../components/addTask.vue";
import AddAccount from "../../../components/addAccount";
import AddAccountOutside from "../../../components/addAccountOutside";
import EditTemplateEmail from "../../../components/editTemplateEmailForInvitation";
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select'
// @ is an alias to /src
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed } from "vue";

export default {
  name: "Add Opportunity",
  components: {
    AddNote,
    AddTask,
    AddConatact,
    AddAccount,
    AddAccountOutside,
    EditTemplateEmail,
    ModelListSelect
  },
  
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      opportunity_id: "",
    });

    const rules = computed(() => {
      return {
        opportunity_id: { required },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      user_details: "",
      // crreate dynamic table
      productOnlyAcomdation: [],
      productOnlyAcomdationMeals: [],
      productOnlyMeals: [],
      productOnlyConferenceRoom: [],
      productOnlyOthers: [],
      add_question: {
        question: "",
        type: "",
      },
      dropdown_option: "",

      // Details
      bidders: "",
      bidders_clone: "",
      selectAccountDetails: "",
      selectAccountDetailsName: "",
      accountDetails: "",
      customerAccountDetails: [],
      contactsDetails: [],
      opportunitiesDetails: "",
      leadsDetails: "",
      contact_details_by_account_id: "",
      accountOwners: "",
      note_detail: [],
      task_detail: [],

      invitation_send_rawdata: {
        data: "",
        index: "",
        action: "",
        emailContent: "",
      },

      // post opportunityDetails
      opportunity_name: "",
      email: "",
      arrival_time: "",
      departure_time: "",
      type_of_conference: "",
      conference_room_setup: "",
      number_of_participants: "",
      number_of_participants_in_single_room: "",
      no_of_people_in_shared_double_rooms: "",
      accomodations: "",
      no_of_participants_in_conf: "",
      created_at: "",
      free_text_message: "",
      date_of_conference_start: "",
      date_of_conference_end: "",
      opportunity_comments: "",
      vat: "",
      account_id: "",
      contact_id: "",
      product_id: "",
      note: [],
      task: [],
      addAcomdation: [],
      addMeals: [],
      addConferenceRoom: [],
      addOtherProduct: [],
      addBidders: [],
      addBidders_Outside: [],
      additional_questions: [],
      addCommonQuestion: [
        {
          1: "Are the dates set or can you consider other alternative dates?",
          answer: "",
        },
        {
          2: "How many participants are you?",
          answer: "",
        },
        {
          3: "Can you give me an estimated plan for your conference?",
          answer: "",
        },
        {
          4: "Day conference or overnight stay?",
          answer: "",
        },
        {
          5: "2 full days?",
          answer: "",
        },
        {
          6: "In which region are you planning to conduct your conference?",
          answer: "",
        },
        {
          7: "Where are the participants coming from?",
          answer: "",
        },
        {
          8: "Are any participants arriving by airplane? Is it important to be close to an airport?",
          answer: "",
        },
        {
          9: " Where is your office located?",
          answer: "",
        },
        {
          10: "Where is you boss/group leader located?",
          answer: "",
        },
        {
          11: "What type of conference venue do you have in mind?",
          answer: "",
        },
        {
          12: "Is this a recurring event/conference? Where have you been before?",
          answer: "",
        },
        {
          13: "Whats the purpose of this conference? What is important for you?",
          answer: "",
        },
        {
          14: "If the venue can’t offer you single room, could you consider sharing double rooms?",
          answer: "",
        },
        {
          15: "Do you need rooms for break out sessions?",
          answer: "",
        },
        {
          16: "Are you looking for some sort of team building activity?",
          answer: "",
        },
        {
          17: "If yes, what kind? Physical demanding or mentally demanding?",
          answer: "",
        },
        {
          18: "What’s the setup of the group? Age, gender?",
          answer: "",
        },
        {
          19: "Do you have a budget for the event?",
          answer: "",
        },
        {
          20: "By when do you need the quote?",
          answer: "",
        },
      ],

      //  Loading all buttons
      value: true,
      loadingSaveOppText: true,
      loader_send_invitation: false,
      loadingSaveOppLoader: false,
      loadingSaveNewTaskText: true,
      loadingSaveNewTaskLoader: false,
      loadingSaveNewNoteText: true,
      loadingSaveNewNoteLoader: false,
      loadingSendCustomerText: true,
      loadingSendCustomerLoader: false,
      hideAccordian: false,
      loader_Loading_C: [
        {
          send: false,
          resend: false,
        },
      ],
      loader_Loading_T: [
        {
          send: true,
          resend: true,
        },
      ],
      loader_Loading_C_2: [
        {
          send: false,
          resend: false,
        },
      ],
      loader_Loading_T_2: [
        {
          send: true,
          resend: true,
        },
      ],
      check_acc_Single_double: false,

      isDropdownOpen: false,
      searchQuery:''
    };
  },
  watch: {
    accomodations(newValue, oldValue) {
      // This function will be executed whenever `inputValue` changes.
      this.productOnlyAcomdation.forEach((check) => {
        // console.log('11s-',check)
        if (check.product_name == newValue) {
          // console.log('check-',check.product_name)
          if (check.type == "Double room") {
            // console.log('type-',check.type)
            this.check_acc_Single_double = true;
            return;
          } else {
            this.check_acc_Single_double = false;
            return;
          }
        }
      });
    },
     account_id(newValue) {
      if(newValue){
        this.checkContact();
      }
  },
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },

    lan() {
      return this.$store.getters.whichLanguage;
    },
    filter_bidder(){
      const data = this.addBidders.length !== 0
        ? this.bidders.map((bidder) => {
          console.log('bidder',bidder);
           return{
             ...bidder,
             disabled: this.addBidders.some(element => element.account_id === bidder.account_id),
             dropdownOpen: false,
            }
          })
        : this.bidders;

        data.sort((a, b) => {
          // Check if 'addBidders' property exists in both objects
          if (a && b) {
            return a.venue_name == '' ? a.name.localeCompare(b.venue_name == '' ? b.name : b.venue_name) : a.venue_name.localeCompare(b.venue_name == '' ? b.name : b.venue_name);
          } else {
            // If one of the objects doesn't have 'addBidders' property, treat it as greater
            return a ? -1 : 1;
          }
        });
      console.log('com- sort data--', data);
      return data;
    }
  },

  beforeMount() {
    // for top load page
    window.scrollTo(0, 0);
  },
  created() {

    this.$watch(
      () => ({
        user_details: this.user_details,

        departure_time: this.departure_time,
        arrival_time: this.arrival_time,
        type_of_conference: this.type_of_conference,
        conference_room_setup: this.conference_room_setup,
        number_of_participants: this.number_of_participants,
        number_of_participants_in_single_room:
          this.number_of_participants_in_single_room,
        no_of_people_in_shared_double_rooms:
          this.no_of_people_in_shared_double_rooms,
        accomodations: this.accomodations,
        no_of_participants_in_conf: this.no_of_participants_in_conf,
        created_at: this.created_at,
        free_text_message: this.free_text_message,
        date_of_conference_start: this.date_of_conference_start,
        date_of_conference_end: this.date_of_conference_end,
        opportunity_comments: this.opportunity_comments,
        vat: this.vat,
        account_id: this.account_id,
        contact_id: this.contact_id,
        product_id: this.product_id,
        note: this.note,
        task: this.task,
        addAcomdation: this.addAcomdation,
        addMeals: this.addMeals,
        addConferenceRoom: this.addConferenceRoom,
        addOtherProduct: this.addOtherProduct,
        // addBidders: this.addBidders,
        additional_questions: this.additional_questions,
      }),
      (newValues, oldValues) => {
        localStorage.setItem(
          "Unsaved_Self_OppoData",
          JSON.stringify(newValues)
        );
        // do whatever you want with the new and old values
      },
      { deep: true }
    );
  },
  mounted() {
    // document.addEventListener('click', event => {
    //   if (!event.target.closest('.dropdown-container')) {
    //     this.addBidders.forEach(data => {
    //       data.dropdownOpen = false;
    //     });
    //   }
    // });
    const selfLocalData = JSON.parse(
      localStorage.getItem("Unsaved_Self_OppoData")
    );

    const interval = setInterval(() => {
      if (selfLocalData != null) {
        (this.departure_time = selfLocalData.departure_time),
          (this.arrival_time = selfLocalData.arrival_time),
          (this.type_of_conference = selfLocalData.type_of_conference),
          (this.conference_room_setup = selfLocalData.conference_room_setup),
          (this.number_of_participants = selfLocalData.number_of_participants),
          (this.number_of_participants_in_single_room =
            selfLocalData.number_of_participants_in_single_room),
          (this.no_of_people_in_shared_double_rooms =
            selfLocalData.no_of_people_in_shared_double_rooms),
          (this.accomodations = selfLocalData.accomodations),
          (this.no_of_participants_in_conf =
            selfLocalData.no_of_participants_in_conf),
          (this.created_at = selfLocalData.created_at),
          (this.free_text_message = selfLocalData.free_text_message),
          (this.date_of_conference_start =
            selfLocalData.date_of_conference_start),
          (this.date_of_conference_end = selfLocalData.date_of_conference_end),
          (this.opportunity_comments = selfLocalData.opportunity_comments),
          (this.vat = selfLocalData.vat),
          (this.account_id = selfLocalData.account_id),
          (this.contact_id = selfLocalData.contact_id),
          (this.product_id = selfLocalData.product_id),
          (this.note = selfLocalData.note),
          (this.task = selfLocalData.task),
          (this.addAcomdation = selfLocalData.addAcomdation),
          (this.addMeals = selfLocalData.addMeals),
          (this.addConferenceRoom = selfLocalData.addConferenceRoom),
          (this.addOtherProduct = selfLocalData.addOtherProduct),
          // (this.addBidders = selfLocalData.addBidders),
          (this.additional_questions = selfLocalData.additional_questions)
          // console.log("selfLocalData", selfLocalData);
        if (this.productOnlyAcomdation && this.productOnlyAcomdation.length) {
          // console.log("productOnlyAcomdation--", this.productOnlyAcomdation);
          if(this.account_id){
            this.checkContact()
          }
          clearInterval(interval); // Stop the interval
        }
      }
    }, 1000);

    // alert(this.user_details.id);
    this.getAccount_owner();
    this.getContact();
    this.getOpportunits();
    // *************GET Accounts**********
    this.getAccount();

    // *************GET Products**********
    axios
      .get(axios.defaults.baseURL + "products", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log("product", res);
        res.data.data.forEach((element) => {
          if (
            element.product_family == "Accomodation" &&
            element.status == "1"
          ) {
            this.productOnlyAcomdation.push(element);
          }
          if (
            element.product_family == "Accomodation" ||
            (element.product_family == "Meals" && element.status == "1")
          ) {
            this.productOnlyAcomdationMeals.push(element);
          }
          if (element.product_family == "Meals" && element.status == "1") {
            this.productOnlyMeals.push(element);
          }
        });
        res.data.data.forEach((element) => {
          if (
            element.product_family == "Conference room" &&
            element.status == "1"
          ) {
            this.productOnlyConferenceRoom.push(element);
          }
        });
        // res.data.data.forEach((element) => {
        //   if (!element.product_family == "Conference room" || !element.product_family == "Accomodation") {
        //     console.log("Product family",element.product_family)
        //     this.productOnlyOthers.push(element);
        //   }
        // });
        res.data.data.forEach((element) => {
          if (element.product_family == "Others" && element.status == "1") {
            this.productOnlyOthers.push(element);
          }
        });
        // this.productOnlyOthers = res.data.data;
      })
      .catch((err) => {
        console.log("err", err);
        this.value = false;
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });

    this.getBidders();

    // *************GET Account by id**********
    // if (this.$route.params.account != 0) {
    //   axios
    //     .get(axios.defaults.baseURL + "account/" + this.$route.params.account, {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((res) => {
    //       this.selectAccountDetailsName = res.data.data[0].name;
    //       this.selectAccountDetails = res.data.data[0];
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //       if (err.response.statusText == "Unauthorized") {
    //         this.$router.push("/login").then(() => {
    //           this.$router.go();
    //         });
    //       }
    //     });
    // }

    // // *********GET quotes***********
    // axios
    //   .get(axios.defaults.baseURL + "quotes", {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   })
    //   .then((res) => {
    //     this.quotesDetails = res.data.data;
    //     this.quotesDetails.forEach((i) => {
    //       this.quote_id = i.quote_id;
    //     });
    //     console.log("quote details", this.quotesDetails);
    //   })
    //   .catch((err) => {
    //     console.log("quote err", err);
    //     this.$store.dispatch("checkErrorAndSendToast", [
    //       err.response,
    //       "error",
    //     ]);
    //   });
  },
  methods: {
    updateProduct(data, condition) {
      console.log('updateProduct !! :- ', data);
      const products = condition == 'Accomodation' ? this.productOnlyAcomdation : condition == 'Meals' ? this.productOnlyMeals : condition == 'OtherProduct' ? this.productOnlyOthers : this.productOnlyConferenceRoom
      const product = products.find(
        product => product.product_id === data.product_id
      );
      if (product) {
        data.product = product;
      }
    },
    invetation_Set_RawData(data, index, action){
        this.invitation_send_rawdata.data = data
        this.invitation_send_rawdata.index = index
        this.invitation_send_rawdata.action = action

        axios
        .get(axios.defaults.baseURL + 'get-email-content/2' , {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("res email content--", res);
          this.invitation_send_rawdata.emailContent = res.data.data[0]
          console.log("11-invitation_send_rawdata--", this.invitation_send_rawdata);
        })
        .catch((err) => {
          this.value = false;
          this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
    },
    getName(item) {
        return `${item.name}`
    },
    // filterDropdown(){
    //   const data = this.bidders_clone

    //   data = data.filter((data1) =>{
    //         if(data1.searchQuery){
    //           return data1.venue_name.toLowerCase().includes(data1.searchQuery.toLowerCase())
    //         }else{
    //           return data1
    //         }
    //       }
    //     )
        
        
    //   console.log('com- data-22-', data);
    //   this.bidders = data;

    // },
    // openDropdown(index) {
    //   this.addBidders[index].dropdownOpen = !this.addBidders[index].dropdownOpen;
    // },
    // selectOption(data, account_id) {
    //   const selectedOption = this.filter_bidder.find(data1 => data1.account_id === account_id);
    //   if (selectedOption) {
    //     data.account_id = account_id;
    //     data.dropdownOpen = false;
    //     data.selectedVenue = selectedOption.venue_name; // Add this line
    //   }
    // },
    getAccount_owner() {
      //************** Account owner details
      axios
        .get(axios.defaults.baseURL + "get-account-owners", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.accountOwners = res.data.data;
          console.log("account owners", this.accountOwners);
        })
        .catch((err) => {
          console.log("err account owner", err);
          this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        });
    },
    getContact() {
      // *************GET Contacts**********
      axios
        .get(axios.defaults.baseURL + "contacts", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.contactsDetails.length = 0;
          res.data.data.forEach((element) => {
            if (element.roles != 1) {
              this.contactsDetails.push(element);
            }
          });
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        });
    },
    getOpportunits() {
      // *************GET Opportunity**********
      axios
        .get(axios.defaults.baseURL + "opportunities", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.opportunitiesDetails = res.data.data;
          console.log("opportunitiesDetails", this.opportunitiesDetails);
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        });
    },
    getAccount() {
      axios
        .get(axios.defaults.baseURL + "accounts", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.accountDetails = res.data.data;
          this.customerAccountDetails.length = 0;
          res.data.data.forEach((element) => {
            if (element.type == "Customer") {
              this.customerAccountDetails.push(element);
            }
          });
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        });
    },
    getBidders(data){
      console.log("data get ---",data)
      // *************GET bidders**********
    axios
      .get(axios.defaults.baseURL + "bidders", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.bidders = res.data.data;
        this.bidders_clone = res.data.data;

        if(data != undefined){
          this.addRow('AddBidder', data)
          // this.addBidders.push({
          //   row_id: Date.now().toString(),
          //   index_priority : this.addBidders.length + 1 ,
          //   partner_venue_name: "",
          //   status: "Invitation not sent",
          //   present_to_customer: false,
          //   send_invitation: 1,
          //   resend_invitation: 0,
          //   opportunity_id: "",
          //   quote_id: "",
          //   account_id: data != undefined ? data.account_id : '',
          //   preliminary: 0,
          //   invited: 0,
          //   searchQuery: "",
          //   is_outside_partner: "",
          //   name: "",
          // });
        }

        this.loader_Loading_C.push({
          send: false,
          resend: false,
        });
        this.loader_Loading_T.push({
          send: true,
          resend: true,
        });

      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        this.value = false;
      });
    },
    checkContact() {
      if(this.account_id){
        axios
        .get(axios.defaults.baseURL + "get-contact/" + this.account_id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.contact_details_by_account_id = res.data.data;
          // alert(this.lead_details);
        })
        .catch((err) => {
            console.log("err", err);
            this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
      }
    },
    incrementDate() {
      const date = new Date(this.date_of_conference_start);
      date.setDate(date.getDate() + 1);
      this.date_of_conference_end = date.toISOString().substr(0, 10);
    },
    hideOpenAccordian() {
      if (this.hideAccordian == false) {
        this.hideAccordian = true;
      } else {
        this.hideAccordian = false;
      }
    },
    editNote(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Note", params: { id: id } });
    },
    editTask(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Task", params: { id: id } });
    },
    // invetationSend_outside_partner(data, index, text){
    //   console.log('data',data);
    //   console.log('text',text);
    //   // return
    //   this.v$.opportunity_id.$touch();
    //   if (!this.v$.opportunity_id.$invalid) {
    //     if(data.name && data.email){
    //       this.loader_Loading_C_2[index].send = true;
    //       this.loader_Loading_T_2[index].send = false;
    //       var formData = new FormData();
    //       formData.append("name", data.name);
    //       formData.append("email", data.email);
    //       formData.append("customer_account_id", this.account_id);
    //       axios
    //         .post(axios.defaults.baseURL + "invite-new-partner/" + this.state.opportunity_id , formData, {
    //           headers: {
    //             Authorization: "Bearer " + localStorage.getItem("token"),
    //           },
    //         })
    //         .then((res) => {
    //           console.log("res", res);
    //         }).catch((err) => {
    //           console.log("err", err);
    //         })
    //     }else{
    //       this.AddToast(
    //       "Please fill all the fields",
    //       "Validation",
    //       "info_1"
    //     );
    //     return;
    //     }
    //   }else {
    //     this.AddToast(
    //       "First save this opportunity then after send invitation",
    //       "First Save opportunity",
    //       "info_1"
    //     );
    //     console.log("return hai");
    //     return;
    //   }

    // },
    invetationSend(isEmail) {
      console.log('isEmail inside *- ',isEmail);
      // return
      var data = this.invitation_send_rawdata.data
      var index = this.invitation_send_rawdata.index
      var text = this.invitation_send_rawdata.action

      //  return
      this.v$.opportunity_id.$touch();
      if (!this.v$.opportunity_id.$invalid) {
        var API
        if (text == "Send") {
          this.bidders.every((element) => {
            if (element.account_id == data.account_id) {
              data.partner_venue_name = element;
              if(element.is_outside_partner == 1 || element.is_outside_partner == '1'){
                API = 'invite-outside-partner'
              }else{
                API ='send-invitation';
              }
              return false;
            } else {
              return true;
            }
          });
          if(!data.partner_venue_name.email){
            this.AddToast(this.lan.Please_add_Email_id_for_this_venue_before_send_invitaion, "Information", "info_1");
            return
          }
          this.loader_Loading_C[index].send = true;
          this.loader_Loading_T[index].send = false;
          document.getElementById('exampleModal-676_closeX').click()
          // this.loader_send_invitation = true;
          

          var formData = new FormData();

          formData.append("opportunity_id", this.state.opportunity_id);
          formData.append("account_id", data.account_id);
          formData.append("customer_account_id", this.account_id);
          formData.append("contact_id", this.account_id);
          formData.append("email", data.partner_venue_name.email);
          formData.append("status", "New");
          if(isEmail == undefined){
            formData.append("is_template_changed", 0);
          }else{
            formData.append("is_template_changed",isEmail[0] == 1  ? 1 : 0);
            formData.append("email_body_content", isEmail[1]);
            formData.append("bccEmail", isEmail[2]);
            formData.append("subject", isEmail[3]);
          }
          // return

          axios
            .post(axios.defaults.baseURL + API, formData, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("res", res);

              // ************************
              var formData = new FormData();
              formData.append(
                "partner_venue_name",
                JSON.stringify(data.partner_venue_name)
              );
              formData.append("index_priority", data.index_priority);
              formData.append("status", "Invitation Sent");
              formData.append("present_to_customer", data.present_to_customer);
              formData.append("send_invitation", 0);
              formData.append("resend_invitation", 1);
              formData.append("quote_id", res.data.data.quote_id);
              formData.append("account_id", data.partner_venue_name.account_id);
              formData.append("opportunity_id", this.state.opportunity_id);
              formData.append("preliminary", 0);
              formData.append("invited", data.invited);
              axios
                .post(axios.defaults.baseURL + "bidders", formData, {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                })
                .then((ress) => {
                  // this.loader_send_invitation = false;
                  this.loader_Loading_C[index].send = false;
                  this.loader_Loading_T[index].send = true;
                  this.addBidders[index].status = "Invitation Sent";
                  this.addBidders[index].send_invitation = 0;
                  this.addBidders[index].resend_invitation = 1;
                  console.log("ress", ress);
                  this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
                })
                .catch((err) => {
                  console.log("err", err);
                  // this.loader_send_invitation = false;
                  this.loader_Loading_C[index].send = false;
                  this.loader_Loading_T[index].send = true;
                  this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
                });
              // **************************
            })
            .catch((err) => {
              console.log("err", err);
              // this.loader_send_invitation = false;
              this.loader_Loading_C[index].send = false;
              this.loader_Loading_T[index].send = true;
              this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
            });
        } else {
          console.log("reach");
          // this.loader_Loading_C[index].resend = true;
          // this.loader_Loading_T[index].resend = false;
          this.AddToast(
            this.lan.Resend_will_be_work_from_Edit_opportunity_page,
            this.lan.Go_to_Edit_opportunit_page,
          "info_1"
          );
        }
      } else {
        this.AddToast(
          this.lan.First_save_this_opportunity_then_after_send_invitation,
          this.lan.First_Save_opportunity,
          "info_1"
        );
        console.log("return hai");
        return;
      }
    },

    save() {
      if (
        this.vat &&
        this.contact_id &&
        this.arrival_time &&
        this.departure_time
      ) {
        // if (!this.state.opportunity_id) {
        var opportunity = this.state.opportunity_id
          ? `edit-opportunity/${this.state.opportunity_id}`
          : "opportunities";
        this.loadingSaveOppLoader = true;
        this.loadingSaveOppText = false;

        this.contact_details_by_account_id.forEach((element) => {
          if (element.id == this.contact_id) {
            this.email = element.email;
          }
        });

        var formData = new FormData();

        // formData.append("start_datetime", "2023-03-11 17:00:00");
        formData.append(
          "date",
          this.date_of_conference_start + " to " + this.date_of_conference_end
        );
        formData.append("arrival_time", this.arrival_time);
        // formData.append("end_datetime", "2023-03-11 17:00:00");
        formData.append("departure_time", this.departure_time);
        formData.append("type_of_conference", this.type_of_conference);
        formData.append("email", this.email);
        formData.append("opportunity_owner", this.user.id);
        formData.append("opportunity_comments", this.opportunity_comments);

        // these data send Only for edit-opportunity
        if (this.state.opportunity_id) {
          formData.append("comments", this.free_text_message);
          formData.append("seen", 0);
          formData.append("shared_double_room", "");
          formData.append("description", "");
          formData.append("opened", "");
        }
        // **********************
        formData.append("number_of_participants", this.number_of_participants);
        formData.append("opportunity_name", "");
        formData.append(
          "no_of_participants_in_conf",
          this.no_of_participants_in_conf
        );
        formData.append("account_id", this.account_id);
        formData.append("contact_id", this.contact_id);
        formData.append("lead_id", "");
        formData.append("self", 1);
        formData.append("status", "New");
        formData.append("lost_reason", "");
        formData.append("free_text_message", this.free_text_message);
        formData.append("accomodations", this.accomodations);
        formData.append(
          "no_of_single_rooms",
          this.number_of_participants_in_single_room
        );
        formData.append(
          "no_of_people_in_shared_double_rooms",
          this.no_of_people_in_shared_double_rooms
        );
        formData.append("vat", this.vat);
        formData.append(
          "common_questions",
          JSON.stringify(this.addCommonQuestion)
        );
        if (this.additional_questions != []) {
          formData.append(
            "additional_questions",
            JSON.stringify(this.additional_questions)
          );
        } else {
          formData.append("additional_questions", this.additional_questions);
        }
        // formData.append("note", this.note);
        // formData.append("task", this.task);

        if (this.note.length) {
          formData.append("note", JSON.stringify(this.note));
        } else {
          formData.append("note", this.note);
        }
        if (this.task.length) {
          formData.append("task", JSON.stringify(this.task));
        } else {
          formData.append("task", this.task);
        }
        var valid_conference = []
        var valid_accomodation = []
        var valid_meals = []
        var valid_otherproduct = []
        this.addAcomdation.forEach(element => {
          console.log('addAcomdation--1---',element);
          if(element.product != ''){
            valid_accomodation.push(element)
          }
        });
        this.addMeals.forEach(element => {
          console.log('addMeals--1---',element);
          if(element.product != ''){
            valid_meals.push(element)
          }
        });
        this.addOtherProduct.forEach(element => {
          console.log('addOtherProduct--1---',element);
          if(element.product != ''){
            valid_otherproduct.push(element)
          }
        });
        this.addConferenceRoom.forEach(element => {
          console.log('addConferenceRoom--1---',element);
          if(element.product != ''){
            valid_conference.push(element)
          }
        });
        
        console.log('this.addConferenceRoom-',this.addConferenceRoom);
        console.log('this.addAcomdation-',this.addAcomdation);
        console.log('this.addMeals-',this.addMeals);

        formData.append("accomodation", JSON.stringify(valid_accomodation));
        formData.append("meals", JSON.stringify(valid_meals));
        formData.append(
          "conference_room",
          JSON.stringify(valid_conference)
        );
        formData.append("other_products", JSON.stringify(valid_otherproduct));
        formData.append("bidders", JSON.stringify(this.addBidders));
        // return
        axios
          .post(axios.defaults.baseURL + opportunity, formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("res", res);
            (this.loadingSaveOppLoader = false),
              (this.loadingSaveOppText = true);
            if (this.state.opportunity_id) {
              this.AddToast(
                this.lan.Opporttunity_Update_Successfully,
                " Success ",
                "success_1"
              );
            } else {
              this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
            }
            this.state.opportunity_id = this.state.opportunity_id
              ? this.state.opportunity_id
              : res.data.data.opportunity_id;
            localStorage.removeItem("Unsaved_Self_OppoData");
          })
          .catch((err) => {
            (this.loadingSaveOppLoader = false),
              (this.loadingSaveOppText = true),
              this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
            console.log("err", err);
          });
        // } else {
        //   this.AddToast(
        //     "You can't save again to same opportunity please come from edit page option then you can save many times",
        //     " Warning ",
        //     "warning_1"
        //   );
        //   return;
        // }
      } else {
        this.AddToast(
          this.lan.Please_select_Vat_Customer_Customer_sContact_Arrival_and_Departure_Time_It_required,
          this.lan.Validation,
          "info_1"
        );
      }
    },
    saveNewNoteHandle(data) {
      this.note.push(data.note_id);
      this.note_detail.push({
        note_id: data.note_id,
        subject: data.subject,
        comment: data.comment,
      });
    },
    saveNewTaskHandle(data) {
      this.task.push(data.task_id);
      this.task_detail.push({
        task_id: data.task_id,
        subject: data.subject,
        comment: data.comment,
      });
    },
    // inviteToCustomer() {
    //   this.loadingSendCustomerText = false;
    //   this.loadingSendCustomerLoader = true;
    //   this.contact_details_by_account_id.forEach((element) => {
    //       if (element.id == this.contact_id) {
    //         this.email = element.email;
    //       }
    //     });
    //   var formData = new FormData();
    //   formData.append("email", this.email);

    //   axios
    //     .post(
    //       axios.defaults.baseURL +
    //         "invite-customer/" +
    //         this.state.opportunity_id,
    //       formData,
    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("token"),
    //         },
    //       }
    //     )
    //     .then((res) => {
    //       // this.opportunitiesDetails = res.data.data;
    //       console.log("res invite customer-", res);
    //       this.loadingSendCustomerText = true;
    //       this.loadingSendCustomerLoader = false;
    //       this.AddToast(res.data.message, " Success ", "success_1");
    //     })
    //     .catch((err) => {
    //       this.loadingSendCustomerText = true;
    //       this.loadingSendCustomerLoader = false;
    //       console.log("err", err);
    //       // if (err.response.statusText == "Unauthorized") {
    //       //   this.$router.push("/login").then(() => {
    //       //     this.$router.go();
    //       //   });
    //       // }
    //     });
    // },
    addRow(check, data) {
      if (check == "Accomodation") {
        this.addAcomdation.push({
          row_id: Date.now().toString(),
          product_id: "",
          product: "",
          date: this.date_of_conference_start,
          room: this.number_of_participants,
          room_we_offer: "",
          price_for_this_quote: "",
          ordinary_price: "",
          no_of_people_came: "",
        });
      } else if (check == "Meals") {
        this.addMeals.push({
          row_id: Date.now().toString(),
          product_id: "",
          product: "",
          date: this.date_of_conference_start,
          room: this.number_of_participants,
          room_we_offer: "",
          price_for_this_quote: "",
          ordinary_price: "",
          no_of_people_came: "",
        });
      } else if (check == "con Product") {
        this.addConferenceRoom.push({
          row_id: Date.now().toString(),
          product_id: "",
          product: "",
          comment: "",
          date: this.date_of_conference_start,
          request_size: this.no_of_participants_in_conf,
          seating: "",
          request_size_we_can_offer: "",
          seating_we_can_offer: "",
          fee_confrence_room: "",
          ordinary_price: "",
          no_of_people_came: "",
        });
      } else if (check == "OtherProduct") {
        this.addOtherProduct.push({
          row_id: Date.now().toString(),
          product_id: "",
          product: "",
          comment: "",
          room: this.number_of_participants,
          date: this.date_of_conference_start,
          room_we_offer: "",
          price_for_this_quote: "",
          ordinary_price: "",
          no_of_people_came: "",
        });
      } else if (check == "AddBidder") {
        console.log('data',data);
        this.addBidders.push({
          row_id: Date.now().toString(),
          index_priority : this.addBidders.length + 1 ,
          partner_venue_name: "",
          status: "Invitation not sent",
          present_to_customer: false,
          send_invitation: 1,
          resend_invitation: 0,
          opportunity_id: "",
          quote_id: "",
          account_id: data != undefined ? data.account_id : '',
          preliminary: 0,
          invited: 0,
          searchQuery: "",
          is_outside_partner: "",
          name: "",
          // partnerDetails: "",
          // status: "Invitation not sent",
          // sendInvitation: 1,
          // resendInvitation: 0,
          // quote: "",
          // presentCustomer: 0,
        });

        this.loader_Loading_C.push({
          send: false,
          resend: false,
        });
        this.loader_Loading_T.push({
          send: true,
          resend: true,
        });
      }
      // else if(check == 'AddBidderOutside'){
      //   console.log('outside')
      //   this.addBidders_Outside.push({
      //     partner_venue_name: "",
      //     status: "Invitation not sent",
      //     present_to_customer: false,
      //     send_invitation: 1,
      //     resend_invitation: 0,
      //     opportunity_id: "",
      //     quote_id: "",
      //     account_id: "",
      //     preliminary: 0,
      //     invited: "",
      //     searchQuery: "",
      //     is_outside_partner: "",
      //     name: "",
      //     email: "",
      //   });
      //   this.loader_Loading_C_2.push({
      //     send: false,
      //     resend: false,
      //   });
      //   this.loader_Loading_T_2.push({
      //     send: true,
      //     resend: true,
      //   });
      // }
    },
    addDropdown(indexx) {
      this.additional_questions.forEach((drop, index) => {
        if (index == indexx) {
          if(this.dropdown_option != ""){
            drop.dropdown.push({ name: this.dropdown_option});
            this.dropdown_option = "";
          }
        }
      });
    },
    deleteRow(index, check) {
      if (check == "Accomodation") {
        this.addAcomdation.splice(index, 1);
      } else if (check == "Product") {
        this.addConferenceRoom.splice(index, 1);
      } else if (check == "OtherProduct") {
        this.addOtherProduct.splice(index, 1);
      } else if (check == "Bidder") {
        this.addBidders.splice(index, 1);
      } else if (check == "Meals") {
        this.addMeals.splice(index, 1);
      } else if (check == "Bidder_Outside") {
        this.addBidders_Outside.splice(index, 1);
      }
    },
    addQuestion() {
      if (this.add_question.type == "dropdown") {
        this.additional_questions.push({
          row_id: Date.now().toString(),
          inputType: this.add_question.type,
          question: this.add_question.question,
          answer: '',
          dropdown: [],
          answertags: [],
          answerMultiple: [],
          maxSelect: 1,
          isShowOrNot: true,
          extra2: '',
        });
        this.add_question.question = "";
      } else if(this.add_question.type == "text" || this.add_question.type == "radio") {
        this.additional_questions.push({
          row_id: Date.now().toString(),
          inputType: this.add_question.type,
          question: this.add_question.question,
          answer: "",
          isShowOrNot: true,
        });
        this.add_question.question = "";
      }
    },
    deleteQuestion(index) {
      this.additional_questions.splice(index, 1);
    },
    selectDropdown(q_index){
      console.log('this.additional_questions',this.additional_questions)
      this.additional_questions.forEach((element, index) => {
        if(index == q_index){
          if(element.maxSelect == element.answertags.length){
            element.answer = ""
            return false;
          }
          this.additional_questions[q_index].dropdown.forEach((drop, index2) => {
            if(element.answer == index2){
              element.answertags.push(drop)
              element.dropdown.splice(element.answer ,1)
              element.answer = ""
            }
          })
        }
      });
      console.log('this.additional_questions 2--',this.additional_questions)
    },
    selectDropdownDelete(item, q_index){
      console.log('item',item,q_index)
      this.additional_questions.forEach((element, index) => {
        if(index == q_index){
          this.additional_questions[q_index].answertags.forEach((drop, index2) => {
            if(item.name == drop.name){
              element.dropdown.push(drop)
              element.answertags.splice(index2 ,1)
              element.answer = ""
            }
          })
        }
      });
    },
    optionsDropdownDelete(item, q_index){
      console.log('item',item,q_index)
      this.additional_questions.forEach((element, index) => {
        if(index == q_index){
          this.additional_questions[q_index].dropdown.forEach((drop, index2) => {
            if(item.name == drop.name){
              element.dropdown.splice(index2 ,1)
              element.answer = ""
            }
          })
          this.additional_questions[q_index].answertags.forEach((drop, index2) => {
            if(item.name == drop.name){
              element.answertags.splice(index2 ,1)
              element.answer = ""
            }
          })
        }
      });
    },
    sendPreview() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$router.push({
          name: "Quote Presentation Preview",
          params: {
            id: this.state.opportunity_id,
            target: "_blanck",
          },
        });
      } else {
        this.AddToast(
          this.lan.First_save_this_opportunity_then_after_send_invitation,
          this.lan.First_Save_opportunity,
          "info_1"
        );
        return;
      }
    },
    clearRawData() {
      localStorage.removeItem("Unsaved_Self_OppoData");
      (this.departure_time = ""),
        (this.arrival_time = ""),
        (this.type_of_conference = ""),
        (this.conference_room_setup = ""),
        (this.number_of_participants = ""),
        (this.number_of_participants_in_single_room = ""),
        (this.no_of_people_in_shared_double_rooms = ""),
        (this.accomodations = ""),
        (this.no_of_participants_in_conf = ""),
        (this.created_at = ""),
        (this.free_text_message = ""),
        (this.date_of_conference_start = ""),
        (this.date_of_conference_end = ""),
        (this.opportunity_comments = ""),
        (this.vat = ""),
        (this.account_id = ""),
        (this.contact_id = ""),
        (this.product_id = ""),
        (this.note = []),
        (this.task = []),
        (this.addAcomdation = []),
        (this.addMeals = []),
        (this.addConferenceRoom = []),
        (this.addOtherProduct = []),
        // (this.addBidders = []),
        (this.additional_questions = []);
    },
    dragStart(index) {
      // Set the data being dragged
      event.dataTransfer.setData("text/plain", index);
    },
    dragOver(event) {
      // Prevent default behavior to allow dropping
      event.preventDefault();
    },
    drop(index, text) {
      // Get the index of the dragged item
      const draggedIndex = event.dataTransfer.getData("text/plain");
      var draggedItem;
      if (text == "accomodation") {
        draggedItem = this.addAcomdation[draggedIndex];
        this.addAcomdation.splice(draggedIndex, 1);
        this.addAcomdation.splice(index, 0, draggedItem);
        // Rearrange the data array
      } else if (text == "meals") {
        draggedItem = this.addMeals[draggedIndex];
        this.addMeals.splice(draggedIndex, 1);
        this.addMeals.splice(index, 0, draggedItem);
      } else if (text == "conference") {
        draggedItem = this.addConferenceRoom[draggedIndex];
        this.addConferenceRoom.splice(draggedIndex, 1);
        this.addConferenceRoom.splice(index, 0, draggedItem);
      } else if (text == "other") {
        draggedItem = this.addOtherProduct[draggedIndex];
        this.addOtherProduct.splice(draggedIndex, 1);
        this.addOtherProduct.splice(index, 0, draggedItem);
      } else if (text == "question") {
        draggedItem = this.additional_questions[draggedIndex];
        this.additional_questions.splice(draggedIndex, 1);
        this.additional_questions.splice(index, 0, draggedItem);
      }
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },

    edit(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Quote" });
    },
  },
};
</script>
<style scoped>
.topCaret {
  font-size: 20px;
}
.mainAccordian {
  border: 1px solid #ccc;
}
.question_to_partner .drag{
  border-left: 1px solid rgb(222, 226, 230);
  border-bottom: 1px solid rgb(222, 226, 230);
}
/* .searchable-select {
  position: relative;
  display: inline-block;
}

.search-input {
  border: 1px solid #ccc;
  padding: 5px;
  width: 200px;
  cursor: pointer;
}

.select-01 {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  display: none;
  border: 1px solid #ccc;
  background-color: #fff;
  max-height: 150px;
  overflow-y: auto;
}
.dropdown-container {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .dropdown-input {
    padding: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    width: 100%;
  }

  .dropdown-list {
    position: absolute;
    top: 100%;
    left: 0;
    max-height: 250px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
    z-index: 50;
  }

  .dropdown-item {
    padding: 5px;
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
  .disabled-option {
  pointer-events: none;
  background-color: #cfcfcfa1;
} */

@media screen and (max-width: 576px) {
  .add-btn{
    padding: 6px 16px;
  }
  .c-btn{
    padding: 10px;
    height: auto;
  }
  .lower-btns-div{
    display: block !important;
  }
}

a {
  text-decoration: none;
  color: #000000;
}

a:hover {
  color: #222222
}

/* Dropdown */

.dropdown {
  display: inline-block;
  position: relative;
}

.dropdown .select-Btn {
  display: inline-block;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 10px 45px 10px 30px;
  height: 43px;
  background-color: #ffffff;
  cursor: pointer;
  white-space: nowrap;
}

.dropdown .select-Btn:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
}

</style>