<template>
  <div id="myModal" class="modal fade" style="pointer-events: none;">
    <div class="modal-dialog modal-confirm_1">
      <div class="modal-content">
        <!-- <div class="modal-header flex-column">
          <div class="icon-box">
            <i class="material-icons">&#xE5CD;</i>
          </div>
          <h4 class="modal-title w-100">Are you sure?</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div> -->
        <div class="modal-body">
          <p>
            {{ lan.admin[4].Opportunity_page[0].Thank_you_for_response_this_quote }}
          </p>
          <p>
            {{ lan.admin[4].Opportunity_page[0].To_see_the_details_of_this_quote_and_your_response }}
          </p>
        </div>
        <div class="modal-footer justify-content-center">
          <button id="closemoda" type="button" @click="close()" class="btn btn-secondary c-bbtn" data-dismiss="modal">
            {{ lan.admin[4].Opportunity_page[0].Close_to_page }}
          </button>
          <button id="closemodal" type="button" class="btn btn-secondary c-bbtn" data-dismiss="modal" style="display: none">
            {{ lan.admin[4].Opportunity_page[0].Close_to_page }}
          </button>
          <button type="button" class="btn btn-danger dan-btn"  @click="deletef">{{ lan.admin[4].Opportunity_page[0].Click_to_login_portal }}</button>
          <a href="http://localhost:8080/email-editquote&id=MTAy" target="_blank"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmModal",
  props: ["id"],
  data() {
    return {};
  },
  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
  },
  methods: {
    close() {
      this.$router.go(-1)
      close();
    },
    deletef() {
      document.getElementById('closemodal').click();
      this.$router.push("/login");
    },
  },
};
</script>
<style >
.modal-confirm_1 {
  color: #636363;
  width: 400px;
}
.modal-confirm_1 .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm_1 .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm_1 h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}
.modal-confirm_1 .close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.modal-confirm_1 .modal-body {
  color: #999;
}
.modal-confirm_1 .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}
.modal-confirm_1 .modal-footer a {
  color: #999;
}
.modal-confirm_1 .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}
.modal-confirm_1 .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm_1 .btn,
.modal-confirm_1 .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 160px;
  font-size: 12px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}
.modal-confirm_1 .c-bbtn {
  background: #c1c1c1;
  height: 70px;
}
.modal-confirm_1 .c-bbtn:hover,
.modal-confirm_1 .c-bbtn:focus {
  background: #a8a8a8;
}
.modal-confirm_1 .dan-btn {
  background: #f15e5e;
  height: 70px;
}
.modal-confirm_1 .dan-btn:hover,
.modal-confirm_1 .dan-btn:focus {
  background: #ee3535;
}
</style>
