<template>
  <div>
    <!--**********************************
  Main wrapper start
  ***********************************-->
    <div id="main-wrapper">
      <!--**********************************
      Content body start
  ***********************************-->
      <div class="content-body pt-0">
        <div class="container-fluid">
          <div
            class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
          >
            <h2 class="font-w600 title mb-2 mr-auto">
              {{ lan.admin[2].Leads_page[0].Create_new_opportunities }}
            </h2>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div
                  class="card-header pb-2 d-block d-sm-flex flex-wrap border-0"
                >
                  <div class="mb-3">
                    <h4 class="fs-20 text-black">
                      {{
                        lan.admin[2].Leads_page[0].Convert_lead_to_opportunity
                      }}
                    </h4>
                  </div>
                </div>
                <div class="card-body tab-content p-3">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <tbody>
                          <tr>
                            <td>{{ lan.admin[0].admin_portal[0].Account }}</td>
                            <td>
                              <select
                                class="select"
                                @change="getContact()"
                                v-model="state.related_account"
                              >
                                <option value="" disabled selected>
                                  Please select one
                                </option>
                                <option
                                  v-for="(data, index) in accountDetails"
                                  :key="index"
                                  :value="data.account_id"
                                >
                                  {{ data.name }}
                                </option></select
                              ><br />
                              <span>{{
                                v$.related_account.$error
                                  ? " Account is required"
                                  : ""
                              }}</span>
                            </td>
                            <td>
                              <button
                                class="filter-btn mb-0"
                                data-toggle="modal"
                                data-target="#AddAccountModel"
                              >
                                {{
                                  lan.admin[12].Accounts[0].Create_new_account
                                }}
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>{{ lan.admin[0].admin_portal[0].Contacts }}</td>
                            <td>
                              <select
                                class="select"
                                name=""
                                id=""
                                v-model="state.related_contact"
                              >
                                <option value="" disabled selected>
                                  Please select one
                                </option>
                                <option
                                  v-for="(data, index) in contactsDetails"
                                  :key="index"
                                  :value="data.id"
                                >
                                  {{ data.first_name }} {{ data.last_name }}
                                </option></select
                              ><br />
                              <span>{{
                                v$.related_contact.$error
                                  ? " Contact is required"
                                  : ""
                              }}</span>
                            </td>
                            <td>
                              <button
                                class="filter-btn mb-0"
                                style="width: 200px; height: 43px"
                                data-toggle="modal"
                                data-target="#exampleModal22"
                              >
                                {{
                                  lan.admin[14].Contact[0].Create_new_contact
                                }}
                              </button>
                              <!-- <router-link to="/add-contact"
                                ><button class="filter-btn mb-0">
                                  Create new Contact
                                </button></router-link
                              > -->
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div class="d-flex justify-content-end mt-3">
                        <div class="button">
                          <button class="search-btn" @click="create">
                            <div class="lds-ring" v-show="loading">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-show="loadingS">{{
                              lan.admin[2].Leads_page[0].convert
                            }}</span>
                          </button>
                          <router-link to="/leads"
                            ><button class="filter-btn">
                              {{ lan.admin[2].Leads_page[0].Cancel }}
                            </button></router-link
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddConatact
        @event="getContact"
        @getContact="getContact"
        :accountDetails="accountDetails"
        setRole="Customer"
        check_from="SelfOppo"
      />
      <AddAccount
        @getAccount="getAccount"
        :accountOwners="accountOwners"
        :contactsDetails="contactsDetails"
        account_type="Customer"
        check_from="SelfOppo"
      />
      <!--**********************************
      Content body end
  ***********************************-->
    </div>
    <!--**********************************
  Main wrapper end
  ***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import AddConatact from "../../../components/addContact";
import AddAccount from "../../../components/addAccount";
// @ is an alias to /src

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed } from "vue";

export default {
  name: "Leads",
  components: { AddConatact, AddAccount },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      related_account: "",
      related_contact: "",
    });

    const rules = computed(() => {
      return {
        related_account: { required },
        related_contact: { required },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      rawaccountDetails: "",
      accountDetails: [],
      contactsDetails: "",
      accountOwners: "",
      value: true,
      loadingS: true,
      loading: false,
    };
  },

  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
  },
  mounted() {
    // *************GET Accounts**********
    this.getAccount();
    this.getAccount_owner();

    // *************GET Contacts**********
    // axios
    //   .get(axios.defaults.baseURL + "contacts", {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   })
    //   .then((res) => {
    //     this.contactsDetails = res.data.data;
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //   });
  },
  methods: {
    getAccount_owner() {
      //************** Account owner details
      axios
        .get(axios.defaults.baseURL + "get-account-owners", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.accountOwners = res.data.data;
          console.log("account owners", this.accountOwners);
        })
        .catch((err) => {
          console.log("err account owner", err);
          this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        });
    },
    getAccount(data) {
      axios
        .get(axios.defaults.baseURL + "accounts", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("res", res);
          this.rawaccountDetails = res.data.data;
          this.accountDetails.length = 0;
          res.data.data.forEach((element) => {
            if (element.type == "Customer") {
              this.accountDetails.push(element);
            }
          });
          console.log("accountDetails", this.accountDetails);
        })
        .catch((err) => {
          console.log("err", err);
          
          this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        });
    },
    getContact(data) {
      if (data != undefined) {
        console.log("data 4", data);
        this.state.related_contact = data.id;
      }
      axios
        .get(
          axios.defaults.baseURL + "get-contact/" + this.state.related_account,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.contactsDetails = res.data.data;
          // alert(this.lead_details);
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        });
    },
    create() {
      this.v$.related_account.$touch();
      this.v$.related_contact.$touch();
      if (
        !this.v$.related_account.$invalid &&
        !this.v$.related_contact.$invalid
      ) {
        (this.loading = true), (this.loadingS = false);
        var formData = new FormData();
        formData.append("status", 1);
        axios
          .post(
            axios.defaults.baseURL + "convert-lead/" + this.$route.params.id,
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log("res", res);
            (this.loading = false),
              (this.loadingS = true),
              this.$router.push({
                name: "Add Opportunity",
                params: {
                  id: this.$route.params.id,
                  account: this.state.related_account,
                  contact: this.state.related_contact,
                },
              });
            this.AddToast(res.data.message, " Success ", "success_1");
          })
          .catch((err) => {
            (this.loading = false), (this.loadingS = true);
            // if (err.response.statusText == "Unauthorized") {
            //   this.$router.push("/login").then(() => {
            //     this.$router.go();
            //   });
            // }
            console.log("err", err);
            this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
          });
      } else {
        this.AddToast(
          this.lan.Please_select_Account_and_Contact_required,
          "Validation",
          "info_1"
        );
        return;
      }
    },

    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>
<style></style>
