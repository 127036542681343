<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="exampleModal22"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div>
            <div class="input">
              <label
                >{{ lan.admin[14].Contact[0].First_name}}<span>*</span
                ><span style="color: red">{{
                  c$.first_name.$error ? " is required" : ""
                }}</span></label
              ><br />
              <input
                type="text"
                name="text"
                v-model="state.first_name"
                :placeholder="lan.admin[15].Add_contact_page[0].First_name"
                :class="c$.first_name.$error ? 'input-error' : ''"
              />

              <label class="mt-3"
                >{{ lan.admin[14].Contact[0].Last_name}}<span>*</span>
                <span style="color: red">{{
                  c$.last_name.$error ? " is required" : ""
                }}</span></label
              ><br />
              <input
                type="text"
                name="text"
                v-model="state.last_name"
                :placeholder="lan.admin[15].Add_contact_page[0].Last_name"
                :class="c$.last_name.$error ? 'input-error' : ''"
              />

              <label class="mt-3">{{ lan.admin[14].Contact[0].Phone}}</label><br />
              <input
                type="text"
                name="Telephone"
                v-model="phone"
                :placeholder="lan.admin[15].Add_contact_page[0].Phone"
              />
            </div>

            <div class="input">
              <label
                >{{ lan.admin[14].Contact[0].Email}}<span>*</span
                ><span style="color: red">{{
                  c$.email.$error ? " is required" : ""
                }}</span></label
              ><br />
              <input
                type="email"
                name="email"
                v-model="state.email"
                :placeholder="lan.admin[15].Add_contact_page[0].Email"
                :class="c$.email.$error ? 'input-error' : ''"
              />

              <label class="mt-3"
                >{{ lan.admin[15].Add_contact_page[0].Role}}<span>*</span>
                <span style="color: red">{{
                  c$.roles.$error ? " is required" : ""
                }}</span></label
              ><br />
              <select v-model="state.roles"
                :class="c$.roles.$error ? 'input-error' : ''"
                >
                <!-- :disabled="check_partner != 'Partner' ? true : false" -->
              <option value="" disabled selected>Role Type</option>
              <!-- <option v-if="check_from != 'SelfOppo'" value="1">Admin</option> -->
              <option v-if="check_from != 'SelfOppo'" value="2">Partner</option>
              <option value="3">Customer</option>
              <option v-show="true" value="" disabled style="color: white;">{{ propSetRole }}</option>
              </select>

              <label v-if="check_partner != 'Partner'" class="mt-3">{{ lan.admin[14].Contact[0].Company}}</label
              ><br />
              <model-list-select v-if="check_partner != 'Partner' && accountDetailsFilter" :list="accountDetailsFilter"
                style="height: 43px; border: 1px solid #ccc; border-radius: 2px;"
                v-model="company"
                option-value="account_id"
                :custom-text="getName"
                placeholder="Select Company"
                >
              </model-list-select>
              <!-- <select v-if="check_partner != 'Partner'" v-model="company">
                <option value="" disabled selected>Select Company</option>
                <option
                  :value="data.account_id"
                  v-for="data in accountDetailsFilter"
                  :key="data.id"
                >
                  {{ data.name }}
                </option>
              </select> -->

              <label class="mt-3">{{ lan.admin[15].Add_contact_page[0].Mobile_phone}}</label><br />
              <input
                type="text"
                name="number" 
                v-model="mobile_phone"
                :placeholder="lan.admin[15].Add_contact_page[0].Mobile_phone"
              />
            </div>

            <label class="mt-3">{{lan.admin[15].Add_contact_page[0].Address}}</label>
            <textarea
              name="Address"
              id="Address"
              rows="5"
              v-model="address"
              :placeholder="lan.admin[15].Add_contact_page[0].Address"
            ></textarea>

            <div class="button d-flex justify-content-end mt-4">
              <button @click="submit1" class="search-btn">
                <div class="lds-ring" v-show="loading">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span v-show="loadingS">{{ lan.admin[15].Add_contact_page[0].Save}}</span>
              </button>

              <button class="filter-btn" ref="Close" data-dismiss="modal">
                {{ lan.admin[15].Add_contact_page[0].Cancel}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required, email, integer } from "@vuelidate/validators";
import { reactive, computed } from "vue";

import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from "vue-search-select"

export default {
  name: "Add conatct",
  components: {
    ModelListSelect
  },
  props: {
    accountDetails: {
      type: [String, Object,Array],
    },
    check: {
      type: [String],
    },
    check_partner: {
      type: [String],
    },
    value: {
      type: [String, Number],
    },
    setRole: {
      type: [String, Number],
    },
    check_from: {
      type: [String],
    },
  },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      first_name: "",
      last_name: "",
      email: "",
      roles: "",
    });

    const rules = computed(() => {
      return {
        first_name: { required },
        last_name: { required },
        roles: { required, integer },
        email: { required, email },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const c$ = useVuelidate(rules, state);

    return { state, c$ };
  },
  data() {
    return {
      // accountDetails: "",
      phone: "",
      mobile_phone: "",
      address: "",
      company: "",

      loading: false,
      loadingS: true,
    };
  },
  computed: {
    lan(){
    return this.$store.getters.whichLanguage
    },
    
    accountDetailsFilter() {
      var data = []
      // var self = this;
      console.log('gsdsfsd',this.accountDetails)
      if(this.accountDetails == "" || this.accountDetails == undefined){
        return
      }
      var roles = this.state.roles == '2' ? "Partner"   : this.state.roles == '3' ? "Customer" : 1
      if(this.state.roles != ""){
        this.accountDetails.forEach(element => {
          if(element.type == roles)
          data.push(element);
        });
      }else{
        data = this.accountDetails
      }
      return data
    },
    propSetRole(){
      return this.state.roles = this.setRole == 'Partner'? 2 : this.setRole == 'Customer'? 3 : ''
    }
  },
  methods: {
    getName(item) {
        return `${item.name}`
    },
    submit1() {
      console.log('roles',this.state.roles);
      console.log('check_partner-*-',this.check_partner);
      this.c$.first_name.$touch();
      this.c$.last_name.$touch();
      this.c$.email.$touch();
      this.c$.roles.$touch();

      if (
        !this.c$.first_name.$invalid &&
        !this.c$.last_name.$invalid &&
        !this.c$.email.$invalid && 
        !this.c$.roles.$invalid
      ) {
        (this.loading = true), (this.loadingS = false);

        // alert('chcek')
        // return

        var formData = new FormData();
        formData.append("first_name", this.state.first_name);
        formData.append("last_name", this.state.last_name);
        formData.append("email", this.state.email);
        formData.append("phone", this.phone);
        if(this.check_partner == 'Partner'){
          formData.append("company", this.value);
          formData.append("roles", 2);
        }else if(this.check == 'Edit-account'){
          formData.append("company", this.value);
          formData.append("roles", this.state.roles);
        }
        else{
          formData.append("company", this.company);
          formData.append("roles", this.state.roles);
        }
        formData.append("mobile_phone", this.mobile_phone);
        formData.append("address", this.address);
        formData.append("password", "");
        formData.append("confirm_password", "");

        axios
          .post(axios.defaults.baseURL + "contacts", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            (this.loading = false),
              (this.loadingS = true),
              console.log("res contat com-", res);
            this.$refs.Close.click();
            this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
            this.$emit("event",res.data.data);
            this.$emit("getContact",res.data.data);
            // this.$router.push({ path: "/contacts" });
          })
          .catch((err) => {
            (this.loading = false),
              (this.loadingS = true),
            this.$store.dispatch("checkErrorAndSendToast", [err.response,'error']);
          });
      } else {
        this.AddToast("Please Fill Required Details", " info ", "info_1");
        return;
      }
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>