<template>
  <div>
    <!--**********************************
      Main wrapper start
    ***********************************-->
    <div id="main-wrapper">
      <!--**********************************
          Content body start
      ***********************************-->
      <div class="content-body pt-3 mb-5">
        <div class="container-fluid">
          <div
            class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
          >
            <h2 class="font-w600 title mb-2 mr-auto"> {{ lan.admin[9].Task_page[0].Create_new_task}}</h2>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body tab-content p-3">
                  <div class="tab-pane active show fade" id="monthly">
                    <div>
                      <div class="row">
                        <div class="col-sm-4 input">
                          <label
                            >{{ lan.admin[9].Task_page[0].Subject}}<span>*</span>
                            <span style="color: red">{{
                              v$.subject.$error ? " is required" : ""
                            }}</span></label
                          ><br />
                          <input
                            type="text"
                            name="text"
                            :placeholder="lan.admin[9].Task_page[0].Subject"
                            v-model="state.subject"
                            :class="v$.subject.$error ? 'input-error' : ''"
                          />
                        </div>
                        <div class="col-sm-4 input">
                          <label class=""
                            >{{ lan.admin[9].Task_page[0].Assigned_to}}<span>*</span>
                            <span style="color: red">{{
                              v$.assign_to.$error
                                ? " Assign to is required"
                                : ""
                            }}</span> </label
                          ><br />
                          <select
                            v-model="state.assign_to"
                            :class="v$.assign_to.$error ? 'input-error' : ''"
                          >
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option
                              v-for="data in accountOwners"
                              :value="data.id"
                              :key="data.id"
                            >
                              {{ data.first_name }} {{ data.last_name }}
                            </option>
                          </select>
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Due_date}}</label><br />
                          <input
                            type="date"
                            name="text"
                            v-model="due_date"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Related_contact}}</label><br />
                          <model-list-select
                            v-if="contactsDetails"
                            :list="contactsDetails"
                            style="
                              height: 43px;
                              border: 1px solid #ccc;
                              border-radius: 2px;
                            "
                            v-model="related_contact"
                            option-value="id"
                            :custom-text="getNameContact"
                            placeholder="Please select one"
                          >
                          </model-list-select>

                          <!-- <select v-model="related_contact">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option
                              v-for="(data, index) in contactsDetails"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.first_name }} {{ data.last_name }}
                            </option>
                          </select> -->
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Related_Account}}</label><br />
                          <model-list-select
                            v-if="accountDetails"
                            :list="accountDetails"
                            style="
                              height: 43px;
                              border: 1px solid #ccc;
                              border-radius: 2px;
                            "
                            v-model="related_account"
                            option-value="account_id"
                            :custom-text="getName"
                            placeholder="Please select one"
                          >
                          </model-list-select>
                          <!-- <select v-model="related_account">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option
                              v-for="(data, index) in accountDetails"
                              :key="index"
                              :value="data.account_id"
                            >
                              {{ data.name }}
                            </option>
                          </select> -->
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Related_Opportunity}}</label><br />
                          <model-list-select
                            v-if="opportunitiesDetails"
                            :list="opportunitiesDetails"
                            style="
                              height: 43px;
                              border: 1px solid #ccc;
                              border-radius: 2px;
                            "
                            v-model="related_opportunity"
                            option-value="opportunity_id"
                            :custom-text="getNameOpportunity"
                            placeholder="Please select one"
                          >
                          </model-list-select>
                          <!-- <select v-model="related_opportunity">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option
                              v-for="(data, index) in opportunitiesDetails"
                              :key="index"
                              :value="data.opportunity_id"
                            >
                              {{ data.account_detail.name }}
                            </option>
                          </select> -->
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Priority}}</label><br />
                          <select v-model="priority">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option value="1">High</option>
                            <option value="2">Medium</option>
                            <option value="3">Low</option>
                          </select>
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Status}}</label><br />
                          <select v-model="status">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option value="1">Open</option>
                            <option value="2">Completed</option>
                          </select>
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Reminder}}</label><br />
                          <input
                            type="date"
                            name="text"
                            placeholder="Related Opportunity"
                            v-model="reminder"
                          />
                        </div>
                      </div>

                      <label>{{ lan.admin[9].Task_page[0].Comment}}</label><br />
                      <textarea
                        name=""
                        id=""
                        class="textarea"
                        rows="5"
                        v-model="comment">Comment</textarea>

                      <div class="button d-flex justify-content-end mt-4">
                        <button class="filter-btn up-btn" @click="reset">{{ lan.admin[9].Task_page[0].Reset}}</button>
                        <button class="search-btn up-btn ml-3" v-on:click="submit()">
                          <div class="lds-ring" v-show="loading">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <span v-show="loadingS">{{ lan.admin[9].Task_page[0].Save}}</span>
                        </button>
                        <button v-on:click="route()" class="filter-btn up-btn">
                          {{ lan.admin[9].Task_page[0].Cancel}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--**********************************
          Content body end
      ***********************************-->
    </div>
    <!--**********************************
      Main wrapper end
    ***********************************-->
  </div>
</template>

<script>
import axios from "axios";

import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from "vue-search-select"

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed } from "vue";
// @ is an alias to /src

export default {
  name: "Add Note",
  components: { ModelListSelect },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      subject: "",
      assign_to: "",
    });

    const rules = computed(() => {
      return {
        subject: { required },
        assign_to: { required },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      due_date: "",
      comment: "",
      reminder: "",
      priority: "",
      status: "",
      related_account: "",
      related_contact: "",
      related_opportunity: "",

      accountDetails: "",
      contactsDetails: "",
      opportunitiesDetails: "",
      accountOwners: "",

      loading: false,
      loadingS: true,
    };
  },
  beforeMount(){
  // for top load page
  window.scrollTo(0, 0);
  },
  mounted() {
    // *************GET Accounts**********
    axios
      .get(axios.defaults.baseURL + "accounts", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.accountDetails = res.data.data;
      })
      .catch((err) => {
        console.log("err", err);
        if (err.response.statusText == "Unauthorized") {
          this.$router.push("/login").then(() => {
            this.$router.go();
          });
        }
      });

    // *************GET Contacts**********
    axios
      .get(axios.defaults.baseURL + "contacts", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.contactsDetails = res.data.data;
      })
      .catch((err) => {
        console.log("err", err);
      });

    // *************GET Opportunity**********
    axios
      .get(axios.defaults.baseURL + "opportunities", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.opportunitiesDetails = res.data.data;
        console.log("opportunitiesDetails", this.opportunitiesDetails);
      })
      .catch((err) => {
        console.log("err", err);
        if (err.response.statusText == "Unauthorized") {
          this.$router.push("/login").then(() => {
            this.$router.go();
          });
        }
      });
    //************** Account owner details
    axios
      .get(axios.defaults.baseURL + "get-account-owners", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.accountOwners = res.data.data;
        console.log("account owners", this.accountOwners);
      })
      .catch((err) => {
        console.log("err account owner", err);
      });
  },
  computed: {
    lan(){
      return this.$store.getters.whichLanguage
    },
  },
  methods: {
    getName(item) {
      return `${item.name}`;
    },
    getNameOpportunity(item) {
      return `${item.account_detail.name}`;
    },
    getNameContact(item) {
      return `${item.first_name} ${item.last_name}`;
    },
    reset() {
      this.related_account = "";
      this.related_contact = "";
      this.related_opportunity = "";
    },
    submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        (this.loading = true), (this.loadingS = false);
        var formData = new FormData();
        formData.append("subject", this.state.subject);
        formData.append("assign_to", this.state.assign_to);
        formData.append("due_date", this.due_date);
        formData.append("comment", this.comment);
        formData.append("reminder", this.reminder);
        formData.append("priority", this.priority);
        formData.append("status", this.status);
        formData.append("related_account", this.related_account);
        formData.append("related_contact", this.related_contact);
        formData.append("related_opportunity", this.related_opportunity);
        axios
          .post(axios.defaults.baseURL + "tasks", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("res", res);
            (this.loading = false),
              (this.loadingS = true),
              this.$router.push({ path: "/tasks" });
              this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
          })
          .catch((err) => {
            (this.loading = false),
              (this.loadingS = true),
              console.log("err", err);
              this.$store.dispatch("checkErrorAndSendToast", [
                err.response,
                "error",
              ]);
          });
      } else {
        console.log("else", this.v$);
        this.AddToast("Please Fill Required Details", " info ", "info_1");
        return;
      }
    },

    route() {
      this.$router.push("/tasks");
    },

    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>
