<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="AddAccount_Outside_Model"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel2"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div>
            <div class="input">
              <label
                >{{ lan.admin[13].Account_page[0].Account_Type }}<span>*</span
                ><span style="color: red">{{
                  v$.type.$error ? " is required" : ""
                }}</span></label
              ><br />
              <select
                @change="checkCustomer()"
                v-model="state.type"
                :class="v$.type.$error ? 'input-error' : ''"
              >
                <option value="" disabled selected>Please select one</option>
                <option v-if="check_from == 'SelfOppo'" value="Partner">
                  Partner
                </option>
                <option v-if="check_from != 'SelfOppo'" value="Customer">
                  Customer
                </option>
                <!-- <option value="Admin">Admin</option> -->
              </select>
            </div>

            <div class="input">
              <label
                >{{ lan.admin[13].Account_page[0].Company_name }}<span>*</span>
                <span style="color: red">{{
                  v$.company_name.$error ? " is required" : ""
                }}</span></label
              ><br />
              <input
                type="text"
                name="text"
                v-model="state.company_name"
                :placeholder="lan.admin[13].Account_page[0].Company_name"
                :class="v$.company_name.$error ? 'input-error' : ''"
              />
            </div>

            <!-- <div v-if="state.type != 'Customer'" class="input">
              <label>{{ lan.admin[13].Account_page[0].Venue_name }}</label
              ><br />
              <input
                type="text"
                name="text"
                v-model="venue_name"
                placeholder="Venue name"
              />
            </div>

            <div class="input">
              <label
                >{{ lan.admin[13].Account_page[0].Address }}
                <span style="color: red">{{
                  v$.address.$error ? " is required" : ""
                }}</span></label
              ><br />
              <input
                type="text"
                name="Address"
                v-model="state.address"
                placeholder="Address"
                :class="v$.address.$error ? 'input-error' : ''"
              />
            </div>
            <div class="input">
              <label
                >{{ lan.admin[13].Account_page[0].Phone_number }}
                <span style="color: red">{{
                  v$.phone.$error ? " is required" : ""
                }}</span></label
              ><br />
              <input
                type="text"
                name="Phone number"
                v-model="state.phone"
                placeholder="Phone number"
                :class="v$.phone.$error ? 'input-error' : ''"
              />
            </div>
            <div v-if="state.type != 'Customer'" class="input">
              <label
                >{{ lan.admin[13].Account_page[0].Corporate_number }}
                <span style="color: red">{{
                  v$.corporate_number.$error ? " is required" : ""
                }}</span></label
              ><br />
              <input
                type="text"
                name="Corporate number"
                v-model="state.corporate_number"
                placeholder="Corporate number"
                :class="v$.corporate_number.$error ? 'input-error' : ''"
              />
            </div>
            <div v-if="state.type != 'Customer'" class="input">
              <label
                >{{ lan.admin[13].Account_page[0].Invoice_address }}
                <span style="color: red">{{
                  v$.invoice_address.$error ? " is required" : ""
                }}</span></label
              ><br />
              <input
                type="text"
                name="Invoice address"
                v-model="state.invoice_address"
                placeholder="Invoice address"
                :class="v$.invoice_address.$error ? 'input-error' : ''"
              />
            </div>
            <div v-if="state.type != 'Customer'" class="input">
              <label
                >{{ lan.admin[13].Account_page[0].Invoice_e_mail
                }}<span>{{
                  v$.invoice_email.$error ? " is required" : ""
                }}</span></label
              ><br />
              <input
                type="email"
                name="email"
                v-model="state.invoice_email"
                placeholder="Invoice e-mail"
                :class="v$.invoice_email.$error ? 'input-error' : ''"
              />
            </div> -->
            <div class="input">
              <label
                >{{ lan.admin[13].Account_page[0].email
                }}<span style="color: red">{{
                  v$.email.$error ? " is required" : ""
                }}</span></label
              ><br />
              <input
                type="email"
                name="email"
                :class="v$.email.$error ? 'input-error' : ''"
                v-model="state.email"
                :placeholder="lan.admin[13].Account_page[0].email"
              />
            </div>
            <div class="input">
              <label
                >{{ lan.admin[13].Account_page[0].Account_owner }}<span>*</span
                ><span style="color: red">{{
                  v$.account_owner.$error ? " is required" : ""
                }}</span></label
              ><br />
              <select
                v-model="state.account_owner"
                :class="v$.account_owner.$error ? 'input-error' : ''"
              >
                <option value="" disabled selected>Please select one</option>
                <option
                  v-for="data in accountOwners"
                  :value="data.id"
                  :key="data.id"
                >
                  {{ data.first_name }} {{ data.last_name }}
                </option>
              </select>
            </div>
            <!-- <div v-if="state.type != 'Customer'" class="input">
              <label>{{
                lan.admin[13].Account_page[0].Commission_persent
              }}</label
              ><br />
              <input
                type="text"
                name="text"
                v-model="commission"
                placeholder="Commission"
              />
            </div>
            <div v-if="state.type != 'Customer'" class="input">
              <label>{{ lan.admin[13].Account_page[0].Payment_terms }}</label
              ><br />
              <input
                type="text"
                name="text"
                v-model="payment_terms"
                placeholder="Payment terms"
              />
            </div>
            <div class="input">
              <div v-if="state.type != 'Customer'" class="input">
                <label>{{
                  lan.admin[13].Account_page[0].Google_map_location
                }}</label
                ><br />
                <vue-google-autocomplete
                  id="map"
                  placeholder="Type Address"
                  v-on:placechanged="getAddressData"
                >
                </vue-google-autocomplete>
              </div>
            </div>
            <div v-if="state.type != 'Customer'" class="input">
              <label>{{ lan.admin[13].Account_page[0].Main_image }}</label
              ><br />
              <input
                type="file"
                name="file"
                @change="onFileChange"
                placeholder="Main image"
              />
            </div>
            <div v-if="state.type != 'Customer'" class="input">
              <label>{{ lan.admin[13].Account_page[0].Add_image }}</label
              ><br />
              <input
                type="file"
                id="files"
                @change="onFileChange2"
                name="files"
                multiple=""
              />
            </div>
            <div v-if="check_from != 'SelfOppo'" class="input">
              <label>{{ lan.admin[13].Account_page[0].Primary_contact }}</label
              ><br />
              <div class="d-flex">
                <select v-model="primary_contact">
                  <option value="" disabled selected>Please select one</option>
                  <option
                    v-for="data in contactsDetailsFilter"
                    :value="data.id"
                    :key="data.id"
                  >
                    {{ data.first_name }} {{ data.last_name }}
                  </option>
                </select>
                <button
                  class="search-btn-new filter-btn"
                  data-toggle="modal"
                  data-target="#exampleModal22"
                >
                  {{ lan.admin[13].Account_page[0].Create_new_contact }}
                </button>
              </div>
            </div>
            <div class="input d-flex">
              <textarea
                v-if="state.type != 'Customer'"
                name=""
                id=""
                cols="20"
                rows="5"
                class="mt-4"
                v-model="predefined_comment1"
                spellcheck="false"
                style="width: 50%"
                placeholder="Predefined comment 1"
              ></textarea>
              <textarea
                v-if="state.type != 'Customer'"
                name=""
                id=""
                cols="20"
                rows="5"
                class="mt-4 ml-3"
                v-model="predefined_comment2"
                spellcheck="false"
                style="width: 50%"
                placeholder="Predefined comment 2"
              ></textarea>
            </div>

            <div class="input d-flex">
              <textarea
                v-if="state.type != 'Customer'"
                name=""
                id=""
                cols="20"
                rows="5"
                class="mt-4"
                v-model="predefined_comment3"
                spellcheck="false"
                style="width: 50%"
                placeholder="Predefined comment 3"
              ></textarea>
              <textarea
                v-if="state.type != 'Customer'"
                name=""
                id=""
                cols="20"
                rows="5"
                class="mt-4 ml-3"
                spellcheck="false"
                v-model="predefined_comment4"
                style="width: 50%"
                placeholder="Predefined comment 4"
              ></textarea>
            </div>-->

            <div class="button d-flex justify-content-end mt-4">
              <button class="search-btn" @click="submit">
                <div class="lds-ring" v-show="loading">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span v-show="loadingS">{{
                  lan.admin[13].Account_page[0].Save
                }}</span>
              </button>
              <button
                class="filter-btn"
                ref="Close"
                data-dismiss="modal"
                v-on:click="route()"
              >
                {{ lan.admin[13].Account_page[0].Cancel }}
              </button>
            </div>
            <!-- <div v-if="state.type != 'Customer'">
              <div>
                <div class="d-inline-block mb-3 mr-3">
                  <div v-if="preview_image">
                    <span class="d-block">{{ lan.admin[13].Account_page[0].Main_image}}</span>

                    <div class="column">
                    <img :src="preview_image" class="img-flow my-0" />

                    <div class="overlay row justify-content-center align-items-center mx-0">
                      <div class="text col-6 d-flex justify-content-center align-items-center"
                      @click="fullImageGallery(preview_image)"
                      data-toggle="modal" data-target="#exampleModal5"
                       style="height: 100%;">
                        <p class="mb-0 fw-bold">VIEW</p>
                      </div>
                      <div class="delete col-6 d-flex justify-content-center align-items-center" style="height: 100%;">
                        <button @click="preview_image = null" class="icon">
                          <i class="fa-solid fa-trash"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  </div>
                </div>

                <div
                  class="w-new"
                  v-if="preview_other_images || preview_other_images.length"
                >
                  <span for="">More Images</span>
                  <div style="display: flex; overflow-x: scroll;">
                    <div
                      v-if="preview_other_images"
                      v-for="(image, index) in preview_other_images"
                      :key="index"
                      style="margin-right: 15px"
                      class="column"
                    >
                      <img :src="image" class="img-flow my-0" />

                      
                      <div class="overlay row justify-content-center align-items-center mx-0">
                        <div class="text col-6 d-flex justify-content-center align-items-center"
                        @click="fullImageGallery(image)"
                        data-toggle="modal" data-target="#exampleModal5"
                         style="height: 100%;">
                          <p class="mb-0 fw-bold">VIEW</p>
                        </div>
                        <div class="delete col-6 d-flex justify-content-center align-items-center" style="height: 100%;">
                          <button @click="deleteImage(index)" class="icon">
                            <i class="fa-solid fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div
                      v-else
                      v-for="(image, indexx) in other_images"
                      :key="indexx"
                      style="margin-right: 15px"
                    >
                      <img :src="image" class="img-flow" />
                    </div>
                  </div>
                </div>
              </div>
            </div>  -->
          </div>
        </div>
      </div>
    </div>
    <!-- <AddConatact @event="getContact" /> -->

    <!-- Preview image Modal -->
    <PreviewImageModal :expandedImage="expandedImage" />
  </div>
</template>

<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
// import AddConatact from "./addContact.vue";
import PreviewImageModal from "./previewImageModal.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { required, email } from "@vuelidate/validators";
import { reactive, computed } from "vue";
export default {
  name: "Add conatct",
  components: {
    PreviewImageModal,
    VueGoogleAutocomplete,
    // AddConatact,
  },
  props: {
    contactsDetails: {
      type: [String, Object, Array],
    },
    accountOwners: {
      type: [String, Object, Array],
    },
    account_type: {
      type: [String, Object],
    },
    check_from: {
      type: [String],
    },
    value: {
      type: [String, Number],
    },
  },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      company_name: "",
      type: "",
      address: "",
      phone: "",
      corporate_number: "",
      invoice_address: "",
      invoice_email: "",
      account_owner: "",
    });

    const rules = computed(() => {
      return {
        company_name: { required },
        type: { required },
        address: {},
        phone: {},
        email: { required, email },
        corporate_number: {},
        invoice_address: {},
        invoice_email: {},
        account_owner: { required },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },

  data() {
    return {
      venue_name: "",
      commission: "10",
      payment_terms: "",
      google_map_location: {},
      image: "",
      other_images: [],
      preview_image: "",
      preview_other_images: [],
      description: "",
      primary_contact: "",
      predefined_comment1: "",
      predefined_comment2: "",
      predefined_comment3: "",
      predefined_comment4: "",

      // accountOwners: "",

      // contactsDetails: [],
      address: "",

      new_contact_details: "",
      check_new_contact: false,

      loading: false,
      loadingS: true,

      hideVenue: true,
      hideCommission: true,
      hidePaymentTerms: true,
      hideMainImage: true,
      hideAddImage: true,
      hideTextArea: true,

      expandedImage: null,
    };
  },
  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
    // accountType(){

    // },

    contactsDetailsFilter() {
      if (this.contactsDetails == "") {
        return;
      }
      // console.log("this.contactsDetails", this.contactsDetails);
      var data = [];
      // var self = this;
      var type =
        this.state.type == "Partner"
          ? 2
          : this.state.type == "Customer"
          ? 3
          : 1;
      if (this.state.type != "") {
        this.contactsDetails.forEach((element) => {
          // console.log('element',element)
          if (element.roles == type) data.push(element);
        });
      } else {
        data = this.contactsDetails;
      }
      return data;
    },
  },
  beforeMount() {
    // for top load page
    window.scrollTo(0, 0);
  },
  mounted() {
    this.state.type = this.account_type;
    // this.getContact();

    // //************** Account owner details
    // axios
    //   .get(axios.defaults.baseURL + "get-account-owners", {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   })
    //   .then((res) => {
    //     this.accountOwners = res.data.data;
    //     console.log("account owners", this.accountOwners);
    //   })
    //   .catch((err) => {
    //     console.log("err account owner", err);
    //   });
  },
  methods: {
    checkCustomer() {
      if (this.state.type == "Customer") {
        this.hideVenue = false;
        this.hideCommission = false;
        this.hidePaymentTerms = false;
        this.hideMainImage = false;
        this.hideAddImage = false;
        this.hideTextArea = false;
      } else {
        this.hideVenue = true;
        this.hideCommission = true;
        this.hidePaymentTerms = true;
        this.hideMainImage = true;
        this.hideAddImage = true;
        this.hideTextArea = true;
      }
    },

    fullImageGallery(image) {
      console.log("image h-", image);
      this.expandedImage = image;
    },
    closeImageGallery() {
      this.expandedImage = null;
    },
    deleteImage(index) {
      this.preview_other_images.splice(index, 1);
    },
    // getContact(data) {
    //   this.check_new_contact = data == undefined ? false : true;
    //   this.new_contact_details = data;
    //   console.log("getContact", data);
    //   axios
    //     .get(axios.defaults.baseURL + "contacts", {
    //       headers: {
    //         Authorization: "Bearer " + localStorage.getItem("token"),
    //       },
    //     })
    //     .then((res) => {
    //       this.contactsDetails.length = 0;
    //       res.data.data.forEach((element) => {
    //         if (element.roles != 1) {
    //           this.contactsDetails.push(element);
    //         }
    //       });
    //     })
    //     .catch((err) => {
    //       console.log("err", err);
    //     });
    // },
    onFileChange(event) {
      const maxAllowedSize = 1 * 1024 * 1024;

      var size = event.target.files[0].size;
      console.log(size, maxAllowedSize);
      if (size > maxAllowedSize) {
        this.AddToast(
          "The image should be less than 2MB.",
          "Information",
          "info_1"
        );
      } else {
        this.preview_image = URL.createObjectURL(event.target.files[0]);
        this.image = event.target.files[0];
      }
      //   }
    },
    onFileChange2(event) {
      const maxAllowedSize = 1 * 1024 * 1024;
      var add = 0;

      for (var i = 0; i < event.target.files.length; i++) {
        var size = event.target.files[i].size;
        if (size > maxAllowedSize) {
          this.AddToast(
            "The image should be less than 2MB.",
            "Information",
            "info_1"
          );
        } else {
          add++;
        }
      }
      console.log("add", add);
      console.log(" event.target.files.length", event.target.files.length);
      if (event.target.files.length == add) {
        for (var i = 0; i < event.target.files.length; i++) {
          this.other_images.push(event.target.files[i]);
          this.preview_other_images.push(
            URL.createObjectURL(event.target.files[i])
          );
        }
      }
    },

    submit() {
      this.v$.company_name.$touch();
      this.v$.type.$touch();
      this.v$.account_owner.$touch();
      this.v$.email.$touch();

      if (
        !this.v$.company_name.$invalid &&
        !this.v$.type.$invalid &&
        !this.v$.email.$invalid &&
        !this.v$.account_owner.$invalid
      ) {
        this.loading = true;
        this.loadingS = false;

        var formData = new FormData();
        formData.append("name", this.state.company_name);
        formData.append("venue_name", this.venue_name);
        formData.append("type", this.state.type);
        formData.append("email", this.state.email);
        formData.append("address", this.state.address);
        formData.append("phone", this.state.phone);
        formData.append("corporate_number", this.state.corporate_number);
        formData.append("invoice_address", this.state.invoice_address);
        formData.append("invoice_email", this.state.invoice_email);
        formData.append("account_owner", this.state.account_owner);
        formData.append("commission", this.commission);
        formData.append("payment_terms", this.payment_terms);
        formData.append("default_language", '');
        formData.append("payment_terms", '');
        formData.append("payment_terms", '');
        formData.append(
          "google_map_location",
          JSON.stringify(this.google_map_location)
        );
        if (this.image != "") {
          formData.append("image", this.image);
        }
        for (var i = 0; i < this.other_images.length; i++) {
          formData.append("other_images[]", this.other_images[i]);
        }
        formData.append("is_outside_partner", 1);
        formData.append("description", this.description);
        formData.append("primary_contact", this.primary_contact);
        formData.append("predefined_comment1", this.predefined_comment1);
        formData.append("predefined_comment2", this.predefined_comment2);
        formData.append("predefined_comment3", this.predefined_comment3);
        formData.append("predefined_comment4", this.predefined_comment4);

        axios
          .post(axios.defaults.baseURL + "accounts", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("res account-", res);

            if (this.check_new_contact) {
              var formData = new FormData();
              formData.append(
                "first_name",
                this.new_contact_details.first_name
              );
              formData.append("last_name", this.new_contact_details.last_name);
              formData.append("email", this.new_contact_details.email);
              formData.append("roles", this.new_contact_details.roles);
              formData.append("phone", this.new_contact_details.phone);
              formData.append("company", res.data.data.account_id);
              formData.append(
                "mobile_phone",
                this.new_contact_details.mobile_phone
              );
              formData.append("address", this.new_contact_details.address);
              formData.append("password", "12345678");
              formData.append("confirm_password", "12345678");
              formData.append("note", "");
              formData.append("task", "");

              axios
                .post(
                  axios.defaults.baseURL +
                    "edit-contact/" +
                    this.new_contact_details.id,
                  formData,
                  {
                    headers: {
                      Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                  }
                )
                .then((res) => {
                  console.log("res -contact-", res);

                  (this.loading = false),
                    (this.loadingS = true),
                    this.AddToast(res.data.message, " Success ", "success_1");
                  this.$refs.Close.click();
                  this.$emit("getBidders", res.data.data);
                })
                .catch((err) => {
                  (this.loading = false),
                    (this.loadingS = true),
                    console.log("err", err);
                  this.AddToast(err.response.data.message, "Faild", "error_1");
                });
            } else {
              (this.loading = false),
                (this.loadingS = true),
                this.$store.dispatch("checkErrorAndSendToast", [
                  res,
                  "Success",
                ]);
              this.$refs.Close.click();
              this.$emit("getBidders", res.data.data);
            }
          })
          .catch((err) => {
            (this.loading = false),
              (this.loadingS = true),
              console.log("err", err);
            this.$store.dispatch("checkErrorAndSendToast", [
              err.response,
              "error",
            ]);
          });
      } else {
        console.log("else", this.v$);
        this.AddToast("Please Fill Required Details", " info ", "info_1");
        return;
      }
    },

    route() {
      this.$refs.Close.click();
    },
    getAddressData(addressData, placeResultData, id) {
      this.google_map_location = {
        administrative_area_level_1: addressData.administrative_area_level_1,
        administrative_area_level_2: addressData.administrative_area_level_2,
        country: addressData.country,
        route: addressData.route,
        postal_code: addressData.postal_code,
        longitude: addressData.longitude,
        latitude: addressData.latitude,
      };
      console.log(addressData);
      console.log(placeResultData);
      console.log(id);
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>

<style scoped>
.column {
  position: relative;
}

.column .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.column:hover .overlay {
  opacity: 1;
}
.column .overlay .text {
  background-color: rgba(0, 0, 0, 0.519);
  color: white;
}
.column .overlay .icon {
  width: 40px;
  height: 40px;
  border: none;
}
.column .overlay .delete {
  background-color: rgba(0, 0, 0, 0.347);
}
.column .overlay .icon i {
  font-size: 16px;
}
.column img {
  cursor: pointer;
}

/* Closable button inside the image */
.closebtn {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
  font-size: 35px;
  cursor: pointer;
}
.container_img {
  position: relative;
  /* display: none; */
}
.column img:hover {
  opacity: 1;
}
</style>
