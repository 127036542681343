<template>
  <div>
    <div
      class="modal fade"
      id="exampleModal1"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div>
              <div class="input">
                <label
                  >{{ lan.admin[9].Task_page[0].Subject}}<span>*</span>
                  <!-- <span>{{
                        v2$.subject.$error ? " Subject name is required" : ""
                      }}</span> --> </label
                ><br />
                <input
                  type="text"
                  name="text"
                  :placeholder="lan.admin[9].Task_page[0].Subject"
                  v-model="state.subject"
                  :class="v$.subject.$error ? 'input-error' : ''"
                />

                <label class="mt-3"
                  >{{ lan.admin[9].Task_page[0].Assigned_to}}<span>*</span>
                  <!-- <span>{{
                              v2$.assign_to.$error
                                ? " Assign to is required"
                                : ""
                            }}</span> --> </label
                ><br />
                <select
                  v-model="state.assign_to"
                  :class="v$.assign_to.$error ? 'input-error' : ''"
                >
                  <option value="" disabled selected>Please select one</option>
                  <option
                    v-for="data in accountOwners"
                    :value="data.id"
                    :key="data.id"
                  >
                    {{ data.first_name }} {{ data.last_name }}
                  </option>
                </select>

                <label class="mt-3">{{ lan.admin[9].Task_page[0].Due_date}}</label
                ><br />
                <input
                  type="date"
                  name="text"
                  v-model="due_date"
                />
              </div>

              <div class="input">
                <label class="mt-3">{{ lan.admin[9].Task_page[0].Related_contact}}</label
                ><br />
                <model-list-select
                  v-if="contactsDetails"
                  :list="contactsDetails"
                  style="
                    height: 43px;
                    border: 1px solid #ccc;
                    border-radius: 2px;
                  "
                  v-model="related_contact"
                  option-value="id"
                  :custom-text="getNameContact"
                  placeholder="Please select one"
                >
                </model-list-select>
                <!-- <select v-model="related_contact">
                      <option value="" disabled selected>Please Select One</option>
                      <option
                        v-for="(data, index) in contactsDetails"
                        :key="index"
                        :value="data.id"
                      >
                        {{ data.first_name }} {{ data.last_name }}
                      </option>
                    </select> -->

                <label class="mt-3">{{ lan.admin[9].Task_page[0].Related_Account}}</label
                ><br />
                <model-list-select
                  v-if="accountDetails"
                  :list="accountDetails"
                  style="
                    height: 43px;
                    border: 1px solid #ccc;
                    border-radius: 2px;
                  "
                  v-model="related_account"
                  option-value="account_id"
                  :custom-text="getName"
                  placeholder="Please select one"
                >
                </model-list-select>
                <!-- <select v-model="related_account">
                      <option value="" disabled selected>Please Select One</option>
                      <option
                        v-for="(data, index) in accountDetails"
                        :key="index"
                        :value="data.account_id"
                      >
                        {{ data.name }}
                      </option>
                    </select> -->

                <label class="mt-3">{{ lan.admin[9].Task_page[0].Related_Opportunity}}</label
                ><br />
                <model-list-select
                  v-if="opportunitiesDetails"
                  :list="opportunitiesDetails"
                  style="
                    height: 43px;
                    border: 1px solid #ccc;
                    border-radius: 2px;
                  "
                  v-model="related_opportunity"
                  option-value="opportunity_id"
                  :custom-text="getNameOpportunity"
                  placeholder="Please select one"
                >
                </model-list-select>
                <!-- <select v-model="related_opportunity">
                      <option value="" disabled selected>Please Select One</option>
                      <option
                        v-for="(data, index) in opportunitiesDetails"
                        :key="index"
                        :value="data.opportunity_id"
                      >
                        {{ data.account_detail.name }}
                      </option>
                    </select> -->
              </div>

              <div class="input">
                <label class="mt-3">{{ lan.admin[9].Task_page[0].Priority}}</label
                ><br />
                <select v-model="priority">
                  <!-- <option value="Priority">Priority</option> -->
                  <option value="" disabled selected>Please Select One</option>
                  <option value="1">High</option>
                  <option value="2">Medium</option>
                  <option value="3">Low</option>
                </select>

                <label class="mt-3">{{ lan.admin[9].Task_page[0].Status}}</label
                ><br />
                <select v-model="statusTask">
                  <!-- <option value="Status">Status</option> -->
                  <option value="" disabled selected>Please Select One</option>
                  <option value="1">Open</option>
                  <option value="2">Completed</option>
                </select>

                <label class="mt-3">{{ lan.admin[9].Task_page[0].Reminder}}</label
                ><br />
                <input
                  type="date"
                  name="text"
                  placeholder="Related Opportunity"
                  v-model="reminder"
                />
              </div>

              <label>{{ lan.admin[9].Task_page[0].Comment}}</label
              ><br />
              <textarea class="textarea" rows="5" v-model="comment">
               Comment</textarea
              >
            </div>
          </div>
          <div class="button d-flex justify-content-end mt-4 pr-4">
            <!-- <button href="#"
                          ><button class="filter-btn">Save and new</button></button
                        > -->
            <button class="search-btn ml-3" @click="saveNewTask()">
              <div class="lds-ring" v-show="loadingSaveNewTaskLoader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <span v-show="loadingSaveNewTaskText">{{ lan.admin[9].Task_page[0].Save}}</span>
            </button>
            <button ref="Close" class="filter-btn" data-dismiss="modal">
              {{ lan.admin[9].Task_page[0].Cancel}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed } from "vue";

import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";

export default {
  name: "Add Task",
  components: { ModelListSelect },
  props: {
    accountDetails: {
      type: [String, Object, Array],
    },
    opportunitiesDetails: {
      type: [String, Object, Array],
    },
    contactsDetails: {
      type: [String, Object, Array],
    },
    accountOwners: {
      type: [String, Object, Array],
    },
  },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      subject: "",
      assign_to: "",
    });

    const rules = computed(() => {
      return {
        subject: { required },
        assign_to: { required },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      // add Task
      due_date: "",
      comment: "",
      reminder: "",
      priority: "",
      statusTask: "",
      related_account: "",
      related_contact: "",
      related_opportunity: "",

      loadingSaveNewTaskText: true,
      loadingSaveNewTaskLoader: false,
    };
  },
  computed: {
    lan(){
      return this.$store.getters.whichLanguage
    },
  },
  methods: {
    getName(item) {
      return `${item.name}`;
    },
    getNameOpportunity(item) {
      return `${item.account_detail.name}`;
    },
    getNameContact(item) {
      return `${item.first_name}' '${item.last_name}`;
    },
    saveNewTask() {
      this.v$.subject.$touch();
      this.v$.assign_to.$touch();
      // this.v2$.$validate();
      // if (!this.v2$.$error) {
      if (!this.v$.subject.$invalid && !this.v$.assign_to.$invalid) {
        this.loadingSaveNewTaskLoader = true;
        this.loadingSaveNewTaskText = false;
        var formData = new FormData();
        formData.append("subject", this.state.subject);
        formData.append("assign_to", this.state.assign_to);
        formData.append("due_date", this.due_date);
        formData.append("comment", this.comment);
        formData.append("reminder", this.reminder);
        formData.append("priority", this.priority);
        formData.append("status", this.statusTask);
        formData.append("related_account", this.related_account);
        formData.append("related_contact", this.related_contact);
        formData.append("related_opportunity", this.related_opportunity);
        axios
          .post(axios.defaults.baseURL + "tasks", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("res", res);
            this.AddToast(res.data.message, " Success ", "success_1");
            this.loadingSaveNewTaskLoader = false;
            this.loadingSaveNewTaskText = true;
            this.$emit("data-updated-task", res.data.data);
            this.$refs.Close.click();
          })
          .catch((err) => {
            (this.loadingSaveNewTaskLoader = false),
              (this.loadingSaveNewTaskText = true),
              console.log("err", err);
            this.AddToast(err.response.data.message, "Faild", "error_1");
            this.$refs.Close.click();
          });
      } else {
        this.AddToast("Please Fill Required Details", " info ", "info_1");
        return;
      }
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>
