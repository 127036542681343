<template>
  <!--**********************************
  Main wrapper start
***********************************-->
  <div id="main-wrapper">
    <!--**********************************
      Content body start
  ***********************************-->
    <div class="content-body pt-md-3 pt-0 mb-5">
      <div class="container-fluid">
        <div
          class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
        >
          <h2 class="font-w600 title mb-2 mr-auto">{{ lan.admin[19].Edit_product_page[0].Edit_product}}</h2>
          <a href="#myModal" class="trigger-btn" data-toggle="modal">
            <i
              v-show="value_T"
              class="fa fa-trash btn btn-danger shadow btn-xs sharp"
            ></i>
          </a>

          <div v-show="value_L" class="loadingio-spinner-rolling-yxlbb0cd94">
            <div class="ldio-4c1f6xkxs72">
              <div></div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div
                class="card-header pb-2 d-block d-sm-flex flex-wrap border-0"
              ></div>

              <div class="card-body p-4">
                <div>
                  <div class="row">
                    <div class="col-md-4 input">
                      <label
                        >{{ lan.admin[18].Add_product_page[0].Product_name
                        }}<span>*</span>
                        <span>{{
                          v$.product_name.$error
                            ? " Product name is required"
                            : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="text"
                        v-model="state.product_name"
                        placeholder="Product name"
                        :class="v$.product_name.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div class="col-md-4 input">
                      <label>{{
                        lan.admin[18].Add_product_page[0].Product_family
                      }}</label
                      ><br />

                      <select v-model="product_family">
                        <option value="" disabled selected>
                          Select select one
                        </option>
                        <option value="Accomodation">Accomodation</option>
                        <option value="Conference room">Conference room</option>
                        <option value="Transportation">Transportation</option>
                        <option value="Meals">Meals</option>
                        <option value="Activity">Activity</option>
                        <option value="Others">Others</option>
                      </select>
                    </div>

                    <div
                      v-if="product_family == 'Accomodation'"
                      class="col-md-4 input"
                    >
                      <label>Type<span>*</span></label
                      ><br />

                      <select v-model="state.type">
                        <option value="" disabled selected>Select type</option>
                        <option value="Single room">Single room</option>
                        <option value="Double room">Double room</option>
                      </select>
                    </div>

                    <div class="col-md-4 input">
                      <label>{{
                        lan.admin[18].Add_product_page[0].Active
                      }}</label
                      ><br />
                      <select v-model="status">
                        <option value="" disabled selected>
                          Select status
                        </option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-4 input">
                      <label>{{
                        lan.admin[18].Add_product_page[0].Price_per_person
                      }}</label
                      ><br />
                      <select v-model="price_per_person">
                        <option value="" disabled selected>
                          Price per person
                        </option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>

                    <div class="col-md-4 input">
                      <label>{{
                        lan.admin[18].Add_product_page[0].Minimum_price
                      }}</label
                      ><br />
                      <select v-model="minimum_price">
                        <option value="" disabled selected>
                          Minimum price
                        </option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>

                    <div class="col-md-4 input">
                      <label>{{ lan.admin[18].Add_product_page[0].Start_price}}</label><br />
                      <select v-model="start_price">
                        <option value="" disabled selected>
                          {{ lan.admin[18].Add_product_page[0].Start_price }}
                        </option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>
                      </select>
                    </div>
                    <div class="col-md-4 input">
                      <label>{{ lan.admin[18].Add_product_page[0].no_of_courses}}</label
                      ><br />
                      <select v-model="no_of_courses">
                        <option value="" disabled selected>
                          Select No Of Courses 
                        </option>
                        <option value="1">Show</option>
                        <option value="0">Hide</option>
                      </select>
                    </div>
                  </div>

                  <label
                    >{{ lan.admin[18].Add_product_page[0].Description }}
                    <span>{{
                      v$.product_description.$error
                        ? " Product description is required"
                        : ""
                    }}</span></label
                  ><br />
                  <textarea
                    name=""
                    id=""
                    class="textarea"
                    v-model="state.product_description"
                    rows="5"
                    spellcheck="false"
                    :class="v$.product_description.$error ? 'input-error' : ''"
                  >
                  Description</textarea
                  >

                  <div class="button d-flex justify-content-end mt-4">
                    <button @click="submit()" class="search-btn">
                      <div class="lds-ring" v-show="loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <span v-show="loadingS">{{
                        lan.admin[18].Add_product_page[0].Save
                      }}</span>
                    </button>
                    <router-link to="/products" class="filter-btn">{{
                      lan.admin[18].Add_product_page[0].Cancel
                    }}</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <deleteModal @delete="deletef" />
    <!--**********************************
      Content body end
  ***********************************-->
  </div>
  <!--**********************************state.
  Main wrapper end
***********************************-->
</template>

<script>
import axios from "axios";
import deleteModal from "../../../components/deleteModal";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed } from "vue";

export default {
  name: "Add Contact",
  components: { deleteModal },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      product_name: "",
      product_description: "",
      type: "",
    });

    const rules = computed(() => {
      return {
        product_name: { required },
        product_description: {},
        type: { required },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },

  data() {
    return {
      product_details: "",
      product_family: "Product family",
      status: "Active",
      price_per_person: "",
      minimum_price: "",
      start_price: "",
      no_of_courses: '',
      Id: this.$route.params.id,

      loading: false,
      loadingS: true,
      value_L: false,
      value_T: true,
    };
  },
  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
  },
  mounted() {
    // for top load page
    window.scrollTo(0, 0);

    axios
      .get(axios.defaults.baseURL + "product/" + this.Id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.product_details = res.data.data[0];

        this.state.product_name = res.data.data[0].product_name;
        this.state.product_description = res.data.data[0].product_description;
        this.product_family = res.data.data[0].product_family;
        this.status = res.data.data[0].status;
        this.price_per_person = res.data.data[0].price_per_person;
        this.minimum_price = res.data.data[0].minimum_price;
        this.start_price = res.data.data[0].start_price;
        this.state.type = res.data.data[0].type;
        this.no_of_courses = res.data.data[0].no_of_courses;
      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });
  },
  methods: {
    submit() {
      this.v$.product_name.$touch();
      this.v$.type.$touch();

      if (this.product_family != "Accomodation") {
        this.state.type = "";
      }

      if (
        !this.v$.product_name.$invalid &&
        (this.product_family == "Accomodation"
          ? !this.v$.type.$invalid
          : this.v$.type.$invalid)
      ) {
        (this.loading = true), (this.loadingS = false);
        var formData = new FormData();
        formData.append("product_name", this.state.product_name);
        formData.append("product_description", this.state.product_description);
        formData.append("status", this.status);
        formData.append("product_family", this.product_family);
        formData.append("price_per_person", this.price_per_person);
        formData.append("no_of_courses", this.no_of_courses);
        formData.append("minimum_price", this.minimum_price);
        formData.append("start_price", this.start_price);
        formData.append("type", this.state.type);

        axios
          .post(axios.defaults.baseURL + "edit-product/" + this.Id, formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("res", res);
            (this.loading = false),
              (this.loadingS = true),
              this.$router.push({ path: "/products" });
              this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
          })
          .catch((err) => {
            (this.loading = false),
              (this.loadingS = true),
              console.log("err", err);
              this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
          });
      } else {
        this.AddToast(this.lan.Please_Fill_Required_Details, " info ", "info_1");
        return;
      }
    },
    deletef() {
      this.value_L = true;
      this.value_T = false;
      axios
        .post(axios.defaults.baseURL + "delete-product/" + this.Id,{}, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.value_L = false;
          this.value_T = true;
          this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
          document.getElementById('deletemodal').click();
          this.$router.push({ path: "/products" });
        })
        .catch((err) => {
          this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
          this.value_L = false;
          this.value_T = true;
        });
    },

    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>
