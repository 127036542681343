<template>
  <div>
    <!--**********************************
  Main wrapper start
***********************************-->
    <div id="main-wrapper">
      <!--**********************************
      Content body start
  ***********************************-->
      <div class="content-body pt-0">
        <div class="container-fluid">
          <div
            class="form-head  d-flex flex-wrap align-items-center pt-3"
          >
            <h2 class="font-w600 title mb-2 mr-auto">{{ lan.admin[8].Tasks[0].My_tasks}}</h2>

            <div class="d-flex justify-content-end mt-3">
              <router-link to="/add-task"
                ><button class="filter-btn up-btn">
                  {{ lan.admin[8].Tasks[0].Create_new_tasks}}
                </button></router-link
              >
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12 col-xxl-12">
              <div class="row">
                <div class="col-sm-12 mar-bo">
                  <div class="card">
                    <div class="card-header border-0 pb-0 justify-content-end">
                      <div class="button justify-content-end d-flex">
                        <!-- <a href="#"><button class="search-btn">Search</button></a> -->
                        <input
                          type="search"
                          name="search"
                          class="init new-init ml-3 w-new"
                          placeholder="Search"
                          v-model="search"
                        />
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="table-responsive">
                        <table class="text-center tr-rounded order-tbl">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>{{lan.admin[8].Tasks[0].Subject}}</th>
                              <th>{{lan.admin[8].Tasks[0].Reminder_Date}}</th>
                              <th>{{lan.admin[8].Tasks[0].Prio}}</th>
                              <th>{{lan.admin[8].Tasks[0].Status}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in filtered"
                              :key="index"
                              @click="edit(data.task_id)"
                              class="pointer"
                            >
                            <td>{{index+1}}</td>
                              <td>{{ data.subject }}</td>
                              <td>{{ data.reminder }}</td>
                              <td>
                                {{
                                  data.priority == 1
                                    ? "High"
                                    : data.priority == 2
                                    ? "Medium"
                                    : "Low"
                                }}
                              </td>
                              <td>
                                <span
                                  class="badge light"
                                  :class="
                                    data.status == '1'
                                      ? 'badge-info'
                                      : 'badge-success'
                                  "
                                  >{{
                                    data.status == "1" ? "Open" : "Completed"
                                  }}</span
                                >
                              </td>
                            </tr>
                            <div class="lds-ellipsis" v-show="value">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--**********************************
      Content body end
  ***********************************-->
    </div>
    <!--**********************************
  Main wrapper end
***********************************-->
  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src

export default {
  name: "Tasks",
  data() {
    return {
      tasksDetails: "",
      value: true,
      search: "",
    };
  },
  computed: {

    lan(){
      return this.$store.getters.whichLanguage
    },

    filtered() {
      // var self = this;
      if (this.search != "") {
        return this.tasksDetails.filter((task) => {
          return (
            task.subject.toLowerCase().includes(this.search.toLowerCase()) ||
            task.comment.toLowerCase().includes(this.search.toLowerCase())
          );
        });
      } else {
        return this.tasksDetails;
      }
    },
  },
  mounted() {
     // for top load page
     window.scrollTo(0, 0);
     
    axios
      .get(axios.defaults.baseURL + "tasks", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log("res", res);
        this.tasksDetails = res.data.data;
        this.value = false;
        console.log("tasksDetails", this.tasksDetails);
      })
      .catch((err) => {
        console.log("err", err);
        this.value = false;
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });
  },
  methods: {
    edit(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Task", params: { id: id } });
    },
  },
};
</script>
