<template>
  <div>
    <!--**********************************
  Main wrapper start
***********************************-->
    <div id="main-wrapper">
      <!--**********************************
      Content body start
  ***********************************-->
      <div class="content-body pt-0">
        <div class="container-fluid">
          <div
            class="form-head  mb-3 d-flex flex-wrap align-items-center pt-3"
          >
            <h2 class="font-w600 title mb-2 mr-auto">{{ lan.admin[17].Products[0].All_Products}}</h2>

            <div class="d-flex justify-content-end mt-3">
              <router-link to="/add-product"
                ><button class="filter-btn up-btn">
                  {{ lan.admin[17].Products[0].Create_new_product}}
                </button></router-link
              >
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12 mar-bo">
              <div class="card">

                <div
                  class="card-header pb-2 d-block d-sm-flex flex-wrap border-0 justify-content-end"
                >
                  <div class="button justify-content-end d-flex">
                    <!-- <a href="#"><button class="search-btn">Search</button></a> -->
                    <input
                      type="search"
                      name="search"
                      class="init new-init ml-3 w-new"
                      placeholder="Search"
                      v-model="search"
                    />
                    <div class="drop-down">
                      <button
                        class="filter-btn mb-0 dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                      {{ lan.admin[3].Opportunity[0].Filter_view }}
                      </button>
                      <div
                        class="dropdown-menu mt-3"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="Accomodation"
                            value="Accomodation"
                            v-model="filterOptions.accomodation"
                          />
                          <label for="Accomodation"> Accomodation</label><br />
                        </a>
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="Conference room"
                            value="Conference room"
                            v-model="filterOptions.conference_room"
                          />
                          <label for="Conference room">Conference room</label><br />
                        </a>
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="Transportation"
                            value="Transportation"
                            v-model="filterOptions.transportation"
                          />
                          <label for="Transportation">Transportation </label><br />
                        </a>
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="Meals"
                            value="Meals"
                            v-model="filterOptions.meals"
                          />
                          <label for="Meals"> Meals</label><br />
                        </a>
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="Activity"
                            value="Activity"
                            v-model="filterOptions.activity"
                          />
                          <label for="Activity"> Activity</label><br />
                        </a>
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="Others"
                            value="Others"
                            v-model="filterOptions.others"
                          />
                          <label for="Others"> Others</label><br />
                        </a>
                        <!-- <a class="dropdown-item" href="#">
                          <input
                            type="radio"
                            id="html1"
                            name="fav_language1"
                            value="Closed"
                            v-model="search"
                          />
                          <label for="vehicle1">{{ lan.admin[3].Opportunity[0].Closed }}</label><br />
                        </a> -->
                      </div>
                    </div>
                  </div>
                </div>


                <div class="card-body tab-content p-3">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>{{ lan.admin[17].Products[0].Product}}</th>
                            <th>{{ lan.admin[17].Products[0].Description}}</th>
                            <th>{{ lan.admin[17].Products[0].Product_family}}</th>
                            <th>{{ lan.admin[17].Products[0].Active}}</th>
                            <th>{{ lan.admin[17].Products[0].Price_per_person}}</th>
                            <th>{{ lan.admin[17].Products[0].Minimum_price}}</th>
                            <th>{{ lan.admin[17].Products[0].Start_price}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr 
                            v-for="(data, index) in filtered"
                            :key="index"
                            @click="edit(data.product_id)"
                            class="pointer"
                          >
                          <td>{{index+1}}</td>
                            <td class="text-left">{{ data.product_name }}</td>
                            <td class="text-left">{{ data.product_description }}</td>
                            <td class="text-left">{{ data.product_family }}</td>
                            <td>
                              <span
                                class="badge light"
                                :class="
                                  data.status == 1
                                    ? 'badge-success'
                                    : 'badge-danger'
                                "
                                >{{ data.status == 1 ? "Yes" : "No" }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="badge light text-center"
                                :class="
                                  data.price_per_person == 1
                                    ? 'badge-success'
                                    : 'badge-danger'
                                "
                                >{{
                                  data.price_per_person == 1 ? "Yes" : "No"
                                }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="badge light"
                                :class="
                                  data.minimum_price == 1
                                    ? 'badge-success'
                                    : 'badge-danger'
                                "
                                >{{
                                  data.minimum_price == 1 ? "Yes" : "No"
                                }}</span
                              >
                            </td>
                            <td>
                              <span
                                class="badge light"
                                :class="
                                  data.start_price == 1
                                    ? 'badge-success'
                                    : 'badge-danger'
                                "
                                >{{
                                  data.start_price == 1 ? "Yes" : "No"
                                }}</span
                              >
                            </td>
                          </tr>

                          <div v-if="!All_Products" class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </tbody>
                      </table>

                      <!-- <div class="d-flex justify-content-end mt-3">
                        <router-link to="/add-product"
                          ><button class="filter-btn">
                            Create new product
                          </button></router-link
                        >
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--**********************************
      Content body end
  ***********************************-->
    </div>
    <!--**********************************
  Main wrapper end
***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex'
// @ is an alias to /src

export default {
  name: "Products",
  data() {
    return {
      productsDetails: "",
      value: true,
      search: "",
      filterOptions: {
        accomodation: false,
        conference_room: false,
        transportation: false,
        meals: false,
        activity: false,
        others: false
      },
    };
  },
  created(){
    this.$store.dispatch("GetProducts").then((res) => {
      console.log('then geting', res);
      this.value = false;
    })
  },
  computed: {
    ...mapState({
      All_Products: (state) => state.products.allProducts_details,
    }),

    lan(){
      return this.$store.getters.whichLanguage
    },
 
    filtered() {
      // var self = this;
      let filteredData = this.All_Products;
      // Apply filter based on status
      if (this.filterOptions.accomodation || this.filterOptions.conference_room || this.filterOptions.transportation || this.filterOptions.meals || this.filterOptions.activity || this.filterOptions.others) {
        filteredData = filteredData.filter(product => {
          if (this.filterOptions.accomodation && product.product_family === 'Accomodation') return true;
          if (this.filterOptions.conference_room && product.product_family === 'Conference room') return true;
          if (this.filterOptions.transportation && product.product_family === 'Transportation') return true;
          if (this.filterOptions.meals && product.product_family === 'Meals') return true;
          if (this.filterOptions.activity && product.product_family === 'Activity') return true;
          if (this.filterOptions.others && product.product_family === 'Others') return true;
          return false;
        });
      }

      // Apply search filter
      if (this.search.trim() !== '') {
        const searchTerm = this.search.toLowerCase();
        filteredData = filteredData.filter(product => 
          product?.product_name?.toLowerCase().includes(searchTerm) ||
          product?.product_description?.toLowerCase().includes(searchTerm) ||
          product?.type?.toLowerCase().includes(searchTerm) ||
          product?.product_family?.toLowerCase().includes(searchTerm)
        );
      }
      return filteredData;
    },
  },
  mounted() {
     // for top load page
     window.scrollTo(0, 0);
     
    // axios
    //   .get(axios.defaults.baseURL + "products", {
    //     headers: {
    //       Authorization: "Bearer " + localStorage.getItem("token"),
    //     },
    //   })
    //   .then((res) => {
    //     console.log("res", res);
    //     this.productsDetails = res.data.data;
    //     this.value = false;
    //     console.log("productsDetails", this.productsDetails);
    //   })
    //   .catch((err) => {
    //     console.log("err", err);
    //     this.value = false;
    //     this.$store.dispatch("checkErrorAndSendToast", [
    //       err.response,
    //       "error",
    //     ]);
    //   });
  },
  methods: {
    edit(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Product", params: { id: id }, });
    },
  },
};
</script>
