<template>
  <div id="myModal" class="modal fade">
    <div class="modal-dialog modal-confirm_1">
      <div class="modal-content">
        <div class="modal-header flex-column">
          <div v-if="check_for != 'Book'" class="icon-box">
            <i class="material-icons">&#xE5CD;</i>
          </div>
          <h4 v-if="check_for != 'Book'" class="modal-title w-100">{{ lan.admin[4].Opportunity_page[0].Are_you_sure }}?</h4>
          <h4 v-else class="modal-title w-100">{{ lan.admin[4].Opportunity_page[0].Are_you_sure_want_book_this_venue }}</h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div v-if="check_for != 'Book'" class="modal-body">
          <p>
            {{ lan.admin[4].Opportunity_page[0].Do_you_really_want_to_delete_these_records }}
          </p>
        </div>
        <div v-else class="modal-body">
          <p>
            {{ lan.quotePrev.Do_you_really_want_to_book_this_venue }}
          </p>
        </div>
        <div class="modal-footer justify-content-center">
          <button id="deletemodal" type="button" class="btn btn-secondary" data-dismiss="modal">
            {{ lan.admin[4].Opportunity_page[0].Cancel }}
          </button>

          <button v-if="check_for == 'Book'" type="button" class="btn btn-primary"  @click="deletef">{{lan.quotePrev.Book}}</button>
          <button v-else type="button" class="btn btn-danger"  @click="deletef">{{ lan.admin[4].Opportunity_page[0].Delete }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteModal",
  props:{
    id:{type: [String, Number],},
    check_for:{type: [String],},
    value:{type: [String, Object],},
  },
  data() {
    return {};
  },
  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
  },
  methods: {
    deletef() {
      if(this.check_for == 'Book'){
        console.log("model value --",this.value)
        this.$emit("book_Venvue",this.value);
      }else{
        this.$emit("delete");
      }
    },
  },
};
</script>
<style >
.modal-confirm_1 {
  color: #636363;
  width: 400px;
}
.modal-confirm_1 .modal-content {
  padding: 20px;
  border-radius: 5px;
  border: none;
  text-align: center;
  font-size: 14px;
}
.modal-confirm_1 .modal-header {
  border-bottom: none;
  position: relative;
}
.modal-confirm_1 h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 -10px;
}
.modal-confirm_1 .close {
  position: absolute;
  top: -5px;
  right: -2px;
}
.modal-confirm_1 .modal-body {
  color: #999;
}
.modal-confirm_1 .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 25px;
}
.modal-confirm_1 .modal-footer a {
  color: #999;
}
.modal-confirm_1 .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}
.modal-confirm_1 .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;
}
.modal-confirm_1 .btn,
.modal-confirm_1 .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}
.modal-confirm_1 .btn-secondary {
  background: #c1c1c1;
}
.modal-confirm_1 .btn-secondary:hover,
.modal-confirm_1 .btn-secondary:focus {
  background: #a8a8a8;
}
.modal-confirm_1 .btn-danger {
  background: #f15e5e;
}
.modal-confirm_1 .btn-danger:hover,
.modal-confirm_1 .btn-danger:focus {
  background: #ee3535;
}
</style>
