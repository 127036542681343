<template>
    <!--**********************************
              Main wrapper start
          ***********************************-->
    <div id="main-wrapper">
      <!--**********************************
        Content body start
    ***********************************-->
  
      <div class="container h-100" style="margin-top: 100px;">
        <div class="row justify-content-center h-100 align-items-center">
          <div class="col-md-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <h4 class="text-center mb-4">
                      Reset Password
                    </h4>
                      <div class="form-group">
                        <label class="mb-3"><strong>Old Password</strong>
                          <span>*</span>
                          <span style="color:red;" v-if="v$.current_password.$error">
                          * {{ v$.current_password.$errors[0].$message }}
                        </span>
  
                        </label>
  
                        <div class="pass-code">
                          <input 
                            autocomplete="one-time-code"
                            type="password"
                            class="form-control typeChange1"
                            v-model="state.current_password"
                          />
                          <span @click="typeChange1()"
                            toggle="#password-field"
                            class="fa fa-fw fa-eye field-icon toggle-password"
                          ></span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="mb-3"><strong>New Password</strong>
                          <span>*</span>
                          <span style="color:red;" v-if="v$.new_password.$error">
                          * {{ v$.new_password.$errors[0].$message }}
                        </span>
  
                        </label>
  
                        <div class="pass-code">
                          <input
                            autocomplete="one-time-code"
                            type="password"
                            id="password-field"
                            class="form-control typeChange2"
                            v-model="state.new_password"
                          />
                          <span
                          @click="typeChange2()"
                            toggle="#password-field"
                            class="fa fa-fw fa-eye field-icon toggle-password"
                          ></span>
                        </div>
                      </div>
                      <div class="form-group">
                        <label class="mb-3"><strong>Confirm Password</strong>
                          <span>*</span>
                          <span style="color:red;" v-if="v$.confirm_password.$error">
                          * {{ v$.confirm_password.$errors[0].$message }}
                        </span>
  
                        </label>
                        <div class="pass-code">
                          <input
                            autocomplete="one-time-code"
                            type="password"
                            id="password-field-new"
                            class="form-control typeChange3"
                            v-model="state.confirm_password"
                          />
                          <span
                          @click="typeChange3()"
                            toggle="#password-field-new"
                            class="fa fa-fw fa-eye field-icon toggle-password-new"
                          ></span>
                        </div>
                      </div>
                      <div
                        class="form-row d-flex justify-content-between mt-4 mb-2"
                      >
                      </div>
                      <div class="text-center">
                        <button type="submit" @click="submit" class="btn btn-primary btn-block">
                      <div class="lds-ring" v-show="loading">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-show="loadingS">Reset Password</span>
                        </button>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--**********************************
        Content body end
    ***********************************-->
    </div>
  </template>
  
  <script>
  import axios from "axios";
  
  import useValidate from '@vuelidate/core'
  import { required, minLength, sameAs } from '@vuelidate/validators'
  import { reactive, computed } from 'vue'
  
  
  
  export default {
    name: "Admin Reset Password",
    setup() {
      const state = reactive({
        // errors: [],
        // success: [],
        email: '',
        current_password: '',
        new_password: '',
        confirm_password: '',
      });
  
      const rules = computed(() => {
        return {
          email: { },
          current_password: { required },
          new_password: { required, minLength: minLength(8) },
          confirm_password: { required, sameAs: sameAs(state.new_password) },
        }
      });
  
      const v$ = useValidate(rules, state)
  
      return { state, v$ }
    },
  
    data() {
      return {
        errors: [],
        success: [],
        user: '',
        loading: false,
        loadingS: true,
      };
    },
  
    mounted() {
  
      this.user = JSON.parse(localStorage.getItem("user"));
      this.state.email = this.user['email'];
  
      let inputElements = document.getElementsByClassName("form-control");
      for (var i = 0; i < inputElements.length; i++) {
        inputElements[i].addEventListener("keypress", this.handleKeyPress);
      }
  
    },
    methods:{
  
      handleKeyPress(event) {
      if (event.key === "Enter") {
        event.preventDefault();
        this.submit().click();
      }
     },
  
      submit()
      {
        // console.log(this.state.email)
        // console.log(this.state.current_password)
        // console.log(this.state.new_password)
        // console.log(this.state.confirm_password)
        this.v$.$validate() 
  
        if (!this.v$.$error) { 
          console.log(this.state)
          this.loading = true 
          this.loadingS = false
          var formData = new FormData();
        formData.append("email", this.state.email);
        formData.append("current_password", this.state.current_password);
        formData.append("new_password", this.state.new_password);
        formData.append("confirm_password", this.state.confirm_password);
  
        axios
          .post(axios.defaults.baseURL + "reset-password", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("res", res);
            this.loading = false
            this.loadingS = true
            this.AddToast(res.data.message," Success ",'success_1');
            this.$router.push({ path: "/login" });
          })
          .catch((err) => {
              console.log("err", err);
              this.loading = false
                this.loadingS = true
              this.AddToast(err.response.data.message , 'Faild','error_1');
          });
        } else {
          console.log(".")
          this.AddToast(this.lan.Please_Fill_Required_Details," info ",'info_1')
        }
  
       
      },
  
      AddToast(message,title,type) {
        this.$store.commit("addToast", {
          title: title,
          type: type,
          message: message,
        });
        setTimeout(() => {
              this.dismissToast(title);
            }, 5000);
      },
      dismissToast(title) {
        this.$store.commit("clearToast", title);
      },
  
      typeChange1(){
        let typeChange = document.querySelector(".typeChange1")
        if(typeChange.type == "password"){
          typeChange.type = "text"
        }else{
          typeChange.type = "password"
        }
      },
      typeChange2(){
        let typeChange = document.querySelector(".typeChange2")
        if(typeChange.type == "password"){
          typeChange.type = "text"
        }else{
          typeChange.type = "password"
        }
      },
      typeChange3(){
        let typeChange = document.querySelector(".typeChange3")
        if(typeChange.type == "password"){
          typeChange.type = "text"
        }else{
          typeChange.type = "password"
        }
      }
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .pass-code {
    position: relative;
  }
  
  .pass-code span {
    position: absolute;
    right: 10px;
    top: 18px;
  }
  </style>
  