<template>
  <div id="main-wrapper">
        <!--**********************************
            Prelimnary header start
          ***********************************-->
         <!-- <prelimnaryHeader /> -->
         <!--**********************************
         Prelimnary header end
         ***********************************-->
         
         <section class="banner">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="content">
              <div class="d-flex justify-content-center">
                <div class="icon">
                  <i class="fa-solid fa-comment"></i>
                </div>
              </div>
              <h1>{{ lan.partners[2].quote_page[0].Preliminary_Response }}</h1>
              <h2>
                {{
                  lan.partners[2].quote_page[0].Thank_you_for_the_opportunity_of_preliminsry_booking
                }}
              </h2>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="banner-images">
              <img
                src="https://d32ijn7u0aqfv4.cloudfront.net/wp/wp-content/uploads/20181025162721/woman-making-online-credit-card-payment_PL17125_7-Simple-Expert-Approved-Ways-To-Boost-Your-Credit-Score_1435403_st.jpg"
                class="img-fluid"
                alt="img not found"
              />
            </div>
          </div>
        </div>
      </div>
    </section>


    <div class="content-body mainPrelim">
      <div class="container-fluid" style="padding-bottom: 40px;">
        <div
          class="form-head mb-sm-3 mb-3 d-flex flex-wrap align-items-center pt-3"
        >
          <h2 class="font-w600 title mb-2 mr-auto">{{ lan.partners[2].quote_page[0].Preliminary_booking }}</h2>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-header border-0 pb-0 justify-content-end">
                    <div class="card-body px-0">
                      <div class="table-responsive">
                        <table class="text-center tr-rounded order-tbl">
                          <thead>
                            <tr>
                              <th>{{ lan.partners[2].quote_page[0].Date }}</th>
                              <th>{{ lan.partners[2].quote_page[0].Customer }}</th>
                              <th>{{ lan.partners[2].quote_page[0].Participants }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{{ date_of_conference_start + ' to ' + date_of_conference_end }}</td>
                              <td>{{ account_name }}</td>
                              <td>{{ number_of_participants }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="table-responsive">
                        <table class="tr-rounded order-tbl t-ble w-auto">
                          <thead>
                          </thead>
                          <tbody>
                            <tr>
                              <th for="yes" >{{ lan.partners[2].quote_page[0].We_have_made_a_preliminary_booking }}</th>
                              <th class="text-center px-4">
                                <input
                                  type="radio"
                                  class="my-2 radio"
                                  value="We have made a preliminary booking"
                                  v-model="response"
                                  name=""
                                  id="yes"
                                  :disabled="disabled_button"
                                />
                              </th>
                            </tr>
                            <tr>
                              <th for="no">{{ lan.partners[2].quote_page[0].We_have_not_made_a_preliminary_booking }}</th>
                              <th class="text-center px-4">
                                <input
                                  type="radio"
                                  class="my-2 radio"
                                  value="We have not made a preliminary booking"
                                  name=""
                                  id="no"
                                  v-model="response"
                                  :disabled="disabled_button"
                                />
                              </th>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                        <textarea v-model="additional_information" cols="10" rows="6" :disabled="disabled_button">
                          {{ lan.partners[2].quote_page[0].Additional_information }}
                        </textarea>

                      <div v-if="!disabled_button" class="button d-flex justify-content-end mt-4">
                        <button @click="saveBook()" class="search-btn" :disabled="!response">
                          <div
                              class="lds-ring"
                              v-show="value2"
                            >
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            
                            <span v-show="value"> 
                               {{ lan.partners[2].quote_page[0].Submit }}
                              </span>
                          </button>
                        <!-- <a href="#" class="filter-btn">Cancel</a> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a
      id="clickModal"
      href="#myModal"
      class="trigger-btn"
      data-toggle="modal"
      style="display: none"
    >
      <i class="fa fa-trash btn btn-danger shadow btn-xs sharp"></i>
    </a>

    <confirmModel />
    <div class="footer">
      <div class="copyright">
        <p>Copyright © 2023 &amp; Konferensbokarna</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import prelimnaryHeader from "../../components/prelimnaryHeader.vue";
import confirmModel from "../../components/confirmModelForPreliminary.vue";

export default {
  name: "Preliminary Booking",
  components: {confirmModel},
  data() {
    return {
      customer_account_id: "",
      response: "",
      additional_information: "",
      number_of_participants: "",
      date_of_conference_start: "",
      date_of_conference_end: "",
      account_id: "",
      contact_id: "",
      account_name: "",
      opportunity_id: this.$route.params.id,
      quote_id: this.$route.params.qID,
      bidder_id: this.$route.params.bidderID,
      booking_id: this.$route.params.bookingID,
      value: true,
      value2: false,
      respnse_booking: null,
      disabled_button: false,
      // value4: true,
    };
  },
  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },

  // created() {
  //   this.$store.dispatch("tryAutoLogin");
  //   if (!this.$store.getters.isAdmin) {
  //     alert(
  //       "You can't access Admin Portal. If you are Admin first logout then Login Admin portal."
  //     );
  //     this.$router.back();
  //   }

  //   console.log("language 1", this.$store.getters.whichLanguage);
  // },
  mounted() {
    console.log('bidder_id', this.bidder_id);
    console.log('this.$route', this.$route);
      axios
        .get(axios.defaults.baseURL + "get-preliminary-booking/" + this.booking_id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res_booking) => {
          console.log("res bidder-", res_booking);
          // if(res_booking.data.data[0].response != null){
          //   var modal = document.getElementById("clickModal");
          //   modal.click();
          //   return
          // }
          if(res_booking.data.data[0].response){
            this.disabled_button = true
          }
          this.response = res_booking.data.data[0].response
          this.additional_information = res_booking.data.data[0].additional_information

          // *********START********Account by ID of customer from oppo****************
        axios
          .get(
            axios.defaults.baseURL + "account/outside-partner/" + res_booking.data.data[0].account_id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
          console.log("res account-", res);
            console.log(res);
            this.contact_id = res.data.data[0].primary_contact;
          })
          .catch((err) => {
            console.log(err);
          });
          // ********END*********Account by ID of customer from oppo****************


        }).catch((err) =>{
          console.log(err)
        })
        // *********START********Opportunity by ID ****************
    axios
      .get(
        axios.defaults.baseURL + "opportunity/outside-partner/" + this.opportunity_id,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log("res ooppo-", res);
        // this.date = res.data.data[0].date
        // if(res.data.data[0].self == 1){
        this.customer_email = res.data.data[0].email;
        this.number_of_participants = res.data.data[0].number_of_participants;
        // this.free_text_message = res.data.data[0].
        this.date_of_conference_start = res.data.data[0].date.slice(0, 10);
        this.date_of_conference_end = res.data.data[0].date.slice(14, 24);
        this.customer_account_id = res.data.data[0].account_id;
        // this.contact_id = res.data.data[0].contact_id;

        // *********START********Account by ID of customer from oppo****************
        axios
          .get(
            axios.defaults.baseURL + "account/outside-partner/" + res.data.data[0].account_id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
          console.log("res account-", res);
            console.log(res);
            this.account_name = res.data.data[0].name;
          })
          .catch((err) => {
            console.log(err);
          });
          // ********END*********Account by ID of customer from oppo****************
      })
      .catch((err) => {
        console.log("err opportunity", err);
        if (err.response.statusText == "Unauthorized") {
          this.$router.push("/login").then(() => {
            this.$router.go();
          });
        }
      });
      // *********END********Opportunity by ID ****************

      // *********START********Quote by ID ****************
      axios
      .get(axios.defaults.baseURL + "outside-partner/quote/" + this.quote_id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.account_id = res.data.data[0].account_id
        console.log("res quote-", res);
      }).catch((err) =>{
        console.log(err)
      })
       // *********END********Quote by ID ****************
  },
  methods: {
    saveBook(){
      this.value = false
      this.value2 = true

      // var modal = document.getElementById("clickModal");
      // modal.click();
      // return

      var formData = new FormData();
      formData.append("quote_id", this.quote_id);
      formData.append("opportunity_id", this.opportunity_id);
      formData.append("account_id", this.account_id);
      formData.append("contact_id", this.contact_id);
      formData.append("bidder_id", this.bidder_id);
      formData.append("preliminary_booking_id", this.booking_id);
      formData.append("customer_account_id", this.customer_account_id);
      formData.append("response", this.response);
      formData.append("additional_information", this.additional_information);
      if(this.response == 'We have made a preliminary booking'){
        formData.append("status", 'Preliminary Booked');
      }else{
        formData.append("status", 'Preliminary Cancelled');
      }
      console.log('formdata',formData);
      axios.post(axios.defaults.baseURL + "preliminary-booking",formData).then((res) => {
        this.value = true
      this.value2 = false
        console.log(res);
        this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
        var modal = document.getElementById("clickModal");
        modal.click();
      }).catch((err) => {
        this.value = true
      this.value2 = false
      this.$store.dispatch("checkErrorAndSendToast", [err.response,"error",]);
        console.log(err);
      })
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mainPrelim{
  max-width: 1200px;
  margin: auto;
  padding-top: 0px;
}
.table-responsive .t-ble .radio{
  scale: 1.3;
}
</style>
