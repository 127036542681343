<template>
  <div>
    <!--**********************************
  Main wrapper start
***********************************-->
    <div id="main-wrapper">
      <!--**********************************
      Content body start
  ***********************************-->
      <div class="content-body pt-0">
        <div class="container-fluid">
          <div class="form-head d-flex flex-wrap align-items-center pt-3">
            <h2 class="font-w600 title mb-2 mr-auto">
              {{ lan.admin[14].Contact[0].All_Contacts }}
            </h2>

            <div class="d-flex justify-content-end mt-3">
              <router-link to="/add-contact"
                ><button class="filter-btn up-btn">
                  {{ lan.admin[14].Contact[0].Create_new_contact }}
                </button></router-link
              >
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12 mar-bo">
              <div class="card">
                <div
                  class="card-header pb-2 d-block d-sm-flex flex-wrap border-0 justify-content-end"
                >
                  <div class="button justify-content-end d-flex">
                    <!-- <a href="#"><button class="search-btn">Search</button></a> -->
                    <input
                      type="search"
                      name="search"
                      class="init new-init ml-3 w-new"
                      placeholder="Search"
                      v-model="search"
                    />
                    <div class="drop-down">
                      <button
                        class="filter-btn mb-0 dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ lan.admin[3].Opportunity[0].Filter_view }}
                      </button>
                      <div
                        class="dropdown-menu mt-3"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="admin"
                            value="admin"
                            v-model="filterOptions.admin"
                          />
                          <label for="admin">
                            Admin</label
                          ><br />
                        </a>
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="customer"
                            value="customer"
                            v-model="filterOptions.customer"
                          />
                          <label for="customer">
                            {{ lan.admin[12].Accounts[0].Customer }}</label
                          ><br />
                        </a>
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="partner"
                            value="partner"
                            v-model="filterOptions.partner"
                          />
                          <label for="partner">
                            {{ lan.admin[12].Accounts[0].Partner }}</label
                          ><br />
                        </a>
                        <!-- <a class="dropdown-item" href="#">
                          <input
                            type="radio"
                            id="html1"
                            name="fav_language1"
                            value="Closed"
                            v-model="search"
                          />
                          <label for="vehicle1">{{ lan.admin[3].Opportunity[0].Closed }}</label><br />
                        </a> -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-body tab-content">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>{{ lan.admin[14].Contact[0].First_name }}</th>
                            <th>{{ lan.admin[14].Contact[0].Last_name }}</th>
                            <th>{{ lan.admin[14].Contact[0].Phone }}</th>
                            <th>{{ lan.admin[14].Contact[0].Email }}</th>
                            <th>{{ lan.admin[14].Contact[0].Company }}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in filtered"
                            :key="index"
                            @click="edit(data.id)"
                            class="pointer"
                          >
                            <!-- <tr v-if="data.roles == 1" > -->
                              <td>{{ index+1 }}</td>
                            <td>{{ data.first_name }}</td>
                            <td>{{ data.last_name }}</td>
                            <td>{{ data.phone }}</td>
                            <td>{{ data.email }}</td>
                            <td
                              v-if="
                                data.account_detail &&
                                data.account_detail.length
                              "
                            >
                              {{ data.account_detail[0].name }}
                            </td>
                            <td v-else-if="data.roles == '1'"><b>Admin</b></td>
                            <td v-else>
                              {{ lan.admin[14].Contact[0].Not_found }}
                            </td>
                            <!-- </tr> -->
                          </tr>
                          <div v-if="!All_Contacts_Details" class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </tbody>
                      </table>

                      <!-- <div class="d-flex justify-content-end mt-3">
                        <router-link to="/add-contact"
                          ><button class="filter-btn">
                            Create new contact
                          </button></router-link
                        >
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--**********************************
      Content body end
  ***********************************-->
    </div>
    <!--**********************************
  Main wrapper end
***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex/dist/vuex.cjs.js";
// import _ from 'lodash';

export default {
  name: "Contacts",
  data() {
    return {
      contactsDetails: [],
      value: true,
      search: "",
      filterOptions: {
        admin: false,
        customer: false,
        partner: false,
      },
    };
  },
  created(){
    this.$store.dispatch("GetContacts")
  },
  computed: {
    ...mapState({
      All_Contacts_Details: (state) => state.contacts.all_contacts_Details,
    }),
    lan() {
      return this.$store.getters.whichLanguage;
    },

    filtered() {
      // var self = this;
      let filteredData = this.All_Contacts_Details;
      // Apply filter based on status
      if (this.filterOptions.customer || this.filterOptions.partner || this.filterOptions.admin) {
        filteredData = filteredData.filter((contact) => {
          if (this.filterOptions.admin && contact.roles === 1) return true;
          if (this.filterOptions.customer && contact.roles === 3) return true;
          if (this.filterOptions.partner && contact.roles === 2) return true;
          return false;
        });
      }

      // Apply search filter
      if (this.search.trim() !== "") {
        const searchTerm = this.search.toLowerCase();
        filteredData = filteredData.filter(
          (contact) =>
            contact?.first_name?.toLowerCase().includes(searchTerm) ||
            contact?.last_name?.toLowerCase().includes(searchTerm) ||
            contact?.email?.toLowerCase().includes(searchTerm) ||
            (contact?.account_detail.length != 0
              ? contact?.account_detail[0].name
                  ?.toLowerCase()
                  .includes(searchTerm) ||
                contact?.account_detail[0].venue_name
                  ?.toLowerCase()
                  .includes(searchTerm)
              : false) || 
              (contact?.default_language != '' ? contact?.default_language?.toLowerCase().includes(searchTerm) : '')
        );
      }
      console.log('filteredData !!:-', filteredData);
      return filteredData;
    },
  },
  // mounted() {
  //   // for top load page
  //   window.scrollTo(0, 0);

  //   axios
  //     .get(axios.defaults.baseURL + "contacts", {
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("token"),
  //       },
  //     })
  //     .then((res) => {
  //       console.log("res", res);

  //       this.contactsDetails = res.data.data;

  //       this.value = false;
  //       console.log("contactsDetails", this.contactsDetails);
  //     })
  //     .catch((err) => {
  //       this.value = false;
  //       this.$store.dispatch("checkErrorAndSendToast", [err.response, "error"]);
  //     });

  //   // this.filtered()
  // },

  methods: {
    edit(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Contact", params: { id: id } });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
