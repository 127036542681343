<template>
  <div>
    <!--**********************************
  Main wrapper start
  ***********************************-->
    <div id="main-wrapper">
      <!--**********************************
      Content body start
  ***********************************-->
      <div class="content-body pt-0 mb-5">
        <div class="container-fluid">
          <div
            class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
          >
            <h2 class="font-w600 title mb-2 mr-auto">
              {{ lan.admin[1].Leads[0].Lead }}
            </h2>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div
                  class="card-header pb-2 d-block d-sm-flex flex-wrap border-0"
                >
                  <div class="mb-3">
                    <h4 class="fs-20 text-black">
                      {{ lan.admin[1].Leads[0].Convert_lead_to_opportunity }}
                    </h4>
                  </div>
                </div>

                <div class="card-body p-4">
                  <div>
                    <div class="row">
                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].Submitted
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="date"
                          name="text"
                          v-model="state.submitted"
                          :class="v$.submitted.$error ? 'input-error' : ''"
                          readonly
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].First_name
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="text"
                          name="text"
                          v-model="state.first_name"
                          :class="v$.first_name.$error ? 'input-error' : ''"
                          placeholder="First name"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].Last_name
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="text"
                          name="text"
                          v-model="state.last_name"
                          :class="v$.last_name.$error ? 'input-error' : ''"
                          placeholder="Last name"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].Business
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="text"
                          name="text"
                          v-model="state.company"
                          :class="v$.company.$error ? 'input-error' : ''"
                          placeholder="Company"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].E_mail
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="email"
                          name="email"
                          v-model="state.email"
                          :class="v$.email.$error ? 'input-error' : ''"
                          placeholder="E-mail"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].Phone }}Phone<span
                            >*</span
                          ></label
                        ><br />
                        <input
                          type="text"
                          placeholder="Phone number"
                          v-model="telephone"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].Type_of_conference
                          }}<span>*</span></label
                        ><br />
                        <select
                          name=""
                          id=""
                          v-model="state.type_of_conference"
                          :class="
                            v$.type_of_conference.$error ? 'input-error' : ''
                          "
                          :disabled="status == 0 || status == '0' ? false: true"
                        >
                          <option value="" disabled selected>
                            Please select one
                          </option>
                          <option value="Conference with overnight stay">
                            Conference with overnight stay
                          </option>
                          <option value="Day conference">Day conference</option>
                        </select>
                      </div>

                      <div class="col-md-4 input">
                        <label>
                          {{ lan.admin[2].Leads_page[0].Date_of_the_conference
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="date"
                          name="Phone number"
                          v-model="date_of_conference_start"
                          placeholder="Number of participants in shared double room"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <!-- <span class="p-2">TO</span> -->
                        <label
                          >{{
                            lan.admin[2].Leads_page[0]
                              .End_Date_of_the_conference
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="date"
                          name="Phone number"
                          v-model="date_of_conference_end"
                          placeholder="Number of participants in shared double room"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].Time_arrival
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="time"
                          name="text"
                          placeholder="Time arrival"
                          v-model="arrival_time"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].Time_departure
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="time"
                          name="text"
                          placeholder="Time departure"
                          v-model="departure_time"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].Number_of_participants
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="number"
                          name="Phone number"
                          v-model="state.number_of_participants"
                          :class="
                            v$.number_of_participants.$error
                              ? 'input-error'
                              : ''
                          "
                          min="0"
                          placeholder="Number of participants"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{
                            lan.admin[2].Leads_page[0]
                              .Number_of_people_in_single_room
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="number"
                          name="Phone number"
                          v-model="number_of_participants_in_single_room"
                          placeholder="Number of participants in single rooms"
                          min="0"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{
                            lan.admin[2].Leads_page[0]
                              .Number_of_people_in_shared_double_room
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="number"
                          name="Phone number"
                          v-model="
                            state.number_of_participants_in_shared_double_room
                          "
                          :class="
                            v$.number_of_participants_in_shared_double_room
                              .$error
                              ? 'input-error'
                              : ''
                          "
                          min="0"
                          placeholder="Number of participants in shared double room"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{
                            lan.admin[2].Leads_page[0]
                              .Do_you_accept_shared_double_room
                          }}<span>*</span></label
                        ><br />
                        <select
                          v-model="state.do_you_accept_shared_double_room"
                          :disabled="status == 0 || status == '0' ? false: true"
                        >
                          <option value="" disabled selected>
                            Please select one
                          </option>
                          <option value="1">Yes</option>
                          <option value="0">No</option>
                        </select>
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{
                            lan.admin[2].Leads_page[0]
                              .Conference_room_for_number_of_participants
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="number"
                          name="Phone number"
                          v-model="number_of_participants_in_conference_room"
                          placeholder="Number of participants in single rooms"
                          min="0"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].Desired_furnishing
                          }}<span>*</span></label
                        ><br />
                        <select name="" id="" v-model="seating_type" :disabled="status == 0 || status == '0' ? false: true">
                          <option value="" disabled selected>
                            Please select one
                          </option>

                          <option value="Cinema Seating">Cinema Seating</option>
                          <option value="U-shaped table">U-shaped table</option>
                          <option value="Boardroom">Boardroom</option>
                          <option value="School seating">School Seating</option>
                          <option value="Islands">Islands</option>
                          <option value="Don't know at the moment">
                            Don't know at the moment
                          </option>
                        </select>
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].Region
                          }}<span>*</span></label
                        ><br />
                        <select v-model="region" id="" :disabled="status == 0 || status == '0' ? false: true">
                          <option value="" disabled selected>
                            Please select one
                          </option>
                          <option value="1">Stockholm</option>
                          <option value="2">Gothemburg</option>
                          <option value="3">Malmö</option>
                          <!-- <option value="Annat område">Annat område</option> -->
                          <option value="4">Other area</option>
                        </select>
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].City
                          }}<span>*</span></label
                        ><br />

                        <input
                          type="text"
                          v-model="city"
                          placeholder="Please select one"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].Prices_should_be
                          }}<span>*</span></label
                        >
                        <br />
                        <input
                          type="radio"
                          id="html"
                          class="redio"
                          v-model="price_wanted"
                          value="excl vat"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                        &nbsp;<label for="html">{{
                          lan.admin[2].Leads_page[0].excl_VAT
                        }}</label>
                        &nbsp;<input
                          type="radio"
                          id="css"
                          class="redio"
                          v-model="price_wanted"
                          value="incl vat"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                        &nbsp;<label for="css">{{
                          lan.admin[2].Leads_page[0].inc_VAT
                        }}</label>
                      </div>

                      <div class="col-md-4 input">
                        <label
                          >{{ lan.admin[2].Leads_page[0].Contact_me_by
                          }}<span>*</span></label
                        ><br />
                        <input
                          type="radio"
                          id="html"
                          class="redio"
                          v-model="contact_me_by"
                          value="Phone"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                        &nbsp;<label for="html">{{
                          lan.admin[2].Leads_page[0].Phone
                        }}</label>
                        &nbsp;<input
                          type="radio"
                          id="css"
                          class="redio"
                          v-model="contact_me_by"
                          value="Email"
                          :disabled="status == 0 || status == '0' ? false: true"
                        />
                        &nbsp;<label for="css">{{
                          lan.admin[2].Leads_page[0].Email
                        }}</label>
                      </div>
                    </div>

                    <label
                      >{{ lan.admin[2].Leads_page[0].Message
                      }}<span>*</span></label
                    ><br />
                    <textarea
                      name=""
                      id=""
                      class="textarea"
                      rows="5"
                      v-model="free_text_message"
                      spellcheck="false"
                      :disabled="status == 0 || status == '0' ? false: true"
                    >
Message</textarea
                    >
                    <div class="button d-flex justify-content-end mt-4 divv">
                      <button class="filter-btn conv-btn mb-3" @click="convert('convert')">
                        <div class="lds-ring" v-show="loading">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-show="loadingS">
                        {{ lan.admin[2].Leads_page[0].Convert_to_opportunity }}</span>
                      </button>
                      <button class="search-btn disc-btn ml-3" @click="convert('discard')">
                        <div class="lds-ring" v-show="loading1">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-show="loadingS1">
                              {{ lan.admin[2].Leads_page[0].Discard_lead }}</span>
                      </button>
                      <router-link to="/leads">
                        <button class="filter-btn up-btn">
                          {{ lan.admin[2].Leads_page[0].Cancel }}
                        </button></router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--**********************************
      Content body end
  ***********************************-->
    </div>
    <!--**********************************
  Main wrapper end
  ***********************************-->
  </div>
</template>

<script>
import axios from "axios";

import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, computed } from "vue";
// @ is an alias to /src

export default {
  name: "Convert Lead",
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      submitted: "",
      first_name: "",
      last_name: "",
      company: "",
      email: "",
      telephone: "",
      type_of_conference: "",
      number_of_participants: "",
      number_of_participants_in_shared_double_room: "",
      do_you_accept_shared_double_room: "",
    });

    const rules = computed(() => {
      return {
        submitted: { required },
        first_name: { required },
        last_name: { required },
        email: { required, email },
        company: { required },
        type_of_conference: { required },
        number_of_participants: { required },
        number_of_participants_in_shared_double_room: { required },
        do_you_accept_shared_double_room: { required },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      lead_details: "",
      telephone: "",
      arrival_time: "",
      departure_time: "",
      number_of_participants_in_single_room: "",
      date_of_conference_start: "",
      date_of_conference_end: "",
      number_of_participants_in_conference_room: "",
      conference_room_setup: "",
      seating_type: "",
      notice_regarding_shared_double_room: "",
      region_detail: "",
      region: "",
      city: "",
      free_text_message: "",
      contact_me_by: "",
      status: "",
      price_wanted: "",

      loadingS: true,
      loading: false,
      loadingS1: true,
      loading1: false,

      Id: localStorage.getItem("editID"),
    };
  },

  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
  },
  mounted() {
    axios
      .get(axios.defaults.baseURL + "lead/" + this.$route.params.id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log("res for leads", res);
        this.lead_details = res.data.data[0];

        this.state.submitted = res.data.data[0].submitted;
        this.state.first_name = res.data.data[0].first_name;
        this.state.last_name = res.data.data[0].last_name;
        this.state.email = res.data.data[0].email;
        this.state.company = res.data.data[0].company;
        this.state.type_of_conference = res.data.data[0].type_of_conference;
        this.state.number_of_participants =
          res.data.data[0].number_of_participants;
        this.state.number_of_participants_in_shared_double_room =
          res.data.data[0].number_of_participants_in_shared_double_room;
        this.state.do_you_accept_shared_double_room =
          res.data.data[0].do_you_accept_shared_double_room;
        this.number_of_participants_in_single_room =
          res.data.data[0].number_of_participants_in_single_room;
        // this.date_of_conference = res.data.data[0].date_of_conference.slice(0, 10);;
        this.date_of_conference_start =
          res.data.data[0].date_of_conference.slice(0, 10);
        this.date_of_conference_end = res.data.data[0].date_of_conference.slice(
          14,
          24
        );
        console.log("startDate", this.date_of_conference_start);
        console.log("endDate", this.date_of_conference_end);
        this.number_of_participants_in_conference_room =
          res.data.data[0].number_of_participants_in_conference_room;
        this.conference_room_setup = res.data.data[0].conference_room_setup;
        this.seating_type = res.data.data[0].seating_type;
        this.notice_regarding_shared_double_room =
          res.data.data[0].notice_regarding_shared_double_room;
        this.region = res.data.data[0].region;
        this.city = res.data.data[0].city;
        this.free_text_message = res.data.data[0].free_text_message;
        this.contact_me_by = res.data.data[0].contact_me_by;
        this.arrival_time = res.data.data[0].arrival_time;
        this.departure_time = res.data.data[0].departure_time;
        this.telephone = res.data.data[0].telephone;
        this.price_wanted = res.data.data[0].price_wanted;
        this.status = res.data.data[0].status;

        // ***********************
        axios
          .get(
            axios.defaults.baseURL + "region/" + res.data.data[0].region_id,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log("resion", res);
            this.region_detail = res.data.data[0].region;
          })
          .catch((err) => {
            console.log(err);
            this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
          });
        // ***************************
      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });
  },
  methods: {
    convert(status) {
      this.v$.$validate();
      if (!this.v$.$error) {
        if(status == "convert"){
          (this.loading = true), (this.loadingS = false);
        }else{
          (this.loading1 = true), (this.loadingS1 = false);
        }
        let formData = new FormData();
        formData.append("first_name", this.state.first_name);
        formData.append("last_name", this.state.last_name);
        formData.append("email", this.state.email);
        formData.append("company", this.state.company);
        formData.append("type_of_conference", this.state.type_of_conference);
        formData.append(
          "number_of_participants",
          this.state.number_of_participants
        );
        formData.append(
          "number_of_participants_in_shared_double_room",
          this.state.number_of_participants_in_shared_double_room
        );
        formData.append(
          "do_you_accept_shared_double_room",
          this.state.do_you_accept_shared_double_room
        );
        formData.append(
          "number_of_participants_in_single_room",
          this.number_of_participants_in_single_room
        );

        if (this.date_of_conference_start && this.date_of_conference_end) {
          formData.append(
            "date_of_conference",
            this.date_of_conference_start +
              " " +
              "to" +
              " " +
              this.date_of_conference_end
          );
        } else if (this.startdate_of_conference) {
          formData.append("date_of_conference", this.date_of_conference_start);
        }
        formData.append(
          "number_of_participants_in_conference_room",
          this.number_of_participants_in_conference_room
        );
        formData.append("conference_room_setup", this.conference_room_setup);
        formData.append("seating_type", this.seating_type);
        formData.append(
          "notice_regarding_shared_double_room",
          this.notice_regarding_shared_double_room
        );
        formData.append("region", this.region);
        formData.append("city", this.city);
        formData.append("free_text_message", this.free_text_message);
        formData.append("contact_me_by", this.contact_me_by);
        formData.append("arrival_time", this.arrival_time);
        formData.append("departure_time", this.departure_time);
        formData.append("telephone", this.telephone);
        formData.append("price_wanted", this.price_wanted);
        if(status == "convert"){
          formData.append("status", this.status);
        }else{
        formData.append("status", 2);
        }
        axios
          .post(
            axios.defaults.baseURL + "edit-lead/" + this.$route.params.id,
            formData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log("post for leads", res);
            // this.lead_details = res.data.data[0];
            if(status == "convert"){
              (this.loading = false),
              (this.loadingS = true),
              this.AddToast(this.lan.Lead_Updated_Successfully_and_Coverted, " Success ", "success_1");
              this.$router.push({
                name: "Create Opportunity",
                params: { id: this.$route.params.id },
              })
            }else{
              this.loading1 = false
              this.loadingS1 = true
              this.AddToast(this.lan.Lead_Discarded_Successfully, " Success ", "success_1");
              this.$router.push({
                name: "Leads",
              })

            }


          })
          .catch((err) => {
            console.log("err", err);
            this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
          });
      } else {
        return;
      }
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>
<style scoped>
.conv-btn{
  min-width: 250px;
}
.disc-btn{
  min-width: 170px;
}
@media screen and (max-width: 576px) {
  .divv{
    display: block !important;
  }
  .conv-btn, .disc-btn{
    min-width: auto;
    padding: 12px;
  }
  
}
</style>
