<template>
  <div>
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <div>
              <div class="input">
                <label>{{ lan.admin[11].Note_page[0].Subject}} {{ related_account }} <span>*</span> </label
                ><br />
                <input
                  type="text"
                  name="text"
                  v-model="state.subject"
                  :class="v$.subject.$error ? 'input-error' : ''"
                  :placeholder="lan.admin[11].Note_page[0].Subject"
                />

                <label class="mt-3">{{lan.admin[11].Note_page[0].Related_Account}}</label><br />
                <model-list-select
                  v-if="accountDetails"
                  :list="accountDetails"
                  style="
                    height: 43px;
                    border: 1px solid #ccc;
                    border-radius: 2px;
                  "
                  v-model="related_accountNote"
                  option-value="account_id"
                  :custom-text="getName"
                  placeholder="Please select one"
                >
                </model-list-select>
                <!-- <select v-model="related_accountNote">
                  <option
                    v-for="(data, index) in accountDetails"
                    :key="index"
                    :value="data.account_id"
                  >
                    {{ data.name }}
                  </option>
                </select> -->

                <label class="mt-3">{{lan.admin[11].Note_page[0].Related_Opportunity}}</label><br />
                <model-list-select
                  v-if="opportunitiesDetails"
                  :list="opportunitiesDetails"
                  style="
                    height: 43px;
                    border: 1px solid #ccc;
                    border-radius: 2px;
                  "
                  v-model="related_opportunityNote"
                  option-value="opportunity_id"
                  :custom-text="getNameOpportunity"
                  placeholder="Please select one"
                >
                </model-list-select>
                <!-- <select  v-model="related_opportunityNote">
                  <option
                    v-for="(data, index) in opportunitiesDetails"
                    :key="index"
                    :value="data.opportunity_id"
                  >
                    {{ data.account_detail.name }} {{ related_opportunityNote }}
                  </option>
                </select> -->

                <label class="mt-3">{{lan.admin[11].Note_page[0].Related_contact}}</label><br />
                <model-list-select
                  v-if="contactsDetails"
                  :list="contactsDetails"
                  style="
                    height: 43px;
                    border: 1px solid #ccc;
                    border-radius: 2px;
                  "
                  v-model="related_contactNote"
                  option-value="id"
                  :custom-text="getNameContact"
                  placeholder="Please select one"
                >
                </model-list-select>
                <!-- <select v-model="related_contactNote">
                  <option
                    v-for="(data, index) in contactsDetails"
                    :key="index"
                    :value="data.id"
                  >
                    {{ data.first_name }} {{ data.last_name }}
                  </option>
                </select> -->
              </div>

              <label class="mt-3">{{ lan.admin[11].Note_page[0].Comment}}</label><br />
              <textarea
                name=""
                id=""
                class="textarea"
                v-model="commentNote"
                rows="5"
                spellcheck="false"
                :placeholder="lan.admin[11].Note_page[0].Comment"
              ></textarea>
            </div>
          </div>
          <div class="button d-flex justify-content-end mt-4 pr-4">
            <!-- <button>
                          <button class="filter-btn">Save and new</button>
                        </button> -->
            <button class="search-btn ml-3" @click="saveNewNote()">
              <div class="lds-ring" v-show="loadingSaveNewNoteLoader">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <span v-show="loadingSaveNewNoteText">{{ lan.admin[11].Note_page[0].Save }}</span></button
            ><button
              ref="Close"
              id="cancelButton"
              class="filter-btn"
              data-dismiss="modal"
            >
            {{ lan.admin[11].Note_page[0].Cancel }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed } from "vue";

import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";

export default {
  name: "Add Note",
  components: { ModelListSelect },
  props: {
    accountDetails: {
      type: [String, Object, Array],
    },
    opportunitiesDetails: {
      type: [String, Object, Array],
    },
    contactsDetails: {
      type: [String, Object, Array],
    },
    related_opportunity: {
      type: [String, Number],
    },
    related_account: {
      type: [String, Number],
    },
    related_contact: {
      type: [Number, String],
    },
  },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      subject: "",
    });

    const rules = computed(() => {
      return {
        subject: { required },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      // add Note
      commentNote: "",
      related_accountNote: "",
      related_contactNote: "",
      related_opportunityNote: "",

      loadingSaveNewNoteText: true,
      loadingSaveNewNoteLoader: false,
    };
  },
  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
  },
  methods: {
    getName(item) {
      return `${item.name}`;
    },
    getNameOpportunity(item) {
      return `${item.account_detail.name}`;
    },
    getNameContact(item) {
      return `${item.first_name}' '${item.last_name}`;
    },
    saveNewNote() {
      this.v$.subject.$touch();
      // this.v$.$validate();
      // if (!this.v$.$error) {
      if (!this.v$.subject.$invalid) {
        this.loadingSaveNewNoteLoader = true;
        this.loadingSaveNewNoteText = false;
        var formData = new FormData();
        formData.append("subject", this.state.subject);
        formData.append("related_account", this.related_accountNote);
        formData.append("related_contact", this.related_contactNote);
        formData.append("related_opportunity", this.related_opportunityNote);
        formData.append("comment", this.commentNote);
        axios
          .post(axios.defaults.baseURL + "notes", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("res", res);
            //   this.note.push({
            //     note_id: res.data.data.note_id,
            //     subject: res.data.data.subject,
            //     related_account: res.data.data.related_account,
            //     related_contact: res.data.data.related_contact,
            //     related_opportunity: res.data.data.related_opportunity,
            //     comment: res.data.data.comment,
            //   });
            this.AddToast(res.data.message, " Success ", "success_1");
            this.loadingSaveNewNoteLoader = false;
            this.loadingSaveNewNoteText = true;
            this.$emit("data-updated-note", res.data.data);

            this.$refs.Close.click();
            // document.getElementById('cancelButton').click();
          })
          .catch((err) => {
            this.loadingSaveNewNoteLoader = false;
            this.loadingSaveNewNoteText = true;
            console.log("err", err);
            this.AddToast(err.response.data.message, "Faild", "error_1");
            this.$refs.Close.click();
          });
      } else {
        this.AddToast("Please Fill Required Details", " info ", "info_1");
        console.log("else", this.v$);
        return;
      }
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>
