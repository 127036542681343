<template>
<!-- Modal -->
<div  v-if="expandedImage" class="modal fade" id="exampleModal5" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" style="max-width: 1400px;">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Preview</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0" style="height: 600px;">
          <img class="card-img-top " :src="expandedImage" style="width: 100%;" />
          <div id="imgtext"></div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" class="btn btn-primary">Save changes</button>
        </div> -->
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    name: "Preview Image Modal",
    props: {
        expandedImage: {
        type: [String],
      },
    },
    data() {
      return {
    
      };
    },
  };
  </script>

<style scoped>
.modal .modal-body .card-img-top{
  height: 100%;
    object-fit: cover;
    object-position: top;
    transition: all 2s ease-in-out;
    border-radius: 0px;
} 
.modal .modal-body .card-img-top:hover{
  object-position: bottom;
}
</style>
  