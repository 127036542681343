<template>
  <div>
    <!--**********************************
Main wrapper start
***********************************-->
    <div id="main-wrapper">

      <!--**********************************
    Content body start
***********************************-->
      <div class="home">
        <div class="content-body pt-0">
          <div class="container-fluid">
            <div
              class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
            >
              <h2 class="font-w600 title mb-2 mr-auto">
                {{ lan.admin[1].Leads[0].New_lead_to_respond_to}}
              </h2>
            </div>

            <div class="row mar-bo">
              <div class="col-xl-12">
                <div class="card">
                  <div
                    class="card-header pb-2 d-block d-sm-flex flex-wrap border-0"
                  ></div>
                  <div class="card-body tab-content p-3">
                    <div class="tab-pane active show fade" id="monthly">
                      <div class="table-responsive">
                        <table
                          class="text-center bg-info-hover tr-rounded order-tbl"
                        >
                          <thead>
                            <tr>
                              <th>{{ lan.admin[1].Leads[0].Lead}}</th>
                              <th>{{ lan.admin[0].admin_portal[0].Contacts}}</th>
                              <th>{{ lan.admin[1].Leads[0].Date_Of_Conference}}</th>
                              <th>{{ lan.admin[0].admin_portal[0].Status}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(data, index) in leadsDetails"
                              :key="index"
                              @click="edit(data.lead_id)"
                              class="pointer"
                            >
                              <td>
                                {{ data.company }}
                              </td>
                              <td>
                                {{ data.first_name }} {{ data.last_name }}
                              </td>
                              <td>{{ data.date_of_conference }}</td>
                              <td>
                                <span
                                  class="badge light"
                                  :class="
                                    data.status == '1'
                                      ? 'badge-info' : data.status == '2' ? 'badge-danger': 'badge-success'
                                  "
                                  >{{
                                    data.status == "1" ? "Converted" : data.status == "2" ? 'Discarded': "New"
                                  }}</span
                                >
                              </td>
                            </tr>
                            <div class="lds-ellipsis" v-show="value">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--**********************************
    Content body end
***********************************-->
    </div>
    <!--**********************************
Main wrapper end
***********************************-->
  </div>
</template>

<script>
import axios from "axios";
// @ is an alias to /src

export default {
  name: "Leads",
  data() {
    return {
      leadsDetails: "",
      value: true,
    };
  },

  computed:{
    lan(){
      return this.$store.getters.whichLanguage
    }
  },
  mounted() {
     // for top load page
     window.scrollTo(0, 0);
     
    axios
      .get(axios.defaults.baseURL + "leads", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log("res", res);
        this.leadsDetails = res.data.data;
        this.value = false;
      })
      .catch((err) => {
        console.log("err", err);
        this.value = false;
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });
  },
  methods: {
    edit(id) {
      localStorage.setItem("editID", id);
      this.$router.push("/convert-lead-id=" + id);
    },
  },
};
</script>
<style></style>
