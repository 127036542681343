<template>
  <div>
    <!--**********************************
  Main wrapper start
***********************************-->
    <div id="main-wrapper">
      <!--**********************************
      Content body start
  ***********************************-->
      <div class="content-body pt-0 mb-5">
        <div class="container-fluid">
          <div
            class="form-head mb-3 d-flex flex-wrap align-items-center pt-3"
          >
            <h2 class="font-w600 title mb-2 mr-auto">{{ lan.admin[12].Accounts[0].All_accounts}}</h2>

            <div class="d-flex justify-content-end mt-3">
              <router-link to="/add-account"
                ><button class="filter-btn up-btn">
                  {{ lan.admin[12].Accounts[0].Create_new_account}}
                </button></router-link
              >
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="card">

                <div
                  class="card-header pb-2 d-block d-sm-flex flex-wrap border-0 justify-content-end"
                >
                  <div class="button justify-content-end d-flex">
                    <!-- <a href="#"><button class="search-btn">Search</button></a> -->
                    <input
                      type="search"
                      name="search"
                      class="init new-init ml-3 w-new"
                      placeholder="Search"
                      v-model="search"
                    />
                    <div class="drop-down">
                      <button
                        class="filter-btn mb-0 dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                      {{ lan.admin[3].Opportunity[0].Filter_view }}
                      </button>
                      <div
                        class="dropdown-menu mt-3"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="customer"
                            value="customer"
                            v-model="filterOptions.customer"
                          />
                          <label for="customer"> {{ lan.admin[12].Accounts[0].Customer}}</label><br />
                        </a>
                        <a class="dropdown-item" href="#">
                          <input
                            type="checkbox"
                            class="mr-3 sameInput"
                            id="partner"
                            value="partner"
                            v-model="filterOptions.partner"
                          />
                          <label for="partner"> {{ lan.admin[12].Accounts[0].Partner}}</label><br />
                        </a>
                        <!-- <a class="dropdown-item" href="#">
                          <input
                            type="radio"
                            id="html1"
                            name="fav_language1"
                            value="Closed"
                            v-model="search"
                          />
                          <label for="vehicle1">{{ lan.admin[3].Opportunity[0].Closed }}</label><br />
                        </a> -->
                      </div>
                    </div>
                  </div>
                </div>

                <div class="card-body tab-content">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th style="width:42%;">{{ lan.admin[12].Accounts[0].Company}}</th>
                            <th>{{ lan.admin[12].Accounts[0].Type}}</th>
                            <th>{{ lan.admin[12].Accounts[0].Phone}}</th>
                            <th>{{ lan.admin[12].Accounts[0].Account_owner}}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(data, index) in filtered"
                            :key="index"
                            @click="edit(data.account_id)"
                            class="pointer"
                          >
                            <td>{{ index+1 }}</td>
                            <td>{{ data.name }}</td>
                            <td>{{ data.type }}</td>
                            <td>{{ data.phone }}</td>
                            <td>{{ data.account_owner_detail.first_name }} {{ data.account_owner_detail.last_name }}</td>
                          </tr>
                          <div v-if="!All_Accounts_Details" class="lds-ellipsis">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </tbody>
                      </table>

                      <!-- <div class="d-flex justify-content-end mt-3">
                        <router-link to="/add-account"
                          ><button class="filter-btn">
                            Create new account
                          </button></router-link
                        >
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--**********************************
      Content body end
  ***********************************-->
    </div>
    <!--**********************************
  Main wrapper end
***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex'

export default {
  name: "account",
  data() {
    return {
      accountDetails: "",
      value: true,
      search: "",
      filterOptions: {
        customer: false,
        partner: false
      },
    };
  },
  created(){
    this.$store.dispatch("GetAccounts")
  },
  computed: {
    ...mapState({
      All_Accounts_Details: (state) => state.accounts.all_Accounts_Details,
    }),

    lan(){
      return this.$store.getters.whichLanguage
    },

    filtered() {
      // var self = this;
      let filteredData = this.All_Accounts_Details;
      // Apply filter based on status
      if (this.filterOptions.customer || this.filterOptions.partner) {
        filteredData = filteredData.filter(account => {
          if (this.filterOptions.customer && account.type === 'Customer') return true;
          if (this.filterOptions.partner && account.type === 'Partner') return true;
          return false;
        });
      }
      // Apply search filter
      if (this.search.trim() !== '') {
        const searchTerm = this.search.toLowerCase();
        filteredData = filteredData.filter(account => 

          account?.name?.toLowerCase().includes(searchTerm) ||
          account?.venue_name?.toLowerCase().includes(searchTerm) ||
          account?.phone?.toLowerCase().includes(searchTerm) ||
          account?.address?.toLowerCase().includes(searchTerm) ||
          account?.type?.toLowerCase().includes(searchTerm) ||
          account?.account_owner_detail.first_name?.toLowerCase().includes(searchTerm) ||
          account?.account_owner_detail.last_name?.toLowerCase().includes(searchTerm) ||
          (account?.default_language != '' ? account?.default_language?.toLowerCase().includes(searchTerm) : '')
        );
      }
      console.log('filteredData !!:-', filteredData);
      return filteredData;
    },
  },
  methods: {
    edit(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Account", params: { id: id }, });
    },
    delete() {
      axios
        .post(axios.defaults.baseURL + "delete-account" + this.id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("res", res);
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
