<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="mapPreview"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" style="max-width: 1400px">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Map Preview</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-0" style="height: 600px">
          <div id="google-map" style="width: 100%; height: 500px"></div>
          <h4 class="ml-4 mt-4">{{ mapLocation.formatted_address }}</h4>
        </div>
        <!-- <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Save changes</button>
            </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Map preview",
  props: {
    mapLocation: {
      type: [Object, String],
    },
  },
  data() {
    return {
        map: null,
        marker: null,
        oldMarker: null,
    };
  },

  watch: {
    mapLocation(newValue, oldValue) {
      console.log("new", newValue);
      console.log("oldValue", oldValue);
      setTimeout(() => {
      if (typeof google === "undefined" || typeof google.maps === "undefined") {
        console.log("typeof google.maps ===", typeof google.maps === "undefined");
        // Google Maps API is not loaded yet, wait for it
        return;
      }

      if (!this.map) {
        console.log("!this.map", !this.map);
        // Initialize the map if it doesn't exist
        this.initMap(newValue);
      } else {
        // Map already exists, update the marker position
        if(this.oldMarker){
          console.log('remove');
          this.initMap(newValue);
          this.updateMarker(newValue);
        }else{
          console.log('remove 20-');
          this.updateMarker(newValue);
        }
      }
    }, 3000);

    },
  },
  mounted(){
    this.map = new google.maps.Map(document.getElementById("google-map"), {
        center: { lat: 55.6401125, lng: 12.5971188 },
        zoom: 2,
      });
  },
  methods: {
    initMap(location) {
        console.log("location init", location);
        this.map = new google.maps.Map(document.getElementById("google-map"), {
        center: { lat: location.latitude, lng: location.longitude },
        zoom: 6,
      });

      this.createMarker(location);
    },
    updateMarker(location) {
      console.log("updateMarker");
      if (this.oldMarker) {
        this.oldMarker.setMap(null);
      }
      console.log('1111111----',this.marker);
      this.marker = new google.maps.Marker({
        position: { lat: parseInt(location.latitude), lng: parseInt(location.longitude) },
        map: this.map,
      });
      this.oldMarker = this.marker;
      console.log('2222-----',this.marker);
      this.marker.addListener("click", () => {
        this.openMap(this.marker.position);
      });
    },
    createMarker(location) {
        console.log("createMarker");
      const service = new google.maps.places.PlacesService(this.map);
      service.getDetails(
        {
          placeId: location.place_id,
        },
        (place, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            this.updateMarker(place.geometry.location);
          }
        }
      );
    },
    openMap(location) {
      console.log('openMap',location);
      var url =
        "https://www.google.com/maps/search/?api=1&query=" +
        location.lat() +
        "," +
        location.lng();
      window.open(url, "_blank");
    },
  },
};
</script>

<style scoped>
.modal .modal-body .card-img-top {
  height: 100%;
  object-fit: cover;
  object-position: top;
  transition: all 2s ease-in-out;
  border-radius: 0px;
}
.modal .modal-body .card-img-top:hover {
  object-position: bottom;
}
</style>
