<template>
  <div>
    <!--**********************************
      Main wrapper start
    ***********************************-->
    <div id="main-wrapper">
      <!--**********************************
          Content body start
      ***********************************-->
      <div class="content-body pt-3 mb-5">
        <div class="container-fluid">
          <div
            class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
          >
            <h2 class="font-w600 title mb-2 mr-auto">
              {{ lan.admin[11].Note_page[0].Edit_note }}
            </h2>
            <a href="#myModal" class="trigger-btn" data-toggle="modal">
              <i
                v-show="value_T"
                class="fa fa-trash btn btn-danger shadow btn-xs sharp"
              ></i>
            </a>
            <div v-show="value_L" class="loadingio-spinner-rolling-yxlbb0cd94">
              <div class="ldio-4c1f6xkxs72">
                <div></div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div
                  class="card-header pb-2 d-block d-sm-flex flex-wrap border-0"
                ></div>
                <div class="card-body tab-content">
                  <div class="tab-pane active show fade" id="monthly">
                    <div>
                      <div class="row">
                        <div class="col-sm-4 input">
                          <label
                            >{{ lan.admin[11].Note_page[0].Subject
                            }}<span>*</span>
                            <span style="color: red">{{
                              v$.subject.$error ? " Subject is required" : ""
                            }}</span></label
                          ><br />
                          <input
                            type="text"
                            name="text"
                            v-model="state.subject"
                            placeholder="Subject"
                            :class="v$.subject.$error ? 'input-error' : ''"
                          />
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{
                            lan.admin[11].Note_page[0].Related_Account
                          }}</label
                          ><br />
                          <model-list-select
                            v-if="accountDetails"
                            :list="accountDetails"
                            style="
                              height: 43px;
                              border: 1px solid #ccc;
                              border-radius: 2px;
                            "
                            v-model="related_account"
                            option-value="account_id"
                            :custom-text="getName"
                            placeholder="Please select one"
                          >
                          </model-list-select>
                          <!-- <select name="" id="" v-model="related_account">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option
                              v-for="(data, index) in accountDetails"
                              :key="index"
                              :value="data.account_id"
                            >
                              {{ data.name }}
                            </option>
                          </select> -->
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{
                            lan.admin[11].Note_page[0].Related_Opportunity
                          }}</label
                          ><br />
                          <model-list-select
                            v-if="opportunitiesDetails"
                            :list="opportunitiesDetails"
                            style="
                              height: 43px;
                              border: 1px solid #ccc;
                              border-radius: 2px;
                            "
                            v-model="related_opportunity"
                            option-value="opportunity_id"
                            :custom-text="getNameOpportunity"
                            placeholder="Please select one"
                          >
                          </model-list-select>
                          <!-- <select name="" id="" v-model="related_opportunity">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option
                              v-for="(data, index) in opportunitiesDetails"
                              :key="index"
                              :value="data.opportunity_id"
                            >
                              {{ data.account_detail.name }}
                            </option>
                          </select> -->
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{
                            lan.admin[11].Note_page[0].Related_contact
                          }}</label
                          ><br />
                          <model-list-select
                            v-if="contactsDetails"
                            :list="contactsDetails"
                            style="
                              height: 43px;
                              border: 1px solid #ccc;
                              border-radius: 2px;
                            "
                            v-model="related_contact"
                            option-value="id"
                            :custom-text="getNameContact"
                            placeholder="Please select one"
                          >
                          </model-list-select>
                          <!-- <select name="" id="" v-model="related_contact">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option
                              v-for="(data, index) in contactsDetails"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.first_name }} {{ data.last_name }}
                            </option>
                          </select> -->
                        </div>
                      </div>

                      <label>{{ lan.admin[11].Note_page[0].Comment }}</label
                      ><br />
                      <textarea
                        name=""
                        id=""
                        class="textarea"
                        v-model="comment"
                        rows="5"
                        spellcheck="false"
                      >
                        Comment</textarea
                      >
                      <div class="button d-flex justify-content-end mt-4">
                        <button class="search-btn up-btn ml-3" @click="submit">
                          <div class="lds-ring" v-show="loading">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <span v-show="loadingS">{{
                            lan.admin[11].Note_page[0].Save
                          }}</span>
                        </button>
                        <button class="filter-btn up-btn" v-on:click="route()">
                          {{ lan.admin[11].Note_page[0].Cancel }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <deleteModal @delete="deletef" />
      <!--**********************************
          Content body end
      ***********************************-->
    </div>
    <!--**********************************
      Main wrapper end
    ***********************************-->
  </div>
</template>

<script>
import axios from "axios";

import "vue-search-select/dist/VueSearchSelect.css";
import { ModelListSelect } from "vue-search-select";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import deleteModal from "../../../components/deleteModal";
// @ is an alias to /src

export default {
  name: "Notes",
  components: { deleteModal, ModelListSelect },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      subject: "",
    });

    const rules = computed(() => {
      return {
        subject: { required },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      note_details: "",

      comment: "",
      related_account: "",
      related_contact: "",
      related_opportunity: "",

      accountDetails: "",
      contactsDetails: "",
      opportunitiesDetails: "",
      Id: this.$route.params.id,

      loading: false,
      loadingS: true,
      value_L: false,
      value_T: true,
    };
  },

  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
  },
  mounted() {
    // for top load page
    window.scrollTo(0, 0);

    axios
      .get(axios.defaults.baseURL + "note/" + this.Id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.note_details = res.data.data[0];

        this.state.subject = res.data.data[0].subject;
        this.related_account = res.data.data[0].account_id;
        this.related_contact = res.data.data[0].contact_id;
        this.related_opportunity = res.data.data[0].opportunity_id;
        this.comment = res.data.data[0].comment;
      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [err.response, "error"]);
      });

    // *************GET Accounts**********
    axios
      .get(axios.defaults.baseURL + "accounts", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log("res", res);
        this.accountDetails = res.data.data;
        console.log("accountDetails", this.accountDetails);
      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [err.response, "error"]);
      });

    // *************GET Contacts**********
    axios
      .get(axios.defaults.baseURL + "contacts", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.contactsDetails = res.data.data;
        console.log("contactsDetails", this.contactsDetails);
      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [err.response, "error"]);
      });

    // *************GET Opportunity**********
    axios
      .get(axios.defaults.baseURL + "opportunities", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.opportunitiesDetails = res.data.data;
        console.log("opportunitiesDetails", this.opportunitiesDetails);
      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [err.response, "error"]);
      });
  },
  methods: {
    getName(item) {
      return `${item.name}`;
    },
    getNameOpportunity(item) {
      return `${item.account_detail.name}`;
    },
    getNameContact(item) {
      return `${item.first_name} ${item.last_name}`;
    },
    submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        (this.loading = true), (this.loadingS = false);
        var formData = new FormData();
        formData.append("subject", this.state.subject);
        formData.append("related_account", this.related_account);
        formData.append("related_contact", this.related_contact);
        formData.append("related_opportunity", this.related_opportunity);
        formData.append("comment", this.comment);
        axios
          .post(axios.defaults.baseURL + "edit-note/" + this.Id, formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("res", res);
            (this.loading = false),
              (this.loadingS = true),
              this.$router.push({ path: "/notes" });
              this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
          })
          .catch((err) => {
            (this.loading = false),
              (this.loadingS = true),
              console.log("err", err);
            this.$store.dispatch("checkErrorAndSendToast", [
              err.response,
              "error",
            ]);
          });
      } else {
        console.log("else", this.v$);
        this.AddToast(this.lan.Please_Fill_Required_Details, " info ", "info_1");
        return;
      }
    },
    route() {
      console.log("recfgh");
      this.$router.push("/notes");
    },
    deletef() {
      this.value_L = true;
      this.value_T = false;
      axios
        .post(
          axios.defaults.baseURL + "delete-note/" + this.Id,
          {},
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.value_L = false;
          this.value_T = true;
          this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
          document.getElementById("deletemodal").click();
          this.$router.push({ path: "/notes" });
        })
        .catch((err) => {
          this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
          this.value_L = false;
          this.value_T = true;
        });
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>
