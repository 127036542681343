<template>
  <!--**********************************
          Main wrapper start
      ***********************************-->
  <div id="main-wrapper">
    <!--**********************************
    Content body start
***********************************-->

    <div class="content-body pt-0 mb-5">
      <div class="container-fluid">
        <div
          class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
        >
          <h2 class="font-w600 title mb-2 mr-auto">All partner accounts</h2>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div
                class="card-header pb-2 d-block d-sm-flex flex-wrap justify-content-end border-0"
              >
                <div class="button d-flex justify-content-end">
                  <!-- <a href="#"><button class="search-btn">Search</button></a> -->
                  <input
                    type="search"
                    name="search"
                    class="init new-init w-new"
                    placeholder="Search"
                  />

                  <div class="drop-down">
                    <button
                      class="filter-btn mb-0 dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Filter View
                    </button>
                    <div
                      class="dropdown-menu mt-3"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a class="dropdown-item" href="#">
                        <input
                          type="radio"
                          id="html"
                          name="fav_language"
                          value="HTML"
                        />
                        <label for="vehicle1"> Customer</label><br />
                      </a>
                      <a class="dropdown-item" href="#">
                        <input
                          type="radio"
                          id="html1"
                          name="fav_language1"
                          value="HTML1"
                        />
                        <label for="vehicle1">Partner</label><br />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body tab-content">
                <div class="tab-pane active show fade" id="monthly">
                  <div class="table-responsive">
                    <table
                      class="text-center bg-info-hover tr-rounded order-tbl"
                    >
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Partner name</th>
                          <th>Phone</th>
                          <th>Account owner</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in contactsDetails" :key="index">
                          <td>{{ account_details.name }}</td>
                          <td>{{ data.first_name }} {{ data.last_name }}</td>
                          <td>{{ data.phone }}</td>
                          <td>{{ data.account_owner.first_name }} {{ data.account_owner.last_name }} </td>
                        </tr>
                      </tbody>
                      <div class="lds-ellipsis" v-show="value">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </table>

                    
                    <div class="d-flex justify-content-end mt-3">
                      <button
                          class="search-btn-new filter-btn"
                          data-toggle="modal"
                          data-target="#exampleModal22"
                        >
                          Create new contact
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddConatact @event="getContact" check_partner="Partner" :value="this.user.company" />
    <!--**********************************
    Content body end
***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import AddConatact from "../../../components/addContact";

export default {
  name: "Partner Account",
  components: { AddConatact },
  data() {
    return {
      contactsDetails:'',
      account_details:'',
      value: true,

      user:JSON.parse(localStorage.getItem("user"))
    };
  },
  // created() {
  //   this.$store.dispatch("tryAutoLogin");
  //   if (!this.$store.getters.isPartner) {
  //     this.AddToast("You can't access Partner Portal", " Warning ", "warning_1");
  //     setTimeout(() => {
  //       alert('First logout then Login partner portal.')
  //     this.$router.back()
  //     }, 3000);
  //   }
  // },
  mounted(){
    this.getContact()

      // *****************Account by ID****************
    axios
      .get(axios.defaults.baseURL + "account/" + this.user.company, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.account_details = res.data.data[0];

      })
      .catch((err) => {
        console.log("err", err);
      });


  },
  methods:{
    getContact(){
      this.value = true

      axios
      .get(axios.defaults.baseURL + "get-contact/" + this.user.company, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.contactsDetails = res.data.data;
        this.value = false
        // alert(this.lead_details);
      })
      .catch((err) => {
        this.value = false
        console.log("err", err);
      });

    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
