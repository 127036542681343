<template>
<div class="header" id="headerr">
    <div class="mycontainer">
        <nav class="navbar navbar-expand-lg">
            <a class="navbar-brand" href="https://konferensbokarna.se/"><img src="images/landing/konferensbokarna-2.png" alt="img not found" /></a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fa-solid fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                <div class="navbar-nav">
                    <a class="nav-item nav-link active" href="https://konferensbokarna.se/">Hem</a>
                    <a class="nav-item nav-link" href="https://konferensbokarna.se/utvalda-konferensanlaggningar/">Utvalda anläggningar</a>
                    <a class="nav-item nav-link" href="https://konferensbokarna.se/enklare-att-boka-konferens/">Våra tjänster</a>
                    <a class="nav-item nav-link" href="https://konferensbokarna.se/vart_team/">Vårt team</a>
                    <a class="nav-item nav-link" href="https://konferensbokarna.se/nyheter-grid/">Nyheter</a>
                    <!-- <a class="nav-item nav-link" href="#">Kontakta oss</a> -->

                    <div class="dropdown">
                        <a class="btn drp dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                            Kontakta oss
                        </a>

                        <div class="dropdown-menu">
                            <a class="dropdown-item" href="https://konferensbokarna.se/kontakt/">Kontakta oss</a>
                            <a class="dropdown-item" href="https://konferensbokarna.se/kontakt/vill-du-bli-uppringd/">Vill du bli uppringed?</a>
                            <a class="dropdown-item" href="https://konferensbokarna.se/faq/">Vanliga Fragor & Svar</a>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
</div>
<!-- <div class="banner">
    <div class="container_1">
        <div class="row">
            <div class="col-lg-6">
                <div class="content">
                    <div class="d-flex justify-content-center">
                        <div class="icon">
                            <i class="fa-sharp fa-solid fa-paper-plane"></i>
                        </div>
                    </div>
                    <h1>My dashboard</h1>
                    <h2>Welcome to konferensbokarna.se</h2>
                    <button data-toggle="modal" data-target="#exampleModal">
                        Send request
                    </button>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="images">
                    <img src="images/landing/corporate-01.jpg" class="img-fluid" alt="img not found" />
                </div>
            </div>
        </div>
    </div>
  </div> -->
<div id="exampleModal" role="dialog" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-dialog-new" role="document">
        <div class="modal-content">
            <div class="modal-body p-0">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="form">
                            <h2>HOW FUN THAT WE GOT THE TRUST TO HELP YOU!</h2>
                            <p>
                                Fill in the form below and within a few hours we will compile
                                a number of offers free of charge from facilities that match
                                your specific wishes.
                            </p>

                            <div class="input row">
                                <div class="col-md-6">
                                    <label>Name<span>*</span>
                                        <span style="color: red">{{
                                    v$.first_name.$error ? " is required" : ""
                                  }}</span></label><br />
                                    <input v-model="state.first_name" placeholder="First Name" :class="v$.first_name.$error ? 'input-error' : ''" type="text" />
                                </div>

                                <div class="col-md-6 margin-top">
                                    <input type="text" name="text" placeholder="Last Name" :class="v$.last_name.$error ? 'input-error' : ''" v-model="state.last_name" />
                                </div>
                            </div>

                            <div class="input">
                                <label>Business</label><br />
                                <input type="text" class="inti" v-model="company" />
                            </div>

                            <div class="input row">
                                <div class="col-md-6">
                                    <label>Email<span>*</span>
                                        <span style="color: red">{{
                                     v$.email.$error ? " is required" : ""
                                     }}</span></label><br />
                                    <input type="email" v-model="state.email" :class="v$.email.$error ? 'input-error' : ''" />
                                </div>

                                <div class="col-md-6 mt-md-0 mt-3 form-group phone">
                                    <label>Phone</label>
                                    <br>
                                    <!-- <input type="tel" id="phone" class="font_family" placeholder="081234 56789" v-model="telephone" /> -->
                                    <input type="tel" class="font_family form-control" id="telephone" v-model="telephone" />
                                </div>
                            </div>

                            <div class="input row">
                                <div class="col-md-6">
                                    <label>Type of conference<span>*</span></label><br />
                                    <select @change="checkConference()" v-model="type_of_conference" id="">
                                        <option value="Conference with overnight stay">
                                            Conference with overnight stay
                                        </option>
                                        <option value="Day conference">Day conference</option>
                                    </select>
                                </div>
                            </div>

                            <div class="input row">
                                <div class="col-md-6">
                                    <label for="">Start Date of the conference</label>
                                    <input type="date" v-model="startdate_of_conference" @change="incrementDate"/>
                                </div>
                                <div class="col-md-6 mt-md-0 mt-3">
                                    <label for="">End Date of the conference</label>
                                    <input type="date" v-model="enddate_of_conference" />
                                </div>
                            </div>

                            <div class="input row">
                                <div class="col-md-6">
                                    <label for="">Time of arrival</label>
                                    <input type="time" v-model="arrival_time" />
                                </div>

                                <div class="col-md-6 mt-md-0 mt-3">
                                    <label for="">Time of departure</label>
                                    <input type="time" v-model="departure_time" />
                                </div>
                            </div>

                            <div class="input row">
                                <div class="col-md-6 mt-3">
                                    <label for="">Number of participants</label>
                                    <input type="number" v-model="number_of_participants" min="0"/>
                                </div>
                            </div>

                            <div class="input row align-items-end">
                                <div v-show="showInputSingle" class="col-md-6">
                                    <label for="">Number of people in single room</label>
                                    <input type="number" v-model="number_of_participants_in_single_room" min="0"/>
                                </div>

                                <div v-show="showInputDouble" class="col-md-6 mt-md-0 mt-3">
                                    <label for="">Number of people in shared double room</label>
                                    <input type="number" v-model="number_of_participants_in_shared_double_room" min="0"/>
                                </div>
                            </div>

                            <div class="tab mb-3">
                                <p>
                                    Some participants may consider sharing a double room (with
                                    separate beds), if the facility cannot offer the number of
                                    single rooms we primarily desire
                                </p>
                                <div class="d-flex mb-3">
                                    <div class="new-redio">
                                        <input @change="checkAcceptedRoom()" type="radio" v-model="do_you_accept_shared_double_room" id="html" value="1" />
                                        <label for="yes" class="ml-2">Yes</label>
                                    </div>

                                    <div class="new-redio ml-4">
                                        <input @change="checkAcceptedRoom()" type="radio" v-model="do_you_accept_shared_double_room" id="no" value="0" />
                                        <label for="css" class="ml-2">No</label>
                                    </div>
                                </div>

                                <textarea v-show="hideTextarea" id="" cols="10" rows="3" spellcheck="false" v-model="notice_regarding_shared_double_room" placeholder="Notice regarding shared double room"></textarea>
                            </div>

                            <div class="input row align-items-end">
                                <div class="col-md-6">
                                    <label for="">Conference room for number of participants</label>
                                    <input type="number" placeholder="Number of participants" min="0" v-model="number_of_participants_in_conference_room" />
                                </div>

                                <div class="col-md-6 mt-md-0 mt-3">
                                    <label>Desired furnishing<span>*</span><span style="color: red">{{
                                      v$.seating_type.$error ? " is required" : ""
                                    }}</span></label><br />
                                    <select v-model="state.seating_type" :class="v$.seating_type.$error ? 'input-error' : ''" id="">
                                        <option value="Cinema Seating">Cinema Seating</option>
                                        <option value="U-shaped table">U-shaped table</option>
                                        <option value="Boardroom">Boardroom</option>
                                        <option value="School seating">School seating</option>
                                        <option value="Islands">Islands</option>
                                        <option value="Don't know at the moment">
                                            Don't know at the moment
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="input row">
                                <div class="col-md-6 mt-md-0 mt-2">
                                    <select v-model="region_id" id="" @change="checkCity()">
                                        <option value="" disabled selected>Select region</option>
                                        <option v-for="(data, index) in region" :value="data.region_id" :key="index">
                                            {{ data.region }}
                                        </option>
                                        <!-- <option value="1">Stockholm</option>
                                        <option value="2">Gothenburg</option>
                                        <option value="3">Malmö</option>
                                        <option value="4">Other area</option> -->
                                    </select>
                                </div>

                                <div v-show="optVshow">
                                    <select v-model="city_id" id="">
                                        <option v-for="(data, index) in cityName" :value="data.name" :key="index">
                                            {{ data.name }}
                                        </option>
                                        <!-- <option value="">Centrala Stockholm</option>
                                        <option value="1">Norra Stockholm</option>
                                        <option value="1">Södra Stockholm</option>
                                        <option value="1">Västra Stockholm</option>
                                        <option value="1">Östra Stockholm</option> -->
                                    </select>
                                </div>
                                <div v-show="textVshow" class="devide ml-4">
                                    <textarea v-model="city_by_txt" name="" cols="10" rows="2" placeholder="Which Area?"></textarea>
                                </div>
                            </div>

                            <label for="css">Price wanted</label>
                            <div class="d-flex">
                                <div class="new-redio">
                                    <input type="radio" id="html" v-model="price_wanted" value="excl vat" />
                                    <label for="html" class="ml-2">ex. VAT</label>
                                </div>
                                <div class="new-redio ml-4">
                                    <input type="radio" id="css" v-model="price_wanted" value="inc vat" />
                                    <label for="css" class="ml-2">incl. VAT</label>
                                </div>
                            </div>

                            <label for="" class="mt-3">Message</label>
                            <textarea id="" cols="30" rows="5" v-model="free_text_message" placeholder="Message"></textarea>

                            <label for="css" class="mt-3">Contact me preferably via </label>
                            <div class="d-flex">
                                <div class="new-redio">
                                    <input type="radio" id="phone" v-model="contact_me_by" value="Phone" />
                                    <label for="html" class="ml-2">Phone</label>
                                </div>
                                <div class="new-redio ml-4">
                                    <input type="radio" id="email" v-model="contact_me_by" value="Email" />
                                    <label for="css" class="ml-2">Email</label>
                                </div>
                            </div>

                            <button type="submit" class="submit mt-2" @click="submit()">
                                <div class="lds-ring" v-show="loading">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                <span v-show="loadingS">Send</span>
                            </button>
                        </div>
                    </div>

                    <div class="col-lg-4">
                        <div class="background">
                            <!-- <button type="button" class="close button_1" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button> -->

                            <div class="contact">
                                <h3>Contact Us</h3>
                                <ul>
                                    <li>
                                        <div class="icon-new">
                                            <i class="fa-solid fa-phone"></i>
                                        </div>
                                    </li>

                                    <li class="ml-3 font-weight-bold">
                                        <a href="tel:+468984600">+46 8 98 46 00</a>
                                    </li>
                                </ul>

                                <ul class="mt-5">
                                    <li>
                                        <div class="icon-new">
                                            <i class="fa-solid fa-paper-plane"></i>
                                        </div>
                                    </li>

                                    <li class="ml-3 font-weight-bold">
                                        <a href="mailto:info@konferensbokarna.se">info@konferensbokarna.se</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- popup start -->
<div v-show="hidd" class="popup-overlay">
    <div class="popup-container">
        <div @click="closePopup()" class="popup-close">+</div>
        <p class="popup-title">Thanks for contacting us! We will be in touch with you shortly.</p>
    </div>
</div>
<!-- popup end -->

<!-- footer -->
<section>
    <footer class="container foot-container">
        <div class="row justify-content-between mx-0">
            <div class="col-xl-2 p-4">
                <a class="navbar-brand footer-img" href="https://konferensbokarna.se/"><img src="images/landing/konferensbokarna-2.png" alt="img not found" /></a>
            </div>
            <div class="col-xl-2 p-4">
                <p>Huvudsidor</p>
                <ul>
                    <li><a href="https://konferensbokarna.se/enklare-att-boka-konferens/">Våra tjänster</a></li>
                    <li><a href="https://konferensbokarna.se/vart_team/">Vårt team</a></li>
                    <li><a href="https://konferensbokarna.se/faq/">FAQ</a></li>
                    <li><a href="https://konferensbokarna.se/kontakt/">Kontakta oss</a></li>
                </ul>
            </div>
            <div class="col-xl-2 p-4">
                <p>Viktiga länkar</p>
                <ul>
                    <li><a href="https://konferensbokarna.se/utvalda-konferensanlaggningar/">Utvalda anläggningar</a></li>
                    <li><a href="https://konferensbokarna.se/nyheter-grid/">Nyheter</a></li>
                </ul>
            </div>
            <div class="col-xl-2 p-4">
                <p>Fakta</p>
                <ul>
                    <li><a href="https://konferensbokarna.se/integritetspolicy/">Integritetspolicy</a></li>
                </ul>
            </div>
            <div class="col-xl-2 p-4">
                <p>Sociala länkar</p>
                <ul>
                    <li><a href="#">Följ oss</a></li>
                    <a href="https://www.facebook.com/konferensbokarna"><i class="fa-brands fa-facebook-f"></i></a>
                    <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                </ul>
            </div>

            <!-- <h3>Therichpost.com</h3>
            <div class="form-group">
               <label> All Countries</label> <br>
               <input type="tel" class="form-control" id="telephone">
           </div> -->
        </div>
    </footer>
</section>
</template>

<script>
import axios from "axios";
// import store from "../../store/index";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, computed } from "vue";

//intlTelInput
import 'intl-tel-input/build/css/intlTelInput.css';
import 'intl-tel-input/build/js/intlTelInput.js';
import intlTelInput from 'intl-tel-input';

export default {
    name: "Customer Landing Page",
    page: {
        title: "Landing Page",
        meta: [{ name: "description", content: "Landing confrence" }],
    },
    setup() {
        const state = reactive({
            errors: [],
            success: [],
            first_name: "",
            last_name: "",
            email: "",
            seating_type: "",

        });

        const rules = computed(() => {
            return {
                first_name: { required },
                last_name: { required },
                email: { required, email },
                seating_type: { required },

                // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
            };
        });
        const v$ = useVuelidate(rules, state);

        return { state, v$ };
    },
    data() {
        return {
            submitted: "2023-03-17",
            company: "",
            telephone: "",
            type_of_conference: "",
            startdate_of_conference: "",
            enddate_of_conference: "",
            arrival_time: "",
            departure_time: "",

            number_of_participants: "",
            number_of_participants_in_single_room: "",
            number_of_participants_in_shared_double_room: "",
            do_you_accept_shared_double_room: "",
            notice_regarding_shared_double_room: "",
            number_of_participants_in_conference_room: "",

            region_id: "",
            city_id: "",
            city_by_txt: "",
            contact_me_by: "",
            price_wanted: "",
            free_text_message: "",
            status: "0",
            cityName: "",
            region: "",

            loading: false,
            loadingS: true,

            textVshow: false,
            optVshow: true,
            showInputDouble: true,
            showInputSingle: true,
            hideTextarea: false,
            hidd: false,
        };
    },
    mounted() {
        axios
            .get(axios.defaults.baseURL + "regions", {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
            .then((res) => {
                console.log("res for regions", res.data.data);
                this.region = res.data.data;
            })
            .catch((err) => {
                console.log("err for regions", err);
            });

        window.onscroll = function () {
            let scroll = document.documentElement.scrollTop;
            if (scroll >= 40) {
                document.getElementById("headerr").classList.add("bg")
            } else {
                document.getElementById("headerr").classList.remove("bg")
            }
        }

        if (this.region_id == "") {
            this.optVshow = false;
        } else {
            this.optVshow = true;
        }

        // telephone
        const input = document.querySelector("#telephone");
        intlTelInput(input, {
            // any initialisation options go here
            preferredCountries: ["in", "us", "ca", "se"],
            separateDialCode: true,
            showFlags: true,
            initialCountry: "se",
            // numberType: "MOBILE",
            // formatOnDisplay: true,
        });

    },
    methods: {
        incrementDate() {
      const date = new Date(this.startdate_of_conference);
      date.setDate(date.getDate() + 1);
      this.enddate_of_conference = date.toISOString().substr(0, 10);
    },
        closePopup(){
            this.hidd = false;
            window.location = "http://konferensbokarna.se";
        },
        checkConference() {
            if (this.type_of_conference == "Day conference") {
                this.showInputDouble = false;
                this.showInputSingle = false;
            } else {
                this.showInputDouble = true;
                this.showInputSingle = true;
            }
        },
        checkAcceptedRoom() {
            if (this.do_you_accept_shared_double_room == "1") {
                this.hideTextarea = true;
            } else {
                this.hideTextarea = false;
            }
        },
        checkCity() {
            // ******** get cities by region id **********
            if (this.region_id != "4") {
                this.optVshow = true;
                this.textVshow = false;
                axios
                    .get(axios.defaults.baseURL + "get-city/" + this.region_id, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    })
                    .then((res) => {
                        console.log("res for city", res.data.data);
                        this.cityName = res.data.data;
                    })
                    .catch((err) => {
                        console.log("err for city", err);
                    });
            } else {
                this.textVshow = true;
                this.optVshow = false;
            }
        },

        submit() {
            this.v$.$validate();
            if (!this.v$.$error) {

                (this.loading = true), (this.loadingS = false);
                var formData = new FormData();
                formData.append("submitted", this.submitted);
                formData.append("first_name", this.state.first_name);
                formData.append("last_name", this.state.last_name);
                formData.append("company", this.company);
                formData.append("email", this.state.email);
                formData.append("telephone", this.telephone);
                formData.append("type_of_conference", this.type_of_conference);

                if (this.startdate_of_conference && this.enddate_of_conference) {
                    formData.append("date_of_conference", this.startdate_of_conference + " " + "to" + " " + this.enddate_of_conference);
                } else if (this.startdate_of_conference) {
                    formData.append("date_of_conference", this.startdate_of_conference);
                }

                formData.append("arrival_time", this.arrival_time);
                formData.append("departure_time", this.departure_time);

                formData.append("number_of_participants", this.number_of_participants);
                formData.append(
                    "number_of_participants_in_single_room",
                    this.number_of_participants_in_single_room
                );
                formData.append(
                    "number_of_participants_in_shared_double_room",
                    this.number_of_participants_in_shared_double_room
                );
                formData.append(
                    "do_you_accept_shared_double_room",
                    this.do_you_accept_shared_double_room
                );
                formData.append(
                    "notice_regarding_shared_double_room",
                    this.notice_regarding_shared_double_room
                );
                formData.append(
                    "number_of_participants_in_conference_room",
                    this.number_of_participants_in_conference_room
                );

                formData.append("seating_type", this.state.seating_type);
                formData.append("region", this.region_id);
                if (this.region_id != "4") {
                    formData.append("city", this.city_id);
                } else {
                    formData.append("city", this.city_by_txt);
                }
                formData.append("contact_me_by", this.contact_me_by);
                formData.append("price_wanted", this.price_wanted);
                formData.append("free_text_message", this.free_text_message);
                formData.append("status", this.status);

                axios
                    .post(axios.defaults.baseURL + "leads", formData, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                    })
                    .then((res) => {
                        (this.loading = false),
                        (this.loadingS = true),
                        console.log("res", res);
                        // this.AddToast(res.data.message, " Success ", "success_1");
                        this.hidd = true;
                    })
                    .catch((err) => {
                        (this.loading = false),
                        (this.loadingS = true),
                        console.log("err", err);
                        // this.AddToast(err.response.data.message, "Faild", "error_1");
                        
                    });
            } else {
                console.log("else", this.v$);
                // this.AddToast("Please Fill Required Details", " info ", "info_1");
                return;
            }

        },

        // AddToast(message, title, type) {
        //     this.$store.commit("addToast", {
        //         title: title,
        //         type: type,
        //         message: message,
        //     });
        //     setTimeout(() => {
        //         this.dismissToast(title);
        //     }, 5000);
        // },
        // dismissToast(title) {
        //     this.$store.commit("clearToast", title);
        // },
    },
};
</script>

<style scoped>
/* @import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */

/* *{
    padding: 0px;
    margin: 0px;
    font-family: 'Raleway', sans-serif;
} */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@700&display=swap');

.container_1 {
    max-width: 1274px !important;
}

button {
    padding: 15px 45px;
    outline: none !important;
    border: none !important;
    /* border-radius: 30px; */
}

.dropdown-menu {
    margin-top: 20px;
    overflow-x: hidden;
    padding: 15px 0;
}

.dropdown-menu .dropdown-item {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    padding: 12px 49px;
    color: #333333 !important;

}

.dropdown-menu .dropdown-item:hover {
    transform: translateX(7px);
    transition: .3s ease-in;
}

.header {
    background: #333333;
    padding: 15px 0px;
    position: sticky;
    top: 0px;
    z-index: 100;
    margin-top: -60px;

}

.nav-item {
    font-family: 'Raleway', sans-serif;
}

.bg {
    background-color: #003232 !important;
}

.navbar-brand img {
    width: 220px;
}

.drp {
    padding: 0.5rem 0.7rem !important;
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    font-family: 'Raleway', sans-serif;
}

.nav-link {
    display: block;
    padding: 0.5rem 0.7rem !important;
    color: #fff !important;
    font-weight: 600 !important;
    font-size: 14px !important;
}

.banner {
    /* background-image: url(../img/corporate-bg.jpg); */
    background: #033f3f !important;
    height: 618px;
    background-size: cover;
    display: flex;
    align-items: center;
}

.banner .content {
    height: 300px;
    text-align: center;
    padding-top: 90px;
}

.icon {
    height: 80px;
    width: 80px;
    background: #fd6e5d;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon i {
    font-size: 40px;
    color: #fff;
}

.banner .content h1 {
    color: #fff;
    font-weight: 600;
    padding-top: 20px;
}

.banner .content h2 {
    color: #fff;
    font-weight: 500;
    padding-top: 10px;
}

.banner .content button {
    color: #000000 !important;
    border-width: 0px !important;
    border-radius: 100px;
    font-size: 15px;
    font-family: "Raleway", Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 600 !important;
    background-color: #ffffff;
    margin-top: 30px;
}

.images {
    width: 100%;
    height: 500px;
}

.images img {
    height: 500px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.modal-dialog-new {
    max-width: 70% !important;
}

.fa-brands {
    font-size: 15px;
    padding: 12px 14px;
    background-color: #003232;
    margin-right: 5px;
    border-radius: 100%;
    font-weight: 600 !important;
    color: white !important;

}

.fa-brands:hover {
    background-color: #fd6e5d;
    color: white !important;
    transition: 0.3s ease-in;
}

.foot-container p {
    color: white !important;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Raleway', sans-serif;
}

.foot-container ul li a {
    font-size: 14px !important;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5) !important;
}

.foot-container ul li a:hover {
    color: white !important;
    transition: 0.3s ease-in;
}

.foot-container ul li {
    padding: 8px 0 !important;
}

section {
    background: #033f3f;
}

footer {
    padding: 138px 0px;
}

.footer-img img {
    width: 200px !important;
}

.footer-logo {
    width: 210px;
}

footer h4 {
    color: #fff;
    font-size: 20px;
}

footer ul {
    list-style-type: none;
    margin-top: 30px;
}

footer ul li a {
    color: #666;
    font-size: 14px;
    font-size: 500;
}

footer ul li a:hover {
    color: #666;
    text-decoration: none;
}

.footer-icons {
    display: flex;
}

.fo-icon {
    background: rgba(255, 255, 255, 0.08);
    height: 40px;
    width: 40px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.fo-icon i {
    color: #fff;
}

.flow {
    color: rgba(255, 255, 255, 0.57);
}

.reading button {
    padding: 15px 55px !important;
    border-radius: 30px;
}

.booking-btn {
    background: #fff;
    color: #033f3f;
    font-weight: 600;
    font-size: 14px;
}

.con-btn {
    background: #033f3f;
    color: #fff;
}

.all {
    display: flex;
    justify-content: space-between;
}

.admin {
    display: flex;
}

.admin li {
    padding-right: 30px;
}

.contact-form {
    padding: 50px 0px;
}

.form {
    padding: 40px;
}

.form .input {
    margin-bottom: 20px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/*.form .input input {
    outline: none !important;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 2px;
    color: #333;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    font-family: inherit;
    border: 1px solid #ccc;
    padding: 6px 10px;
    height: 38px;
    width: 49%;
    line-height: 1.3;
}*/

.form .input select {
    outline: none !important;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 2px;
    color: #333;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    font-family: inherit;
    border: 1px solid #ccc;
    padding: 6px 10px;
    height: 38px;
    width: 100%;
    line-height: 1.3;
}

.form textarea {
    outline: none !important;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 2px;
    color: #333;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;

    font-size: 16px;
    font-family: inherit;
    border: 1px solid #ccc;
    padding: 6px 10px;
    width: 100%;
    line-height: 1.3;
}

.devide {
    width: 49%;
}

.devide input {
    width: 100% !important;
}

.form label {
    font-size: 16px;
    font-weight: 600;
    color: #666;
    position: relative;
}

.form label span {
    color: #ff0000;
    font-weight: 400;
    position: absolute;
    font-size: 30px;
    top: -7px;
    padding-left: 2px;
}

.form h2 {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 30px;
}

.form p {
    margin-bottom: 30px;
    font-size: 14px;
    color: #a7a4a4;
}

.margin-top {
    margin-top: 31px;
}

.inti {
    width: 100% !important;
}

.iti {
    width: 100% !important;
}


.font_family {
    font-family: "Noto Sans", sans-serif !important;
}

.radio {
    width: 10px !important;
    height: 10px !important;
}

.submit {
    color: white !important;
    border-width: 0px !important;
    border-radius: 100px;
    font-size: 15px;
    font-family: "Raleway", Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 600 !important;
    background-color: black;
    margin-top: 30px;
    padding: 15px 45px;
}

.submit:hover {
    background-color: #fd6e5d;
    color: white !important;
}

.background {
    height: 1750px;
    background: #fd6e5d;
}

.icon-new {
    height: 50px;
    width: 50px;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-new i {
    font-size: 20px;
    color: #fff;
}

.contact {
    padding: 80px 30px;
}

.contact h3 {
    color: #fff;
    padding-bottom: 30px;
    font-weight: 400;
}

.contact ul {
    display: flex;
    list-style-type: none;
}

.contact ul li a {
    color: #fff;
    padding-top: 10px;
}

.button_1 {
    color: #000000 !important;
    border-width: 0px !important;
    font-size: 15px;
    font-family: "Raleway", Helvetica, Arial, Lucida, sans-serif !important;
    font-weight: 600 !important;
    background-color: #fff !important;
    height: 50px;
    width: 50px;
    opacity: 1 !important;
}

.button_1 span {
    font-size: 25px !important;
}

.mycontainer {
    max-width: 1310px;
    margin: auto;
}

.form label span {
    position: relative !important;
    font-size: 15px !important;
}
/* popup start */
.popup-overlay{
    background-color: rgba(0, 0, 0, 0.637);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    
}
.popup-container{
    max-width: 500px;
    margin: 0 auto;
    margin-top: 300px;
    background-image: linear-gradient(to right, rgb(230, 59, 59) , rgb(223 251 210));
    padding: 40px;
    border-radius: 24px;
    box-shadow: 0 0 9px 0 rgb(204 155 155 / 84%);
    position: relative;
}
.popup-container p{
    font-size: 18px;
}
.popup-close{
    position: absolute;
    right: -5px;
    top: -6px;
    background-color: red;
    width: 25px;
    height: 25px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 30px;
   font-size: 20px;
   font-weight: bold;
   color: white;
   transform: rotateZ(45deg);
   cursor: pointer;

}
.popup-btn{
    padding: 12px 30px;
    background-color: rgb(255, 81, 0);
    text-decoration: none;
    font-size: 20px;
    display: block;
    width: 80px;
    margin: 0 auto;
    border-radius: 30px;
    color: white;
}
/* popup end */

@media only screen and (max-width: 768px) {
    .navbar-brand img {
        width: 200px;
    }

    .footer-img img {
        width: 220px;
    }

    .navbar-toggler {
        color: #fff;
    }

    .banner .content {
        padding-top: 0px;
    }

    .images {
        height: auto;
    }

    .images img {
        height: 200px;
    }

    .table {
        font-size: 13px;
    }

    .reading h6 {
        font-size: 28px;
    }

    .reading {
        padding: 15px;
    }

    .reading button_1 {
        padding: 15px 33px !important;
    }

    .banner .content h2 {
        font-size: 29px;
    }

    .all {
        display: block;
    }

    .all .button_1 {
        margin-top: 20px;
    }

    footer {
        padding: 50px 0px;
    }

    .opportuniies {
        padding: 30px 0px 30px 0px;
    }

    .footer-logo {
        width: 210px;
        margin-bottom: 30px;
    }

    .scroll {
        width: 99%;
        overflow-x: scroll;
    }

    .form {
        width: 100%;
        margin: 0 auto;
        padding: 20px;
    }
    .contact{
        padding: 60px 20px;
    }
    .background{
        height: 350px;
    }
    .header .navbar{
        height: auto;
    }
   .header .navbar{
    padding-left: 5px;
   }
   .header .navbar button{
    padding: 0.25rem 0.75rem;
   }
   .header .navbar .nav-link{
    padding: 0.75rem 0.7rem !important;
   }
}
@media only screen and (max-width: 576px) {
    .modal-dialog-new {
        max-width: 100% !important;
    }
}
</style>
