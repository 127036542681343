<template>
  <div>
    <!--**********************************
    Main wrapper start
  ***********************************-->
    <div id="main-wrapper">
      <!--**********************************
        Content body start
    ***********************************-->
      <div class="content-body pt-0">
        <div class="container-fluid">
          <div class="form-head d-flex flex-wrap align-items-center pt-3">
            <h2 class="font-w600 title mb-5 mr-auto">
              {{ lan.admin[22].Email[0].Email_Template }}
            </h2>
          </div>

          <div class="row">
            <div class="col-xl-12 mar-bo">
              <div class="card">
                <div class="card-header border-0 pb-0">
                  <h4 class="mb-0 fs-20 text-black">
                    {{ lan.admin[22].Email[0].Email_Contents }}
                  </h4>
                </div>
                <div class="card-body tab-content">
                  <div class="tab-pane active show fade" id="monthly">
                    <div class="table-responsive">
                      <table
                        class="text-center bg-info-hover tr-rounded order-tbl"
                      >
                        <thead>
                          <tr>
                            <th>{{ lan.admin[22].Email[0].Id }}</th>
                            <th>{{ lan.admin[22].Email[0].Email_Message }}</th>
                            <th>English</th>
                            <th>Swedish</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in emailContent"
                            :key="item.id"
                            class="pointer"
                          >
                            <td>{{ item.id }}</td>
                            <td>{{ item.email_name }}</td>
                            <td
                              data-toggle="modal"
                              :data-target="'#exampleModal-' + item.id"
                              @click="getDetails(item, 'En')"
                            >
                              <i class="fa-solid fa-pen-to-square"></i>
                              <!-- <i :id="'td' + item.id" class="fa-solid fa-pen-to-square"></i> -->
                              <!-- <div :id="'loader' + item.id"
                                    class="lds-ellipsis loader-show"
                                  >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div> -->
                            </td>
                            <td
                              data-toggle="modal"
                              :data-target="'#exampleModal-' + item.id"
                              @click="getDetails(item, 'Sw')"
                            >
                              <i class="fa-solid fa-pen-to-square"></i>
                              <!-- <i :id="'td' + item.id" class="fa-solid fa-pen-to-square"></i> -->
                              <!-- <div :id="'loader' + item.id"
                                    class="lds-ellipsis loader-show"
                                  >
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                  </div> -->
                            </td>
                          </tr>
                          <div class="lds-ellipsis" v-show="value">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Edit Email Template -->
      <!-- Modal -->
      <div
        class="modal fade"
        :id="'exampleModal-' + getId"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="max-width: 1200px">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ lan.admin[22].Email[0].Edit_Email }} {{ ln == 'En' ? 'English' : 'Swedish' }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-md-4 p-3">
              <div class="input">
                <span class="mr-3">{{ lan.admin[22].Email[0].Subject }}:</span>
                <input style="width: 40%" type="text" v-model="subject" />
                <span class="ml-3 mr-3">BCC:</span>
                <input style="width: 40%" type="text" v-model="bcc_email" />
              </div>


              <!-- <Editor
              v-model="BodyContent"
              api-key="7d9czbwdygks2t443szu0h394idcy6qni8smq9b0k0a2fju8"
                :init="{
                  toolbar_mode: 'sliding',
                  plugins: 'lists link image paste help wordcount',
                  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image table | align lineheight | checklist numlist bullist indent outdent | emoticons',
                  tinycomments_mode: 'embedded',
                  tinycomments_author: 'Ulf',
                }"
                initial-value="Welcome to TinyMCE!"
              /> -->

              
              <ckeditor
                :editor="editor"
                v-model="BodyContent"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="search-btn up-btn mb-0"
                data-dismiss="modal"
              >
                {{ lan.admin[22].Email[0].Close }}
              </button>
              <button
                type="button"
                class="filter-btn up-btn mb-0"
                @click="save()"
              >
                <div class="lds-ring" v-show="loading">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span v-show="loadingS">
                  {{ lan.admin[22].Email[0].Save_changes }}</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--**********************************
        Content body end
    ***********************************-->
    </div>
    <!--**********************************
    Main wrapper end
  ***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Editor from '@tinymce/tinymce-vue'
// import _ from 'lodash';

export default {
  name: "Email Template",
  // components: {
  //   Editor
  // },
  data() {
    return {
      value: true,
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        // The configuration of the editor.
      },
      itemDetails: "",
      emailContent: "",
      getId: "",
      BodyContent: "",
      subject: "",
      partner_email_body: "",
      bcc_email: "",
      ln: "",

      loading: false,
      loadingS: true,
    };
  },
  mounted() {
    // for top load page
    window.scrollTo(0, 0);

    this.emailContentLoad("mount");
  },

  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
  },

  methods: {
    getDetails(item, ln) {
      console.log('getDetails-item---',item);
      this.getId = item.id;
      this.ln = ln;
      this.itemDetails = item;

      this.BodyContent = ln == 'En' ? item.body : item.sw_body;
      this.subject = ln == 'En' ? item.subject : item.sw_subject
      this.partner_email_body = item.partner_email_body;
      this.bcc_email = item.bcc_email != "null" ? item.bcc_email : '';
    },
    emailContentLoad(text) {
      console.log(text);
      axios
        .get(axios.defaults.baseURL + "emails-content", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("res email content", res);
          this.emailContent = res.data.data;
          //   if(text != 'mount'){
          //   var loader = document.getElementById('loader' + this.getId)
          //     var td = document.getElementById('td' + this.getId)
          //     loader.style.display = 'none';
          //     td.style.display = 'block';
          // }

          this.value = false;
          console.log("emailContent", this.emailContent);
        })
        .catch((err) => {
          this.value = false;
          this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
    },
    save() {
      console.log('itemDetails--*-',this.itemDetails);
      var formData = new FormData();
      formData.append("subject", this.ln == 'En' ?  this.subject : this.itemDetails.subject);
      formData.append("sw_subject", this.ln == 'Sw' ?  this.subject : this.itemDetails.sw_subject);
      formData.append("bcc_email", this.bcc_email);
      formData.append("body", this.ln == 'En' ?  this.BodyContent : this.itemDetails.body);
      formData.append("sw_body", this.ln == 'Sw' ?  this.BodyContent : this.itemDetails.sw_body);
      if(this.getId != 2 && this.getId != 3){
        formData.append("partner_email_body", this.BodyContent);
      }else{
        formData.append("partner_email_body", this.partner_email_body);
      }
      this.loading = true;
      this.loadingS = false;
      // return
      axios
        .post(
          axios.defaults.baseURL + "edit-email-content/" + this.getId,
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log("post res", res);
          // var loader = document.getElementById('loader' + this.getId)
          // var td = document.getElementById('td' + this.getId)
          // loader.style.display = 'block';
          // td.style.display = 'none';

          this.emailContentLoad("save");
          (this.loading = false),
            (this.loadingS = true),
            this.$store.dispatch("checkErrorAndSendToast", [res, "Success"]);
        })
        .catch((err) => {
          console.log("post err", err);
          (this.loading = false),
            (this.loadingS = true),
            this.$store.dispatch("checkErrorAndSendToast", [res, "Success"]);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loader-show {
  display: none;
}
</style>
