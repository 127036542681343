<template>
  <!--**********************************
            Main wrapper start
        ***********************************-->
  <div id="main-wrapper">
    <section class="banner">
      <div class="container">
        <div class="row">
          <div class="col-lg-6">
            <div class="content">
              <div class="d-flex justify-content-center">
                <div class="icon">
                  <i class="fa-solid fa-comment"></i>
                </div>
              </div>
              <h1>{{lan.quotePrev.Quote_Presentation}}</h1>
              <h2>
                {{lan.quotePrev.Thank_you_for_the_opportunity}}
              </h2>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="banner-images">
              <img
                src="https://d32ijn7u0aqfv4.cloudfront.net/wp/wp-content/uploads/20181025162721/woman-making-online-credit-card-payment_PL17125_7-Simple-Expert-Approved-Ways-To-Boost-Your-Credit-Score_1435403_st.jpg"
                class="img-fluid"
                alt="img not found"
              />
            </div>
          </div>
        </div>
      </div>
    </section>


    <div class="container">
      <div
        v-if="disabledAfterBooking && isBooked"
        class="msg-highlight d-inline-block p-3 my-2"
      >
        <h6 v-if="Preview_details.length == 1" class="mb-0">
          {{ lan.quotePrev.This_conference_venue_has_been_already_booked }}
        </h6>
        <h6 v-else class="mb-0">
          {{ lan.quotePrev.This_conference_has_been_already_booked_in }} <span class="fs-20">{{ booked_venue_details.venue_name }}</span>
        </h6>
    </div>
      <div v-if="If_Checked" class="row">
        <div class="col-12 mt-5">
          <div class="card">
            <div class="card-header pb-2 d-block d-sm-flex flex-wrap border-0">
              <h4 class="mb-0 fs-20 text-black">{{lan.quotePrev.Conference_Venues}}</h4>
            </div>
            <div class="card-body tab-content">
              <div class="tab-pane active show fade" id="monthly">
                <div class="table-responsive">
                  <table class="text-center bg-info-hover tr-rounded order-tbl">
                    <thead>
                      <tr>
                        <th>{{lan.quotePrev.Venue}}</th>
                        <th>{{lan.quotePrev.Total_price}}</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in Preview_details" :key="index">
                        <td
                          v-if="
                            data.bidder_detail[0].present_to_customer ==
                              'true' ||
                            data.bidder_detail[0].present_to_customer == true
                          "
                          class="align-middle"
                        >
                          {{ data.account_detail.venue_name }}
                        </td>
                        <td
                          v-if="
                            data.bidder_detail[0].present_to_customer ==
                              'true' ||
                            data.bidder_detail[0].present_to_customer == true
                          "
                          class="align-middle"
                        >
                          {{ data.price.toLocaleString() }} SEK
                        </td>
                        <td
                          v-if="
                            data.bidder_detail[0].present_to_customer ==
                              'true' ||
                            data.bidder_detail[0].present_to_customer == true
                          "
                        >
                        <button @click="view(index)" class="filter-btn m-0 blue-btn">
                          {{lan.quotePrev.View}}
                        </button>
                        </td>
                        <td
                          v-if="
                            data.bidder_detail[0].present_to_customer ==
                              'true' ||
                            data.bidder_detail[0].present_to_customer == true
                          "
                        >
                        <a href="#myModal" class="trigger-btn" data-toggle="modal" 
                            @click="
                              setValueForProps(
                                data.account_id,
                                data.opportunity_id,
                                data.quote_id,
                                data.account_detail.primary_contact,
                                index
                              )
                            ">
                          <button
                            :id="'book_button_id_' + index"
                            class="filter-btn m-0 blue-btn book-button-hide"
                            :class="{ darkbtn: changeToDarkBlue }"
                            :disabled="disabledAfterBooking"
                          >
                            <div class="lds-ring" v-show="loading">
                              <div></div>
                              <div></div>
                              <div></div>
                              <div></div>
                            </div>
                            <span v-show="loadingS"> {{lan.quotePrev.Book}}</span>
                            <span v-show="booked"> {{lan.quotePrev.Booked}}</span>
                          </button>

                        </a>
                        </td>
                      </tr>

                      <!-- <tr>
                        <td>Conference Venue 2</td>
                        <td>123 000SEK not incl VAT</td>
                        <td>
                          <a href="#venueTwo"
                            ><button class="filter-btn m-0 blue-btn">
                              View
                            </button></a
                          >
                        </td>
                        <td>
                          <button class="filter-btn m-0 blue-btn">Book</button>
                        </td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 mb-4">
          <!-- <div v-for="(data, index) in Preview_details" :key="index">
            <button class="filter-btn ml-0 mb-0" @click="set_google_mappreview(data.account_detail.google_map_location)" data-toggle="modal" data-target="#mapPreview" > {{ data.account_detail.venue_name }}</button>
          </div> -->
          <!-- :src="`//maps.google.com/maps?53.3381768,-6.2613077&z=15&output=embed`" -->
          <div id="map" style="width: 100%; height: 500px"></div>
          <div class="center-map" v-show="value">
            <h2>{{lan.quotePrev.Map_loading}}</h2>
            <div class="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>

        <div
          :id="'view' + index"
          class="col-xl-12 col-xxl-12"
          v-for="(preview, index) in Preview_details"
          :key="index"
        >
          <div
            v-if="
              preview.bidder_detail[0].present_to_customer == 'true' ||
              preview.bidder_detail[0].present_to_customer == true
            "
            class="row" 
          >
            <div class="col-sm-12">
              <div class="row">
                <div class="card">
                  <div class="card-header border-0 pb-0">
                    <h4 class="mb-0 fs-24 text-black">
                      {{ preview.account_detail.venue_name }}
                    </h4>
                  </div>
                  <div class="card-body pb-0">
                    <!-- <h6>{{ data.account_detail.name }}</h6> -->
                    <!-- <span>{{ data.account_detail.description }}</span> -->

                    <div class="row mt-4">
                      <div
                        v-if="preview.account_detail.image != ''"
                        class="col-md-6"
                      >
                        <div class="customer-images">
                          <img :src="preview.account_detail.image" alt="" />
                        </div>
                      </div>
                      <div
                        v-if="
                          preview.account_detail.other_images &&
                          preview.account_detail.other_images.length
                        "
                        class="col-md-6"
                      >
                        <div class="row">
                          <div
                            v-for="image in preview.account_detail.other_images"
                            :key="image"
                            class="col-md-6"
                          >
                            <div class="customer-images-01">
                              <img :src="image" :alt="image" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />

                    <div class="row predifined_text my-4">
                      <!-- <h4 class="fs-20 my-3 text-black">Additional Information</h4> -->
                      <div class="col-md-9 brdr p-3">
                        <div class="bio">
                          <h2 class="text-left fs-22">
                            {{ preview.account_detail.title }}
                          </h2>
                          <p
                          v-if="!preview.showDescription"
                            class="description-text mt-3"
                            v-html="
                              preview.account_detail.description.substring(
                                0,
                                800
                              )
                            "
                          ></p>
                          <p
                            v-else
                            class="description-text mt-3"
                            v-html="preview.account_detail.description"
                          ></p>

                          <div
                            v-if="
                              preview.account_detail.description.length > 800
                            "
                          >
                          <button
                          @click="preview.showDescription = !preview.showDescription"
                          class="view-button"
                      >
                          {{ preview.showDescription ? lan.quotePrev.View_less : lan.quotePrev.View_more }}
                      </button>
                          </div>
                        </div>
                        <div
                          class="bio"
                          v-if="
                            preview.text != ''
                              ? preview.text[4].predefined_text_area != ''
                              : preview.text != ''
                          "
                        >
                          <h3 class="text-left fs-20">  {{lan.quotePrev.Additional_Information}}</h3>
                          <span class="addInfoRich mt-3" v-html="preview.text[4].predefined_text_area"></span>
                        </div>
                      </div>

                      <div
                        class="col-md-3 p-3 text brdr right"
                        style="border-left: 0px"
                      >
                        <h4>
                          <span class="fs-16 font-weight-normal">{{lan.quotePrev.Total_price}}:</span>
                          {{ preview.price.toLocaleString() }} SEK
                        </h4>
                        <h4 class="mt-3 mb-4 font-weight-normal">
                          <span class="fs-16">{{lan.quotePrev.Total_Discount}}:</span
                          >
                          {{ preview.total_discount.toLocaleString() }} SEK
                        </h4>
                        <h4 >
                          <span class="fs-16 font-weight-normal">{{lan.quotePrev.Price}} -</span>
                          {{ preview.price_should_be == 'excl vat' ? lan.partners[2].quote_page[0].Ex_vat : lan.partners[2].quote_page[0].Inc_vat }}
                        </h4>

                        <p class="fs-16 text-left">
                          {{lan.quotePrev.Booking_terms}} -
                          <b
                            v-if="
                              preview.free_of_charge_cancellation.date != ''
                            "
                          >
                            {{ preview.free_of_charge_cancellation.date }}
                            &nbsp;{{ preview.free_of_charge_cancellation.week != 'Week' ? lan.Months : lan.Weeks}}
                          </b>
                          <span v-else> {{lan.quotePrev.No_Booking_terms}} </span>
                        </p>
                      </div>
                    </div>
                    <hr />

                    <div
                      v-if="preview.price_for_accomodation.length"
                      class="row mt-2 mb-0"
                    >
                      <div class="col-12">
                        <h4 class="fs-20 my-3 text-black">{{lan.quotePrev.Accomodation}}</h4>
                        <div class="table-responsive">
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th>{{lan.quotePrev.Date}}</th>
                                <th>{{lan.quotePrev.Products}}</th>
                                <th>{{lan.quotePrev.Description}}</th>
                                <th>{{lan.quotePrev.Participants}}</th>
                                <th>{{lan.quotePrev.Ordinary_Price}}</th>
                                <th>{{lan.quotePrev.Price_for_this_quote}}</th>
                                <th>{{lan.quotePrev.Total}}</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr
                                v-for="(
                                  data, index
                                ) in preview.price_for_accomodation"
                                :key="index"
                              >
                                <td>{{ data.date }}</td>
                                <td class="text-left">
                                  {{ data.product.product_name }}
                                </td>
                                <td
                                  class="text-left"
                                  style="white-space: initial; width: 100%"
                                >
                                  {{ data.product.product_description }}
                                </td>
                                <td>
                                  {{
                                    preview.response == "Yes"
                                      ? data.room.toLocaleString()
                                      : data.room_we_offer.toLocaleString()
                                  }}
                                </td>
                                <td>
                                  {{ data.ordinary_price.toLocaleString() }}
                                </td>
                                <td>
                                  {{
                                    data.price_for_this_quote.toLocaleString()
                                  }}
                                </td>
                                <td class="text-right">
                                  {{
                                    preview.response == "Yes"
                                      ? (
                                          data.room * data.price_for_this_quote
                                        ).toLocaleString()
                                      : (
                                          data.room_we_offer *
                                          data.price_for_this_quote
                                        ).toLocaleString()
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="preview.price_for_meals.length"
                      class="row mt-2 mb-0"
                    >
                      <div class="col-12">
                        <h4 class="fs-20 my-3 text-black"><th>{{lan.quotePrev.Meals}}</th></h4>
                        <div class="table-responsive">
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th>{{lan.quotePrev.Date}}</th>
                                <th>{{lan.quotePrev.Products}}</th>
                                <th>{{lan.quotePrev.Description}}</th>
                                <th>{{lan.quotePrev.Participants}}</th>
                                <th>{{lan.quotePrev.Ordinary_Price}}</th>
                                <th>{{lan.quotePrev.Price_for_this_quote}}</th>
                                <th>{{lan.quotePrev.Total}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(data, index) in preview.price_for_meals"
                                :key="index"
                              >
                                <td>{{ data.date }}</td>
                                <td class="text-left">
                                  {{ data.product.product_name }}
                                </td>
                                <td
                                  class="text-left"
                                  style="white-space: initial; width: 100%"
                                >
                                  {{ data.product.product_description }}
                                </td>
                                <td>{{ data.room.toLocaleString() }}</td>
                                <td>
                                  {{ data.ordinary_price.toLocaleString() }}
                                </td>
                                <td>
                                  {{
                                    data.price_for_this_quote.toLocaleString()
                                  }}
                                </td>
                                <td class="text-right">
                                  {{
                                    (
                                      data.room * data.price_for_this_quote
                                    ).toLocaleString()
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>

                    <div
                    v-if="preview.price_for_other_products.length"
                    class="row mt-3 mb-2"
                  >
                    <div class="col-12">
                      <h4 class="fs-20 my-3 text-black"><th>{{lan.quotePrev.Other_Products}}</th></h4>
                      <div class="table-responsive">
                        <table
                          class="text-center bg-info-hover tr-rounded order-tbl"
                        >
                          <thead>
                            <tr>
                              <th>{{lan.quotePrev.Date}}</th>
                              <th>{{lan.quotePrev.Products}}</th>
                              <th>{{lan.quotePrev.Description}}</th>
                              <th>{{lan.quotePrev.Participants}}</th>
                              <th>{{lan.quotePrev.Ordinary_Price}}</th>
                              <th>{{lan.quotePrev.Price_for_this_quote}}</th>
                              <th>{{lan.quotePrev.Total}}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                data, index
                              ) in preview.price_for_other_products"
                              :key="index"
                            >
                              <td>{{ data.date }}</td>
                              <td class="text-left">
                                {{ data.product.product_name }}
                              </td>
                              <td class="text-left">
                                {{ data.product.product_description }}
                              </td>
                              <td>
                                {{ data.room.toLocaleString() }}
                              </td>
                              <td>
                                {{ data.ordinary_price.toLocaleString() }}
                              </td>
                              <td>
                                {{
                                  data.price_for_this_quote.toLocaleString()
                                }}
                              </td>
                              <td class="text-right">
                                {{
                                  (data.room * data.price_for_this_quote).toLocaleString()
                                }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                    <div
                      v-if="preview.price_for_conference_room.length"
                      class="row mt-2 mb-0"
                    >
                      <div class="col-12">
                        <h4 class="fs-20 my-3 text-black">{{lan.quotePrev.Conference_Hall}}</h4>
                        <div class="table-responsive">
                          <table
                            class="text-center bg-info-hover tr-rounded order-tbl"
                          >
                            <thead>
                              <tr>
                                <th>{{lan.quotePrev.Date}}</th>
                                <th>{{lan.quotePrev.Products}}</th>
                                <th>{{lan.quotePrev.Number_of_max_people}}</th>
                                <th>{{lan.quotePrev.Setup}}</th>
                                <th>{{lan.quotePrev.Ordinary_Price}}</th>
                                <th>{{lan.quotePrev.Price_for_this_quote}}</th>
                                <th>{{lan.quotePrev.Total}}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(
                                  data, index
                                ) in preview.price_for_conference_room"
                                :key="index"
                              >
                                <td>{{ data.date }}</td>
                                <td class="text-left">
                                  {{ data.product.product_name }}
                                </td>
                                <td>
                                  {{
                                    data.request_size_we_can_offer.toLocaleString()
                                  }}
                                </td>
                                <td class="text-left">
                                  {{checkSeating_type_transaltion(data.seating_we_can_offer)}}
                                </td>
                                <td>
                                  {{ data.request_size_we_can_offer == 'N/A' ? 'N/A' :
                                    parseInt(
                                      data.ordinary_price
                                    ).toLocaleString()
                                  }}
                                </td>
                                <td>
                                  {{ data.request_size_we_can_offer == 'N/A' ? 'N/A' :
                                    parseInt(
                                      data.fee_confrence_room
                                    ).toLocaleString()
                                  }}
                                </td>
                                <td class="text-right">
                                  {{ data.request_size_we_can_offer == 'N/A' ? 'N/A' :
                                    parseInt(
                                      data.fee_confrence_room
                                    ).toLocaleString()
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>


                    <div class="text-right mx-1 mt-sm-2 mt-4">
                      <h4>
                        <span class="fs-16 font-weight-normal">{{lan.quotePrev.Total_price}}:
                        </span>
                        {{ preview.price.toLocaleString() }} SEK
                      </h4>
                      <h4 class="mt-3 mb-4">
                        <span class="fs-16 font-weight-normal">{{lan.quotePrev.Total_Discount}}:</span
                        >
                        {{ preview.total_discount.toLocaleString() }} SEK
                      </h4>
                    </div>

                    <hr />

                    <div
                      class="my-4"
                      v-if="preview.additional_questions.length"
                    >
                      <h4>
                        {{lan.quotePrev.Answers_from_partner}}
                      </h4>
                      <div class="brdr my-4">
                        <div
                          class="u-list text py-4"
                          style="border: 1px solid lightgray"
                          v-for="(data, index) in preview.additional_questions"
                          :key="index"
                        >
                          <div class="d-flex">
                            <h4 class="col-md-2 col-4 fs-14 pr-0">
                              <b>{{lan.quotePrev.Question}} </b>:
                            </h4>
                            <p
                              class="col-md-10 col-8 fs-14 font-weight-bold pl-0"
                            >
                              {{ data.question }}
                            </p>
                          </div>
                          <!-- <div v-if="data.inputType == 'dropdown'" class="d-flex">
                            <h4 class="col-2 fs-14 pr-0"><b>Answer :</b></h4>
                            <p v-for="(drop, index) in data.dropdown" :key="index" class="col-10 fs-14 pl-0">{{ index ==  data.answer }}</p>
                          </div> -->
                          <div
                            v-if="data.inputType == 'dropdown'"
                            class="d-flex"
                          >
                            <h4 class="col-md-2 col-4 fs-14 pr-0">
                              <b>{{lan.quotePrev.Answer}} :</b>
                            </h4>
                            <p
                              v-for="drop in data.answertags"
                              class="col-md-1 col-8 fs-12 pl-0"
                            >
                              {{ drop.name }}
                            </p>
                          </div>
                          <div v-else class="d-flex">
                            <h4 class="col-md-2 col-4 fs-14 pr-0">
                              <b>{{lan.quotePrev.Answer}} :</b>
                            </h4>
                            <p class="col-md-10 col-8 fs-16 pl-0">
                              {{ data.answer }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="
                        preview.attachments && preview.attachments.length != 0
                      "
                      class="p-4 text brdr my-4"
                    >
                      <h4>{{lan.quotePrev.Attached_files}}</h4>
                      <p class="fs-16 d-flex mt-3">
                        <a
                          v-for="(image, index) in preview.attachments"
                          :key="index"
                          :href="image.file"
                          target="_blank"
                          class="mr-4"
                        >
                        <div class="d-flex align-items-center">
                          <strong class="text-muted mr-2">{{ index+1 }} </strong> 
                          <div class="d-flex">
                            <img
                              :src="checkimage(image.file)"
                              alt="PDF"
                              height="40"
                              width="40"
                            />
                            <div class="d-flex flex-column ml-2">
                              <small class="text-muted"> <strong>{{ image.name }}</strong></small>
                              <small class="text-muted">{{ image.message }}</small>
                            </div>
                          </div>
                        </div>
                      
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!loader" v-else style="margin: 100px">
        <h2>{{lan.quotePrev.Please_Select_Atleast_One_Bidder}}</h2>
      </div>

      <div v-show="loader" style="margin: 100px">
        <h2>{{lan.quotePrev.Please_wait_just_a_few_seconds_fetching_details}}</h2>
        <div class="lds-ellipsis">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
    <ViewMapLoctions :mapLocation="google_map_location_preview" />
    <deleteModal @book_Venvue="book" check_for="Book" :value="setValue"/>
  </div>
</template>

<script>
import axios from "axios";
import ViewMapLoctions from "../../components/mapPreview";
import deleteModal from "../../components/deleteModal";

export default {
  name: "Quote Presentation Preview",
  components: {
    ViewMapLoctions,
    deleteModal
  },
  data() {
    return {
      Preview_details: [],
      google_map_location_preview: {},
      google_map_location: [],
      loadingS: true,
      loading: false,
      Booked: false,
      changeToDarkBlue: false,
      disabledAfterBooking: false,
      locations: [],
      placeIds: [],
      data: "",
      latitude: "",
      longitude: "",
      booked: false,
      value: true,
      If_Checked: false,
      loader: true,
      isComingFromOppo: false,
      isBooked: false,
      // other_images: ""
      setValue:{},
      booked_venue_details:{},

      response: "",
    };
  },
  created() {
    this.isComingFromOppo =
      this.$route.params.admin == 0 || this.$route.params.admin == "0"
        ? false
        : true;
    const LocalBidder = JSON.parse(
      localStorage.getItem("BidderForQuotePreview")
    );
    // console.log("routess--", this.$route);
    axios
      .get(
        axios.defaults.baseURL +
          "quote-presentation/opportunity_id=" +
          this.$route.params.id +
          "&admin=" +
          this.$route.params.admin,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res.data.data, "quote Presentation");
        if (Array.isArray(res.data.data)) {
          this.Preview_details = [];

          res.data.data.forEach((element, index) => {
            // console.log('element',element);
            if (!this.isComingFromOppo) {
              if (element.bidder_detail.length && (element.bidder_detail[0].present_to_customer == 'true' || element.bidder_detail[0].present_to_customer == true)) {
                this.push_Preview_details(element, index, "null", LocalBidder);
              }
            } 
            else {
              for (let i = 0; i < LocalBidder.length; i++) {
                const Local = LocalBidder[i];
                if (
                  Local.quote_id == element.quote_id &&
                  (Local.present_to_customer == true ||
                    Local.present_to_customer == "true")
                ) {
                  this.push_Preview_details(element, index, Local, LocalBidder);
                  // console.log('Local.quote_id 2---',Local.quote_id, element.quote_id);
                  // console.log('Local.present_to_customer 2---',Local.present_to_customer);
                }
              }
            }
          });
        } else {
          console.error("Invalid data:", res.data.data);
        }
        this.$nextTick(function () {
          // this.$refs.preview.$el.style.display = "block";
          this.Preview_details.forEach((item, index) => {
            if (
              item.bidder_detail[0].present_to_customer == true ||
              item.bidder_detail[0].present_to_customer == "true"
            ) {
              this.latitude = item.account_detail.google_map_location.latitude;
              this.longitude =
                item.account_detail.google_map_location.longitude;
              if (item.account_detail.google_map_location != "") {
                this.placeIds.push(
                  item.account_detail.google_map_location.place_id
                );
              }
            }
          });
        });
        this.loader = false;
        console.log('push_Preview_details after end-*-',this.Preview_details);
        this.Preview_details.sort((a, b) => a.bidder_detail[0].index_priority - b.bidder_detail[0].index_priority);
      })
      .catch((err) => {
        console.log("err", err);
        if (err.message == "Network Error") {
          this.AddToast(err.message, "Faild", "error_1");
        }
      });
  },


  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
    languageName() {
      return this.$store.getters.whichLanguageName;
    },
  },
watch:{
  isBooked(newValue, oldValue){
    console.log('newValue',newValue);
    console.log('oldValue',oldValue);
    if (this.isBooked) {
      console.log('isBooked--',this.isBooked);
      this.disabledAfterBooking = true;
      this.changeToDarkBlue = true;
      this.AddToast(
        this.lan.quotePrev.This_Opportunity_already_booked,
        "Information",
        "info_1"
      );
    }

  }
},

  mounted() {
    window.scrollTo(0, 0);
    var self = this;
    var map;

    setTimeout(() => {
      map = new google.maps.Map(document.getElementById("map"), {
        center: { lat: parseInt(self.latitude), lng: parseInt(self.longitude) },
        zoom: 6,
      });
      var service = new google.maps.places.PlacesService(map);
      self.placeIds.forEach(function (placeId) {
        service.getDetails(
          {
            placeId: placeId,
          },
          function (place, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              var marker = new google.maps.Marker({
                position: place.geometry.location,
                map: map,
              });
              marker.addListener("click", function () {
                self.openMap(place.geometry.location);
              });
              self.value = false;
            }
          }
        );
      });
      console.log("lat-", self.latitude);
      console.log("log-", self.longitude);
    }, 3000);
    window.scrollTo({ top: 0, left: 0 });
  },
  methods: {
    push_Preview_details(element, index, Local, LocalBidder) {
      if (element.bidder_detail.length) {
        console.log("push_Preview_details --element--", element);
        const text = { ...element };
        const accountDetail = { ...element.account_detail };
        const price_for_accomodation = {
          ...element,
        };
        const price_for_other_products = {
          ...element,
        };
        const price_for_conference_room = {
          ...element,
        };
        const price_for_meals = {
          ...element,
        };
        this.response = element.response;

        if(element.additional_questions != ''){
          var additional_questions = JSON.parse(element.additional_questions)
        }else{
          var additional_questions = element.additional_questions
        }

        const free_of_charge_cancellation = {
          ...element,
        };
        const attachments = element.attachments
        const Booked = { ...element };
        const Info_regarding_file = element.info_regarding_file

        if (Booked.booked == 1 || Booked.booked == "1") {
          this.isBooked = true;
          this.booked_venue_details = {
            venue_name: Booked.account_detail.venue_name
          }
        }

        if (text.text != "") {
          text.text = JSON.parse(text.text);
        }

        if (accountDetail.other_images != "") {
          let str = accountDetail.other_images;
          if (str[0] == ",") {
            str = str.slice(1);
          }
          accountDetail.other_images = str.split(",", 4);
        }
        if (accountDetail.google_map_location.length) {
          accountDetail.google_map_location = JSON.parse(
            accountDetail.google_map_location
          );
        }
        // }
        // console.log("price_for_accomodation",price_for_accomodation.price_for_accomodation);
        if (price_for_accomodation.price_for_accomodation != "") {
          price_for_accomodation.price_for_accomodation = JSON.parse(
            price_for_accomodation.price_for_accomodation
          );
        }
        // console.log("price_for_meals",price_for_meals.price_for_meals);
        if (price_for_meals.price_for_meals != "") {
          price_for_meals.price_for_meals = JSON.parse(
            price_for_meals.price_for_meals
          );
        }
        // console.log("price_for_other_products");
        if (price_for_other_products.price_for_other_products != "") {
          price_for_other_products.price_for_other_products = JSON.parse(
            price_for_other_products.price_for_other_products
          );
        }
        // console.log("price_for_conference_room");
        if (price_for_conference_room.price_for_conference_room != "") {
          price_for_conference_room.price_for_conference_room = JSON.parse(
            price_for_conference_room.price_for_conference_room
          );
        }
        // **************additional_questions start************
        if(!this.isComingFromOppo){
          if (additional_questions != "") {
            const quaetion_Array = []
            var oppo_additional_questions = JSON.parse(
              element.opportunity_detail.additional_questions
            );
            oppo_additional_questions.forEach((question) => {
              if(question.isShowOrNot){
                const matchedIndex = additional_questions.findIndex(item => item.row_id === question.row_id);
                if(matchedIndex !== -1) {
                  quaetion_Array.push(additional_questions[matchedIndex]);
                }
                else{
                  quaetion_Array.push(question);
                }
              }
            })
            additional_questions = quaetion_Array

          }
        }else{
          const quaetion_Array = []
          if (additional_questions != "") {
            const Local_Additional_Questions = JSON.parse(
              localStorage.getItem("Additional_Questions_ForQuotePreview")
            );
            Local_Additional_Questions.forEach((question) => {
              if(question.isShowOrNot){
                const matchedIndex = additional_questions.findIndex(item => item.row_id === question.row_id);
                if(matchedIndex !== -1) {
                  quaetion_Array.push(additional_questions[matchedIndex]);
                }
                else{
                  quaetion_Array.push(question);
                }
              }
            })
            additional_questions = quaetion_Array
            // additional_questions.additional_questions = JSON.parse(
            //   additional_questions.additional_questions
            // );
          }
        }
        // **************additional_questions end************

        // console.log("free_of_charge_cancellation");
        if (free_of_charge_cancellation.free_of_charge_cancellation != "") {
          free_of_charge_cancellation.free_of_charge_cancellation = JSON.parse(
            free_of_charge_cancellation.free_of_charge_cancellation
          );
        }
        // console.log("attachments");
        if (!this.isComingFromOppo) {
          if (
            element.bidder_detail[0].present_to_customer == "true" ||
            element.bidder_detail[0].present_to_customer == true
          ) {
            this.If_Checked = true;
          }
        } else {
          LocalBidder.forEach((bidder) => {
            // console.log('element.bidder_detail',element.bidder_detail)
            if (element.bidder_detail && element.bidder_detail.length != 0) {
              if (bidder.bidder_id == element.bidder_detail[0].bidder_id) {
                element.bidder_detail[0].present_to_customer =
                  bidder.present_to_customer;
                if (
                  bidder.present_to_customer == "true" ||
                  bidder.present_to_customer == true
                ) {
                  this.If_Checked = true;
                }
              }
            } else {
              element.bidder_detail.push({
                present_to_customer: (bidder.present_to_customer = false),
              });
            }
          });
        }

        console.log('Info_regarding_file-', Info_regarding_file);
        console.log('attachments-', attachments);

        // if (
        //   attachments.attachments != "" &&
        //   attachments.attachments != [] &&
        //   attachments.attachments[0] != null
        // ) {
        //   const file = JSON.parse(attachments.attachments);
        //   const newObject = {};
        //   for (const key in file) {
        //     if (file.hasOwnProperty(key)) {
        //       const value = file[key];
        //       if (value != null && value != "null") {
        //         const keyy = value.split("/").pop();
        //         const fileName = keyy.split(".")[0];
        //         newObject[fileName] = value;
        //       }
        //     }
        //   }
        //   // console.log('newObject',newObject);
        //   attachments.attachments = newObject;
        // }
        if (attachments != "" && attachments != [] && attachments != [null] && attachments != null && attachments != '[null]') {
          var preview_all_attachments = attachments.split(",").filter(Boolean);
          var all_attachments = []
          var info_file = JSON.parse(Info_regarding_file);
          if(preview_all_attachments != null && preview_all_attachments != 'null' && preview_all_attachments != '[null]'){
            preview_all_attachments.map((item, index) => {
              if(item != null && item != "" && item != "null"){
                if (item.includes(".pdf")) {
                  all_attachments.push({
                    file: item,
                    preview: '',
                    name: info_file[index]?.name || '',
                    type: info_file[index]?.type  || 'pdf',
                    message: info_file[index]?.message  || '',
                    index: info_file[index]?.index  || ''
                  });
                } else {
                  all_attachments.push({
                    file: item,
                    preview: '',
                    name: info_file[index]?.name  || '',
                    type: info_file[index]?.type  || 'jpg',
                    message: info_file[index]?.message  || '',
                    index: info_file[index]?.index  || ''
                  });
                }
              }
            });
          }
        }


        console.log('all_attachments--', all_attachments);
        this.Preview_details.push({
          ...element,
          account_detail: accountDetail,
          text: text.text,
          price_for_accomodation: price_for_accomodation.price_for_accomodation,
          price_for_meals: price_for_meals.price_for_meals,
          price_for_other_products:
            price_for_other_products.price_for_other_products,
          price_for_conference_room:
            price_for_conference_room.price_for_conference_room,
          additional_questions: additional_questions,
          free_of_charge_cancellation:
            free_of_charge_cancellation.free_of_charge_cancellation,
          attachments: all_attachments,
        });
        console.log("Preview_details--*--", this.Preview_details);
      }
    },
    openMap(location) {
      var url =
        "https://www.google.com/maps/search/?api=1&query=" +
        location.lat() +
        "," +
        location.lng();
      window.open(url, "_blank");
    },
    checkimage(image) {
      var ext = image.split(/[#?]/)[0].split(".").pop().trim();
      if (ext == "pdf") {
        return "https://upload.wikimedia.org/wikipedia/commons/8/87/PDF_file_icon.svg";
      } else {
        return "https://upload.wikimedia.org/wikipedia/vi/9/90/Microsoft_Photos_Icon_on_Windows_10.png";
      }
    },
    setValueForProps(account_id, opportunity_id, quote_id, contact_id, index_value){
      console.log('change--',account_id);
      this.setValue = {
        account_id:account_id,
        opportunity_id:opportunity_id,
        quote_id:quote_id,
        contact_id:contact_id,
        index_value:index_value
      }
      console.log('change--setValue-',this.setValue);
    },
    book() {
      // const LocalBidder = JSON.parse(
      //   localStorage.getItem("BidderForQuotePreview")
      // );
      var account_id = this.setValue.account_id
      var opportunity_id = this.setValue.opportunity_id
      var quote_id = this.setValue.quote_id
      var contact_id = this.setValue.contact_id
      var index_value = this.setValue.index_value
      document.getElementById("deletemodal").click();
      console.log("setValue in book--", this.setValue);

      // return
      
      for (let index = 0; index < this.Preview_details.length; index++) {
        // const isOpportunity = this.Preview_details[index].bidder_detail[0].present_to_customer
        // const notOpportunity = LocalBidder[index].present_to_customer
        if (index != index_value) {
          console.log("index 1--", index);
          console.log("index_value 2--", index_value);
          var book_btn = document.getElementById("book_button_id_" + index);
          book_btn.style.display = "none";
          console.log("yes 1--", book_btn);
        }
      }

      this.loadingS = false;
      this.loading = true;
      //   ****************Get opportunity by ID****************************
      axios
        .get(axios.defaults.baseURL + "opportunity/outside-partner/" + opportunity_id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("res ooppo--", res);

          // *************GET Lead details by id**********
          if (res.data.data[0].self == 0) {
            console.log("lead data enter", res.data.data[0].self);
            axios
              .get(
                axios.defaults.baseURL + "lead/" + res.data.data[0].lead_id,
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                  },
                }
              )
              .then((res) => {
                // this.leadsDetails = res.data.data[0];
                console.log(
                  "bookByCustomer 2-",
                  account_id,
                  opportunity_id,
                  quote_id,
                  contact_id,
                  res.data.data,
                  res.data.data[0].customer_id
                );
                this.bookByCustomer(
                  account_id,
                  opportunity_id,
                  quote_id,
                  contact_id,
                  res.data.data[0].customer_id
                );
              })
              .catch((err) => {
                console.log("err", err);
                if (err.response.statusText == "Unauthorized") {
                  this.$router.push("/login").then(() => {
                    this.$router.go();
                  });
                }
                this.loadingS = true;
                this.loading = false;
              });
          } else {
            console.log(
              "bookByCustomer 1-",
              account_id,
              opportunity_id,
              contact_id,
              res.data.data[0].email
            );
            this.bookByCustomer(
              account_id,
              opportunity_id,
              quote_id,
              contact_id,
              res.data.data[0].contact_id
            );
            // this.customer_email = res.data.data[0].email;
          }
          // ******************************
        })
        .catch((err) => {
          this.loadingS = true;
          this.loading = false;
          console.log("err opportunity", err);
        });
      // ********************************
    },
    bookByCustomer(account_id, opportunity_id, quote_id, contact_id, customer_id) {
      console.log(
        "bookByCustomer !!:-",
        account_id,
        opportunity_id,
        quote_id,
        contact_id,
        customer_id
      );
      // return
      var formData = new FormData();
      formData.append("contact_id", contact_id);
      formData.append("customer_id", customer_id);
      formData.append("account_id", account_id);
      formData.append("quote_id", quote_id);
      formData.append("opportunity_id", opportunity_id);
      axios
        .post(
          axios.defaults.baseURL + "book-venue/" + opportunity_id,
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          axios
            .post(axios.defaults.baseURL + "send-notification", formData, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              console.log("res", res);
              this.loadingS = false;
              this.loading = false;
              this.booked = true;
              this.changeToDarkBlue = true;
              this.disabledAfterBooking = true;
              // this.$router.push({ path: "/opportunity" });
              this.$store.dispatch("checkErrorAndSendToast", [
                response,
                "Success",
              ]);
              // this.AddToast(
              //   "Venue Booked Successfully",
              //   " Success ",
              //   "success_1"
              // );
            })
            .catch((err) => {
              this.loadingS = true;
              this.loading = false;
              console.log("err", err);
              this.AddToast(err.response.data.message, "Faild", "error_1");
            });
        })
        .catch((err) => {
          this.loadingS = true;
          this.loading = false;
          console.log("err", err);
          this.AddToast(err.response.data.message, "Faild", "error_1");
        });
    },
    set_google_mappreview(addressData){
      console.log('addressData',addressData);
      this.google_map_location_preview = {
        administrative_area_level_1: addressData.administrative_area_level_1,
        // administrative_area_level_2: addressData.administrative_area_level_2,
        country: addressData.country,
        route: addressData.route,
        postal_code: addressData.postal_code,
        longitude: addressData.longitude,
        latitude: addressData.latitude,
        place_id: addressData.place_id,
        url: addressData.url,
        formatted_address: addressData.formatted_address,
      };
      // console.log('placeResultData',placeResultData);
      // console.log('is',id);
    },
    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
    view(index){
      console.log('index--*--', index);
      setTimeout(function() {
      const afterSubmitElement = document.getElementById('view' + index);
      console.log('afterSubmitElement--*--', afterSubmitElement);
      if (afterSubmitElement) {
            const scrollPosition = afterSubmitElement.offsetTop - 10; // Adjust this value as needed
            window.scrollTo({
                top: scrollPosition,
                behavior: 'smooth'
            });
        }
      }, 100);
      // window.location.assign("#" + index ).scrollIntoView({ behavior: 'smooth' });
    },
    checkSeating_type_transaltion(seatingToCheck){
      console.log('checkSeating_type_transaltion', seatingToCheck);
      if(seatingToCheck == 'Cinema Seating'){
        return this.lan.partners[2].quote_page[0].Cinema_Seating;
      }
      else if(seatingToCheck == 'U-shaped table'){
        return this.lan.partners[2].quote_page[0].Ushaped_table;
      }
      else if(seatingToCheck == 'Boardroom'){
        return this.lan.partners[2].quote_page[0].Boardroom;
      }
      else if(seatingToCheck == 'School Seating'){
        return this.lan.partners[2].quote_page[0].School_Seating;
      }
      else if(seatingToCheck == 'Islands'){
        return this.lan.partners[2].quote_page[0].Islands;
      }
      else if(seatingToCheck == "Don't know at the moment"){
        return this.lan.partners[2].quote_page[0].Dont_know_at_the_moment;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#main-wrapper {
  margin-top: 43px;
}
.darkbtn {
  background-color: #0c1462 !important;
}
.text p {
  text-align: start;
}
.brdr {
  border: 0.5px solid lightgrey;
}
.brdr.right {
  background-color: #F9B5B0;
}
.bio h2,
.bio h3 {
  color: #5e5e5e;
  line-height: 43px;
  font-weight: 800;
}
.bio p {
  font-size: 16px;
  color: #666;
}
.bio span {
  font-size: 15px;
  color: #666;
}
.center-map {
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-device-width: 576px) {
  .bio h2 {
    font-size: 22px;
  }
}
</style>
