<template>
  <div>
    <!--**********************************
        Main wrapper start
      ***********************************-->
    <div id="main-wrapper">
      <!--**********************************
            Content body start
        ***********************************-->
      <div class="content-body pt-3 mb-5">
        <div class="container-fluid">
          <div
            class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
          >
            <h2 class="font-w600 title mb-2 mr-auto">{{ lan.admin[9].Task_page[0].Edit_task}}</h2>
            <a href="#myModal" class="trigger-btn" data-toggle="modal">
            <i
              v-show="value_T"
              class="fa fa-trash btn btn-danger shadow btn-xs sharp"
            ></i>
          </a>
            <div v-show="value_L"
                class="loadingio-spinner-rolling-yxlbb0cd94"
              >
                <div class="ldio-4c1f6xkxs72">
                  <div></div>
                </div>
              </div>
          </div>

          <div class="row">
            <div class="col-xl-12">
              <div class="card">
                <div class="card-body tab-content p-3">
                  <div class="tab-pane active show fade" id="monthly">
                    <div>
                      <div class="row">
                        <div class="col-sm-4 input">
                          <label
                            >{{ lan.admin[9].Task_page[0].Subject}}<span>*</span>
                            <span>{{
                              v$.subject.$error
                                ? " Subject name is required"
                                : ""
                            }}</span></label
                          ><br />
                          <input
                            type="text"
                            name="text"
                            placeholder="Subject"
                            v-model="state.subject"
                            :class="v$.subject.$error ? 'input-error' : ''"
                          />
                        </div>

                        <div class="col-sm-4 input">
                          <label class=""
                            >{{ lan.admin[9].Task_page[0].Assigned_to}}<span>*</span>
                            <span style="color: red">{{
                              v$.assign_to.$error
                                ? " Assign to is required"
                                : ""
                            }}</span> </label
                          ><br />
                          <select v-model="state.assign_to">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option
                              v-for="data in accountOwners"
                              :value="data.id"
                              :key="data.id"
                            >
                              {{ data.first_name }} {{ data.last_name }}
                            </option>
                          </select>
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Due_date}}</label><br />
                          <input
                            type="date"
                            name="text"
                            placeholder="Related contact"
                            v-model="due_date"
                          />
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Related_contact}}</label><br />

                          <model-list-select
                            v-if="contactsDetails"
                            :list="contactsDetails"
                            style="
                              height: 43px;
                              border: 1px solid #ccc;
                              border-radius: 2px;
                            "
                            v-model="related_contact"
                            option-value="id"
                            :custom-text="getNameContact"
                            placeholder="Please select one"
                          >
                          </model-list-select>
                          <!-- <select v-model="related_contact">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option
                              v-for="(data, index) in contactsDetails"
                              :key="index"
                              :value="data.id"
                            >
                              {{ data.first_name }} {{ data.last_name }}
                            </option>
                          </select> -->
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Related_Account}}</label><br />
                          <model-list-select
                            v-if="accountDetails"
                            :list="accountDetails"
                            style="
                              height: 43px;
                              border: 1px solid #ccc;
                              border-radius: 2px;
                            "
                            v-model="related_account"
                            option-value="account_id"
                            :custom-text="getName"
                            placeholder="Please select one"
                          >
                          </model-list-select>
                          <!-- <select v-model="related_account">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option
                              v-for="(data, index) in accountDetails"
                              :key="index"
                              :value="data.account_id"
                            >
                              {{ data.name }}
                            </option>
                          </select> -->
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Related_Opportunity}}</label><br />
                          <model-list-select
                            v-if="opportunitiesDetails"
                            :list="opportunitiesDetails"
                            style="
                              height: 43px;
                              border: 1px solid #ccc;
                              border-radius: 2px;
                            "
                            v-model="related_opportunity"
                            option-value="opportunity_id"
                            :custom-text="getNameOpportunity"
                            placeholder="Please select one"
                          >
                          </model-list-select>
                          <!-- <select v-model="related_opportunity">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option
                              v-for="(data, index) in opportunitiesDetails"
                              :key="index"
                              :value="data.opportunity_id"
                            >
                              {{ data.account_detail.name }}
                            </option>
                          </select> -->
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Priority}}</label><br />
                          <select v-model="priority">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option value="1">High</option>
                            <option value="2">Medium</option>
                            <option value="3">Low</option>
                          </select>
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Status}}</label><br />
                          <select v-model="status">
                            <option value="" disabled selected>
                              Please select one
                            </option>
                            <option value="1">Open</option>
                            <option value="2">Completed</option>
                          </select>
                        </div>

                        <div class="col-sm-4 input">
                          <label>{{ lan.admin[9].Task_page[0].Reminder}}</label><br />
                          <input
                            type="date"
                            name="text"
                            placeholder="Related Opportunity"
                            v-model="reminder"
                          />
                        </div>
                      </div>

                      <label>{{ lan.admin[9].Task_page[0].Comment}}<span>*</span></label
                      ><br />
                      <textarea
                        name=""
                        id=""
                        class="textarea"
                        rows="5"
                        v-model="comment"
                      >
Comment</textarea
                      >

                      <div class="button d-flex justify-content-end mt-4">
                        <!-- <button href="#"
                          ><button class="filter-btn">Save and new</button></button
                        > -->
                        <button class="search-btn up-btn ml-3" v-on:click="submit()">
                          <div class="lds-ring" v-show="loading">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                          <span v-show="loadingS">{{ lan.admin[9].Task_page[0].Save}}</span>
                        </button>
                        <button v-on:click="route()" class="filter-btn up-btn">
                          {{ lan.admin[9].Task_page[0].Cancel}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <deleteModal @delete="deletef" />
      <!--**********************************
            Content body end
        ***********************************-->
    </div>
    <!--**********************************
        Main wrapper end
      ***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import deleteModal from "../../../components/deleteModal";

import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from "vue-search-select"

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { reactive, computed } from "vue";
// @ is an alias to /src

export default {
  name: "Edit Note",
  components: { deleteModal, ModelListSelect },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      subject: "",
      assign_to: "",
    });

    const rules = computed(() => {
      return {
        subject: { required },
        assign_to: { required },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      due_date: "",
      comment: "",
      reminder: "",
      priority: "",
      status: "",
      related_account: "",
      related_contact: "",
      related_opportunity: "",

      accountOwners: "",
      accountDetails: "",
      contactsDetails: "",
      opportunitiesDetails: "",
      Id: this.$route.params.id,

      loading: false,
      loadingS: true,
      value_L: false,
      value_T: true,
    };
  },

  computed: {
    lan(){
      return this.$store.getters.whichLanguage
    }
  },

  mounted() {
    // for top load page
    window.scrollTo(0, 0);

    axios
      .get(axios.defaults.baseURL + "task/" + this.Id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.state.subject = res.data.data[0].subject;
        this.state.assign_to = res.data.data[0].assign_to;
        this.due_date = res.data.data[0].due_date.slice(0, 10);
        this.comment = res.data.data[0].comment;
        this.reminder = res.data.data[0].reminder.slice(0, 10);
        this.priority =
          res.data.data[0].priority == "" ? "" : res.data.data[0].priority;
        this.status =
          res.data.data[0].status == "" ? "" : res.data.data[0].status;
        this.related_account =
          res.data.data[0].account_id == "" ? "" : res.data.data[0].account_id;
        this.related_contact =
          res.data.data[0].contact_id == "" ? "" : res.data.data[0].contact_id;
        this.related_opportunity =
          res.data.data[0].opportunity_id == ""
            ? ""
            : res.data.data[0].opportunity_id;
      })
      .catch((err) => {
        console.log("err", err);
        if (err.response.statusText == "Unauthorized") {
          this.$router.push("/login").then(() => {
            this.$router.go();
          });
        }
      });

    // *************GET Accounts**********
    axios
      .get(axios.defaults.baseURL + "accounts", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.accountDetails = res.data.data;
      })
      .catch((err) => {
        console.log("err", err);
        if (err.response.statusText == "Unauthorized") {
          this.$router.push("/login").then(() => {
            this.$router.go();
          });
        }
      });

    // *************GET Contacts**********
    axios
      .get(axios.defaults.baseURL + "contacts", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.contactsDetails = res.data.data;
      })
      .catch((err) => {
        console.log("err", err);
      });

    // *************GET Opportunity**********
    axios
      .get(axios.defaults.baseURL + "opportunities", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.opportunitiesDetails = res.data.data;
        console.log("opportunitiesDetails", this.opportunitiesDetails);
      })
      .catch((err) => {
        console.log("err", err);
        if (err.response.statusText == "Unauthorized") {
          this.$router.push("/login").then(() => {
            this.$router.go();
          });
        }
      });
    //************** Account owner details
    axios
      .get(axios.defaults.baseURL + "get-account-owners", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.accountOwners = res.data.data;
        console.log("account owners", this.accountOwners);
      })
      .catch((err) => {
        console.log("err account owner", err);
      });
  },
  methods: {
    getName(item) {
      return `${item.name}`;
    },
    getNameOpportunity(item) {
      return `${item.account_detail.name}`;
    },
    getNameContact(item) {
      return `${item.first_name} ${item.last_name}`;
    },
    submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        (this.loading = true), (this.loadingS = false);
        var formData = new FormData();
        formData.append("subject", this.state.subject);
        formData.append("assign_to", this.state.assign_to);
        formData.append("due_date", "2023-02-17 13:13:00");
        // formData.append("due_date", this.due_date);
        formData.append("comment", this.comment);
        formData.append("reminder", "2023-02-17 13:13:00");
        // formData.append("reminder", this.reminder);
        formData.append("priority", this.priority);
        formData.append("status", this.status);
        formData.append("related_account", this.related_account);
        formData.append("related_contact", this.related_contact);
        formData.append("related_opportunity", this.related_opportunity);
        axios
          .post(axios.defaults.baseURL + "edit-task/" + this.Id, formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("res", res);
            (this.loading = false),
              (this.loadingS = true),
              this.$router.push({ path: "/tasks" });
              this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
          })
          .catch((err) => {
            (this.loading = false),
              (this.loadingS = true),
              console.log("err", err);
            this.AddToast(err.response.data.message, "Faild", "error_1");
          });
      } else {
        console.log("else", this.v$);
        this.AddToast("Please Fill Required Details", " info ", "info_1");
        return;
      }
    },
    deletef(){
      this.value_L = true
      this.value_T = false
      axios
      .post(axios.defaults.baseURL + "delete-task/" + this.Id ,{}, {
        headers: {
          Accept:"application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.value_L = false
      this.value_T = true
      this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
        document.getElementById('deletemodal').click();
        this.$router.push({ path: "/tasks" });
      })
      .catch((err) => {
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        this.value_L = false
      this.value_T = true
      });
    },

    route() {
      this.$router.push("/tasks");
    },

    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>
