<template>
  <!--**********************************
  Main wrapper start
***********************************-->
  <div id="main-wrapper">

    <!--**********************************
      Content body start
  ***********************************-->
    <div class="content-body pt-md-3 pt-0 mb-5">
      <div class="container-fluid">
        <div
          class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
        >
          <h2 class="font-w600 title mb-2 mr-auto">{{ lan.admin[14].Contact[0].Create_new_contact}}</h2>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div
                class="card-header pb-2 d-block d-sm-flex flex-wrap border-0"
              ></div>

              <div class="card-body p-4">
                <div>
                  <div class="row">
                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.admin[14].Contact[0].First_name}}<span>*</span
                        ><span style="color: red">{{
                          v$.first_name.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="text"
                        v-model="state.first_name"
                        :placeholder="lan.admin[15].Add_contact_page[0].First_name"
                        :class="v$.first_name.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.admin[14].Contact[0].Last_name}}<span>*</span>
                        <span style="color: red">{{
                          v$.last_name.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="text"
                        v-model="state.last_name"
                        :placeholder="lan.admin[15].Add_contact_page[0].Last_name"
                        :class="v$.last_name.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label>{{ lan.admin[14].Contact[0].Phone}}</label
                      ><br />
                      <input
                        type="text"
                        name="Telephone"
                        v-model="phone"
                        :placeholder="lan.admin[15].Add_contact_page[0].Phone"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.admin[14].Contact[0].Email}}<span>*</span
                        ><span style="color: red">{{
                          v$.email.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="email"
                        name="email"
                        v-model="state.email"
                        :placeholder="lan.admin[15].Add_contact_page[0].Email"
                        :class="v$.email.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.admin[15].Add_contact_page[0].Role}}<span>*</span>
                        <span style="color: red">{{
                          v$.roles.$error ? " is required" : ""
                        }}</span></label
                      ><br />
                      <select
                        v-model="state.roles"
                        :class="v$.roles.$error ? 'input-error' : ''"
                      >
                        <option value="" disabled selected>Role Type</option>
                        <option value="1">Admin</option>
                        <option value="2">Partner</option>
                        <option value="3">Customer</option>
                      </select>
                    </div>

                    <div v-if="state.roles != 1"  class="col-sm-4 input">
                      <label>{{ lan.admin[14].Contact[0].Company}}
                       </label
                      ><br />
                      <model-list-select v-if="accountDetailsFilter" :list="accountDetailsFilter"
                style="height: 43px; border: 1px solid #ccc; border-radius: 2px;"
                v-model="state.company"
                option-value="account_id"
                :custom-text="getName"
                placeholder="Select Company"
                >
              </model-list-select>
                      <!-- <select v-model="state.company">
                        <option value="" disabled selected>Select Company</option>
                        <option
                          :value="data.account_id"
                          v-for="data in accountDetailsFilter"
                          :key="data.id"
                        >
                          {{ data.name }}
                        </option>
                      </select> -->
                    </div>

                    <div class="col-sm-4 input">
                      <label>{{ lan.admin[15].Add_contact_page[0].Mobile_phone}}</label
                      ><br />
                      <input
                        type="text"
                        name="number"
                        v-model="mobile_phone"
                        :placeholder="lan.admin[15].Add_contact_page[0].Mobile_phone"
                      />
                    </div>
                    <div class="col-md-2 col-sm-4 input">
                      <label>{{
                        lan.default_language
                      }}</label
                      ><br />
                      <select name="" id="" v-model="default_language" :placeholder=" lan.default_language">
                        <option value="en">English</option>
                        <option value="sw">Swedish</option>
                      </select>
                    </div>
                  </div>

                  <textarea
                    name="Address"
                    id="Address"
                    rows="5"
                    v-model="address"
                    :placeholder="lan.admin[15].Add_contact_page[0].Address"
                  ></textarea>

                  <div class="button d-flex justify-content-end mt-4">
                    <button @click="submit" class="search-btn">
                      <div class="lds-ring" v-show="loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <span v-show="loadingS">{{ lan.admin[15].Add_contact_page[0].Save}}</span>
                    </button>
                    <router-link to="/contacts">
                    <button class="filter-btn">{{ lan.admin[15].Add_contact_page[0].Cancel}}</button>
                  </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--**********************************
      Content body end
  ***********************************-->
  </div>
  <!--**********************************
  Main wrapper end
***********************************-->
</template>

<script>
import axios from "axios";

import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from "vue-search-select"

import useVuelidate from "@vuelidate/core";
import { required, email, integer } from "@vuelidate/validators";
import { reactive, computed } from "vue";

export default {
  name: "Add Contact",
  components: { ModelListSelect },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      first_name: "",
      last_name: "",
      email: "",
      roles: "",
      company: "",
    });

    const rules = computed(() => {
      return {
        first_name: { required },
        last_name: { required },
        roles: { required, integer },
        email: { required, email },
        company: { },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      accountDetails: "",
      phone: "",
      mobile_phone: "",
      address: "",
      default_language: "",

      loading: false,
      loadingS: true,
    };
  },
  computed: {

    lan(){
    return this.$store.getters.whichLanguage
    },

    accountDetailsFilter() {
      var data = []
      // var self = this;
      var roles = this.state.roles == '2' ? "Partner"   : this.state.roles == '3' ? "Customer" : 1
      if(this.state.roles != ""){
        this.accountDetails.forEach(element => {
          console.log(element)
          if(element.type == roles && (element.is_outside_partner != '1' ||element.is_outside_partner != 1))
          data.push(element);
        });
      }else{
        data = this.accountDetails
      }
      return data
    },
  },
  beforeMount(){
  // for top load page
  window.scrollTo(0, 0);
  },
  mounted() {
    axios
      .get(axios.defaults.baseURL + "accounts", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log("res", res);
        this.accountDetails = res.data.data;
        console.log("accountDetails", this.accountDetails);
      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });
  },

  methods: {
    getName(item) {
        return `${item.name}`
    },
    submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        (this.loading = true), (this.loadingS = false);
        var formData = new FormData();
        formData.append("first_name", this.state.first_name);
        formData.append("last_name", this.state.last_name);
        formData.append("email", this.state.email);
        formData.append("phone", this.phone);
        formData.append("mobile_phone", this.mobile_phone);
        formData.append("default_language", this.default_language);
        if(this.state.roles == '1')
        {
          formData.append("roles", this.state.roles);
        }
        else
        {
          formData.append("roles", this.state.roles);
          formData.append("company", this.state.company); 
        }
        
        formData.append("address", this.address);
        formData.append("password", "");
        formData.append("confirm_password", "");

        axios
          .post(axios.defaults.baseURL + "contacts", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            (this.loading = false),
              (this.loadingS = true),
              console.log("res", res);
            this.$router.push({ path: "/contacts" });
            this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
          })
          .catch((err) => {
            (this.loading = false),
            (this.loadingS = true),
            this.$store.dispatch("checkErrorAndSendToast", [err.response,'error']);
          });
      } else {
        console.log("else", this.v$);
        this.AddToast(this.lan.Please_Fill_Required_Details," info ",'info_1')
        return;
      }
    },

    
    AddToast(message,title,type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
            this.dismissToast(title);
          }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>
