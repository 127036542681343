<template>
  <div>
    <!--**********************************
    Main wrapper start
  ***********************************-->
    <div id="main-wrapper">
      <!-- Edit Email Template -->
      <!-- Modal -->
      <div
        class="modal fade"
        id="exampleModal-invite-customer-2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="max-width: 1200px">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ lan.admin[22].Email[0].Edit_Email }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-md-4 p-3">
              <div class="input">
                <span class="mr-3">{{ lan.admin[22].Email[0].Subject }}:</span>
                <input style="width: 40%" type="text" v-model="subject" />
              </div>

              <ckeditor
                :editor="editor"
                v-model="partner_email_body"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="modal-footer">
              <button
                id="email_template_close"
                type="button"
                class="search-btn up-btn mb-0"
                data-dismiss="modal"
              >
                {{ lan.admin[22].Email[0].Close }}
              </button>
              <button
                type="button"
                class="filter-btn up-btn mb-0"
                @click="save()"
              >
                <div class="lds-ring" v-show="loading">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                <span v-show="loadingS">
                  {{ lan.admin[22].Email[0].Save_changes }}</span
                >
              </button>
            </div>
          </div>
        </div>
      </div>

      <!--**********************************
        Content body end
    ***********************************-->
    </div>
    <!--**********************************
    Main wrapper end
  ***********************************-->
  </div>
</template>

<script>
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import _ from 'lodash';

export default {
  name: "Email Template",
  props:{
    emailData:{
      type: [String, Object, Array],
    }
  },
  data() {
    return {
      value: true,
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        // The configuration of the editor.
      },
      getId: "",
      BodyContent: "",
      partner_email_body: "",
      subject: "",

      loading: false,
      loadingS: true,
      props_emailData: this.emailData
    };
  },
  watch:{
    emailData(newValue, oldValue){
      console.log('watch newValue', newValue);
      console.log('watch oldValue', oldValue);
      console.log('watch emailData', emailData);
    },
    // props_emailData(newValue, oldValue){
    //   console.log('watch newValue 2', newValue);
    //   console.log('watch oldValue 2', oldValue);
    //   console.log('watch props_emailData', this.props_emailData);
    // }
  },
  mounted() {
    // for top load page
    window.scrollTo(0, 0);
    console.log('mount emailData', this.emailData);
    // this.$watch(
    //   () => ({
    //     props_emailData: this.emailData,
    //   }),
    //   (newValues, oldValues) => {
    //     console.log('this.$watch--newValues--',newValues);
    //     console.log('this.$watch--oldValues--',oldValues);
    //     console.log('this.$watch--props_emailData--',this.props_emailData);
    //     this.getId = newValues.props_emailData.emailContent.id
    //     this.BodyContent = newValues.props_emailData.emailContent.body
    //     this.subject = newValues.props_emailData.emailContent.subject
    //     this.partner_email_body = newValues.props_emailData.emailContent.partner_email_body

    //     // do whatever you want with the new and old values
    //   },
    //   { deep: true }
    // );

    this.emailContentLoad("mount");
  },

  computed: {
    lan() {
      return this.$store.getters.whichLanguage;
    },
  },

  methods: {
    getDetails(item) {
      this.getId = item.id;
      this.BodyContent = item.body;
      this.subject = item.subject;
    },
    emailContentLoad(text) {
      console.log(text);
      axios
        .get(axios.defaults.baseURL + "get-email-content/1", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          console.log("res email content--", res);
          this.getId = res.data.data[0].id;
          this.BodyContent = res.data.data[0].body;
          this.subject = res.data.data[0].subject;
          this.partner_email_body = res.data.data[0].partner_email_body;
          //   if(text != 'mount'){
          //   var loader = document.getElementById('loader' + this.getId)
          //     var td = document.getElementById('td' + this.getId)
          //     loader.style.display = 'none';
          //     td.style.display = 'block';
          // }

        })
        .catch((err) => {
          this.$store.dispatch("checkErrorAndSendToast", [
            err.response,
            "error",
          ]);
        });
    },
    save() {
      console.log('save- his.getId--', this.getId);
      this.$emit("email-updated", [1,this.partner_email_body]);
      document.getElementById('email_template_close').click()
      return
      var formData = new FormData();
      formData.append("subject", this.subject);
      formData.append("body", this.BodyContent);
      formData.append("partner_email_body", this.partner_email_body);
      this.loading = true;
      this.loadingS = false;

      axios
        .post(
          axios.defaults.baseURL + "edit-email-content/5" ,
          formData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log("post res", res);
          // var loader = document.getElementById('loader' + this.getId)
          // var td = document.getElementById('td' + this.getId)
          // loader.style.display = 'block';
          // td.style.display = 'none';

          this.emailContentLoad("save");
          (this.loading = false),
            (this.loadingS = true),
            this.$emit("email-updated", 1);
            document.getElementById('email_template_close').click()
            this.$store.dispatch("checkErrorAndSendToast", [res, "Success"]);
        })
        .catch((err) => {
          console.log("post err", err);
          (this.loading = false),
            (this.loadingS = true),
            this.$store.dispatch("checkErrorAndSendToast", [res, "Success"]);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loader-show {
  display: none;
}
</style>
