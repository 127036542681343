<template>
  <!--**********************************
  Main wrapper start
***********************************-->
  <div id="main-wrapper">

    <!--**********************************
          Content body start
      ***********************************-->
    <div class="content-body pt-md-3 pt-0 mb-5">
      <div class="container-fluid">
        <div
          class="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center pt-3"
        >
          <h2 class="font-w600 title mb-2 mr-auto">
            {{ lan.admin[14].Contact[0].Edit}} {{ state.first_name }} {{ lan.admin[14].Contact[0].contact}}
          </h2>
          <a href="#myModal" class="trigger-btn" data-toggle="modal">
            <i
              v-show="value_T"
              class="fa fa-trash btn btn-danger shadow btn-xs sharp"
            ></i>
          </a>
            <div v-show="value_L"
                class="loadingio-spinner-rolling-yxlbb0cd94"
              >
                <div class="ldio-4c1f6xkxs72">
                  <div></div>
                </div>
              </div>
        </div>

        <div class="row">
          <div class="col-xl-12">
            <div class="card">
              <div
                class="card-header pb-2 d-block d-sm-flex flex-wrap border-0"
              >
                <div class="d-flex mt-3">
                  <button
                    class="filter-btn up-btn ml-0"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                  {{ lan.admin[13].Account_page[0].Add_new_note}}
                  </button>
                  <button
                    class="filter-btn up-btn"
                    data-toggle="modal"
                    data-target="#exampleModal1"
                  >
                  {{ lan.admin[13].Account_page[0].Add_new_task}}
                  </button>
                </div>
                <div class="row">
                    <div class="col-lg-6">
                      <table v-if="note_detail && note_detail.length" class="pointer">
                        <h5>{{ lan.admin[13].Account_page[0].Notes}}</h5>
                        <tr v-for="(data, index) in note_detail" :key="index" @click="editNote(data.note_id)">
                          <td>
                            <strong>{{ index + 1 }}</strong>
                          </td>
                          <td>
                            <p class="mb-0">{{ data.subject }}</p>
                          </td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-lg-6">
                      <table v-if="task_detail && task_detail.length" class="pointer">
                        <h5>{{ lan.admin[13].Account_page[0].Tasks}}</h5>
                        <tr v-for="(data, index) in task_detail" :key="index" @click="editTask(data.task_id)">
                          <td>
                            <strong>{{ index + 1 }}</strong>
                          </td>
                          <td>
                            <p class="mb-0">{{ data.subject }}</p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
              </div>

              <div class="card-body p-4">
                <div>
                  <div class="row">
                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.admin[14].Contact[0].First_name}}<span>*</span
                        ><span>{{
                          v$.first_name.$error ? " First Name is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="text"
                        v-model="state.first_name"
                        :placeholder="lan.admin[15].Add_contact_page[0].First_name"
                        :class="v$.first_name.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.admin[14].Contact[0].Last_name}}<span>*</span>
                        <span>{{
                          v$.last_name.$error ? " Last name is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="text"
                        name="text"
                        v-model="state.last_name"
                        :placeholder="lan.admin[15].Add_contact_page[0].Last_name"
                        :class="v$.last_name.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label>{{ lan.admin[14].Contact[0].Phone}}</label
                      ><br />
                      <input
                        type="text"
                        name="Telephone"
                        v-model="phone"
                        :placeholder="lan.admin[15].Add_contact_page[0].Phone"
                      />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.admin[14].Contact[0].Email}}<span>*</span
                        ><span>{{
                          v$.email.$error ? " Email is required" : ""
                        }}</span></label
                      ><br />
                      <input
                        type="email"
                        name="email"
                        v-model="state.email"
                        :placeholder="lan.admin[15].Add_contact_page[0].Email"
                        :class="v$.email.$error ? 'input-error' : ''"
                      />
                    </div>

                    <div class="col-sm-4 input">
                      <label
                        >{{ lan.admin[15].Add_contact_page[0].Role}}<span>*</span>
                        <span>{{
                          v$.roles.$error ? " Roles is required" : ""
                        }}</span></label
                      ><br />
                      <select
                        v-model="state.roles"
                        :class="v$.roles.$error ? 'input-error' : ''"
                      >
                        <option value="1">Admin</option>
                        <option value="2">Partner</option>
                        <option value="3">Customer</option>
                      </select>
                    </div>

                    <div v-if="state.roles != 1" class="col-sm-4 input">
                      <label>{{ lan.admin[14].Contact[0].Company}}</label
                      ><br />
                      <model-list-select v-if="accountDetailsFilter" :list="accountDetailsFilter"
                style="height: 43px; border: 1px solid #ccc; border-radius: 2px;"
                v-model="company"
                option-value="account_id"
                :custom-text="getName"
                placeholder="Select Company"
                >
              </model-list-select>
                      <!-- <select v-model="company">
                        <option
                          :value="data.account_id"
                          v-for="data in accountDetailsFilter"
                          :key="data.account_id"
                        >
                          {{ data.name }}
                        </option>
                      </select> -->
                    </div>

                    <div class="col-sm-4 input">
                      <label>{{ lan.admin[15].Add_contact_page[0].Mobile_phone}}</label
                      ><br />
                      <input
                        type="text"
                        name="number"
                        v-model="mobile_phone"
                        :placeholder="lan.admin[15].Add_contact_page[0].Mobile_phone"
                      />
                    </div>
                    <div class="col-md-2 col-sm-4 input">
                      <label>{{
                        lan.default_language
                      }}</label
                      ><br />
                      <select name="" id="" v-model="default_language" :placeholder=" lan.default_language">
                        <option value="en">English</option>
                        <option value="sw">Swedish</option>
                      </select>
                    </div>
                  </div>

                  <textarea
                    name="Address"
                    id="Address"
                    rows="5"
                    v-model="address"
                    :placeholder="lan.admin[15].Add_contact_page[0].Address"
                  ></textarea>

                  <div class="button d-flex justify-content-end mt-4">
                    <button @click="submit" class="search-btn">
                      <div class="lds-ring" v-show="loading">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                      <span v-show="loadingS">{{ lan.admin[15].Add_contact_page[0].Save}}</span>
                    </button>
                    <button class="filter-btn">
                      <router-link to="/contacts">{{ lan.admin[15].Add_contact_page[0].Cancel}}</router-link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal Note -->
    <AddNote
          :accountDetails="accountDetails"
          :opportunitiesDetails="opportunitiesDetails"
          :contactsDetails="contactsDetails"
          :related_contact="related_contact"
          @data-updated-note="saveNewNoteHandle"
        />
        <!-- Modal Task -->
        <AddTask
          :accountDetails="accountDetails"
          :opportunitiesDetails="opportunitiesDetails"
          :contactsDetails="contactsDetails"
          :accountOwners="accountOwners"
          @data-updated-task="saveNewTaskHandle"
        />
        <deleteModal @delete="deletef" />
    <!--**********************************
          Content body end
      ***********************************-->

  </div>
  <!--**********************************
  Main wrapper end
***********************************-->
</template>

<script>
import axios from "axios";
import AddNote from "../../../components/addNote.vue";
import AddTask from "../../../components/addTask.vue";
import deleteModal from "../../../components/deleteModal";

import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from "vue-search-select"

import useVuelidate from "@vuelidate/core";
import { required, email, integer } from "@vuelidate/validators";
import { reactive, computed } from "vue";

export default {
  name: "Add Contact",
  components: { AddNote,
    AddTask, deleteModal, ModelListSelect },
  setup() {
    const state = reactive({
      errors: [],
      success: [],
      first_name: "",
      last_name: "",
      email: "",
      roles: "",
    });

    const rules = computed(() => {
      return {
        first_name: { required },
        last_name: { required },
        roles: { required, integer },
        email: { required, email },
        // mobile: { required, integer, minLength: minLength(10), maxLength: maxLength(10) },
      };
    });
    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {

      opportunitiesDetails: "",
      contactsDetails: "",

      accountOwners: "",
      note_detail: [],
      task_detail: [],

      accountDetails: [],
      company: "",
      contact_details: "",
      phone: "",
      mobile_phone: "",
      note: [],
      task: [],
      address: "",
      default_language: "",
      Id: this.$route.params.id,

      related_contact:'',

      loading: false,
      loadingS: true,
      value_L: false,
      value_T: true,
      loadingSaveNewTaskText: true,
      loadingSaveNewTaskLoader: false,
      loadingSaveNewNoteText: true,
      loadingSaveNewNoteLoader: false,
    };
  },
  computed: {
    lan(){
      return this.$store.getters.whichLanguage
    },
    accountDetailsFilter() {
      var data = []
      // var self = this;
      console.log('element')
      var roles = this.state.roles == '2' ? "Partner"   : this.state.roles == '3' ? "Customer" : 1
      if(this.state.roles != ""){
        this.accountDetails.forEach(element => {
          console.log(element)
          if(element.type == roles && (element.is_outside_partner != '1' ||element.is_outside_partner != 1))
          data.push(element);
        });
      }else{
        data = this.accountDetails
      }
      return data
    },
  },
  mounted() {
     // for top load page
     window.scrollTo(0, 0);
     
    this.related_contact = this.Id
    console.log('tt-', typeof this.related_contact)
    this.getAccount_contact_oppo();
    axios
      .get(axios.defaults.baseURL + "contact/" + this.Id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log("res", res);
        this.contact_details = res.data.data[0];

        this.state.first_name = res.data.data[0].first_name;
        this.state.last_name = res.data.data[0].last_name;
        this.state.email = res.data.data[0].email;
        this.phone = res.data.data[0].phone;
        this.company = res.data.data[0].company;
        this.note_detail= res.data.data[0].note_detail
        this.task_detail = res.data.data[0].task_detail
        this.default_language = res.data.data[0].default_language
        // this.note = res.data.data[0].note;
        // this.task = res.data.data[0].task;

        this.mobile_phone = res.data.data[0].mobile_phone;
        this.state.roles = res.data.data[0].roles;
        this.address = res.data.data[0].address;
      })
      .catch((err) => {
        console.log("err", err);
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });

    //************** Account owner details
    axios
      .get(axios.defaults.baseURL + "get-account-owners", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.accountOwners = res.data.data;
        console.log("account owners", this.accountOwners);
      })
      .catch((err) => {
        console.log("err account owner", err);
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
      });
  },
  methods: {
    getName(item) {
        return `${item.name}`
    },
    editNote(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Note", params: { id: id }, });
    },
    editTask(id) {
      localStorage.setItem("editID", id);
      this.$router.push({ name: "Edit Task", params: { id: id }, });
    },
    getAccount_contact_oppo() {
      // *************GET Accounts**********
      axios
        .get(axios.defaults.baseURL + "accounts", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          this.accountDetails = res.data.data;
        })
        .catch((err) => {
          console.log("err", err);
          this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        });
    },
    submit() {

      this.v$.first_name.$touch();
      this.v$.last_name.$touch();
      this.v$.email.$touch();
      this.v$.roles.$touch();
      if (
        !this.v$.first_name.$invalid&&
        !this.v$.last_name.$invalid &&
        !this.v$.email.$invalid &&
        !this.v$.roles.$invalid
      ) {
        (this.loading = true), (this.loadingS = false);
        var formData = new FormData();
        formData.append("first_name", this.state.first_name);
        formData.append("last_name", this.state.last_name);
        formData.append("email", this.state.email);
        formData.append("phone", this.phone);
        formData.append("company", this.company);
        formData.append("mobile_phone", this.mobile_phone);
        formData.append("roles", this.state.roles);
        formData.append("address", this.address);
        formData.append("password", this.contact_details.password);
        formData.append("confirm_password", this.contact_details.confirm_password);
        formData.append("default_language", this.default_language);
        if (this.note.length) {
          formData.append("note", JSON.stringify(this.note));
        } else {
          formData.append("note", this.note);
        }
        if (this.task.length) {
          formData.append("task", JSON.stringify(this.task));
        } else {
          formData.append("task", this.task);
        }

        axios
          .post(axios.defaults.baseURL + "edit-contact/" + this.Id, formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((res) => {
            console.log("res", res);
            (this.loading = false),
              (this.loadingS = true),
              this.$router.push({ path: "/contacts" });
              this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
          })
          .catch((err) => {
            (this.loading = false),
            (this.loadingS = true),
            this.$store.dispatch("checkErrorAndSendToast", [err.response,'error']);
          });
      } else {
        console.log("else", this.v$);
        this.AddToast(this.lan.Please_Fill_Required_Details, " info ", "info_1");
        return;
      }
    },
    saveNewNoteHandle(data) {
      this.note.push(data.note_id,)
      this.note_detail.push({
        note_id: data.note_id,
        subject: data.subject,
        comment: data.comment,
      });
    },
    saveNewTaskHandle(data) {
      this.task.push(data.task_id),
      this.task_detail.push({
        task_id: data.task_id,
        subject: data.subject,
        assign_to: data.assign_to,
        comment: data.comment,
      });
    },

    route() {
      console.log("recfgh");
      this.$router.push("/contacts");
    },
    deletef(){
      this.value_L = true
      this.value_T = false
      axios
      .post(axios.defaults.baseURL + "delete-contact/" + this.Id ,{}, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        this.value_L = false
      this.value_T = true
      this.$store.dispatch("checkErrorAndSendToast", [res,'Success']);
        document.getElementById('deletemodal').click();
        this.$router.push({ path: "/contacts" });
      })
      .catch((err) => {
        this.$store.dispatch("checkErrorAndSendToast", [
          err.response,
          "error",
        ]);
        this.value_L = false
      this.value_T = true
      });
    },

    AddToast(message, title, type) {
      this.$store.commit("addToast", {
        title: title,
        type: type,
        message: message,
      });
      setTimeout(() => {
        this.dismissToast(title);
      }, 5000);
    },
    dismissToast(title) {
      this.$store.commit("clearToast", title);
    },
  },
};
</script>
